/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_user_provider = {
    provider: response_user_provider.provider;
};

export namespace response_user_provider {

    export enum provider {
        EMAIL = 'email',
        GOOGLE_OAUTH2 = 'google_oauth2',
    }


}

