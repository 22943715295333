/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { response_revisedHistoricalInformations_exist } from '../models/response_revisedHistoricalInformations_exist';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RevisedHistoricalInformationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 修正した過去データの存在有無取得API
     * 修正した過去データが存在するかどうかを返すAPI。
     * @returns response_revisedHistoricalInformations_exist Example response
     * @throws ApiError
     */
    public getRevisedHistoricalInformationsExist(): CancelablePromise<response_revisedHistoricalInformations_exist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/revised_historical_informations/exist',
        });
    }

}
