/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_ultra_tasks_medicalExpenses = {
    progress: response_ultra_tasks_medicalExpenses.progress | null;
};

export namespace response_ultra_tasks_medicalExpenses {

    export enum progress {
        INCOMPLETED = 'incompleted',
        COMPLETED = 'completed',
    }


}

