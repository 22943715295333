/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_taxes_patch } from '../models/request_taxes_patch';
import type { response_taxes } from '../models/response_taxes';
import type { response_taxes_patch } from '../models/response_taxes_patch';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaxService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 税区分取得API
     * 税区分を返す。
     * @param scope
     * @returns response_taxes Example response
     * @throws ApiError
     */
    public getTaxes(
        scope?: 'journal' | 'invoice',
    ): CancelablePromise<response_taxes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/taxes',
            query: {
                'scope': scope,
            },
        });
    }

    /**
     * 使用税区分更新API
     * 使用する税区分を更新する。
     * @param requestBody
     * @returns response_taxes_patch Example response
     * @throws ApiError
     */
    public patchTaxes(
        requestBody?: request_taxes_patch,
    ): CancelablePromise<response_taxes_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/taxes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
