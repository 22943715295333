/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type surveyRevenueInformation = {
    salary_status: surveyRevenueInformation.salary_status | null;
    revenue_status: surveyRevenueInformation.revenue_status | null;
    crypto_status: surveyRevenueInformation.crypto_status | null;
    retirement_status: surveyRevenueInformation.retirement_status | null;
    stock_dividend_status: surveyRevenueInformation.stock_dividend_status | null;
    stock_transfer_status: surveyRevenueInformation.stock_transfer_status | null;
    real_estate_transfer_status: surveyRevenueInformation.real_estate_transfer_status | null;
    real_estate_status: surveyRevenueInformation.real_estate_status | null;
    timber_status: surveyRevenueInformation.timber_status | null;
    fx_status: surveyRevenueInformation.fx_status | null;
    other_status: surveyRevenueInformation.other_status | null;
    workplace_name: string | null;
    cash_revenue_status: surveyRevenueInformation.cash_revenue_status | null;
    no_invoice_status: surveyRevenueInformation.no_invoice_status | null;
    invoice_issuance_status: surveyRevenueInformation.invoice_issuance_status | null;
    factoring_status: surveyRevenueInformation.factoring_status | null;
    advance_payment_status: surveyRevenueInformation.advance_payment_status | null;
};

export namespace surveyRevenueInformation {

    export enum salary_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum revenue_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum crypto_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum retirement_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum stock_dividend_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum stock_transfer_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum real_estate_transfer_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum real_estate_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum timber_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum fx_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum other_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum cash_revenue_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum no_invoice_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum invoice_issuance_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum factoring_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum advance_payment_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }


}

