/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_fixedAssets } from '../models/request_fixedAssets';
import type { request_fixedAssets_depreciationAmount } from '../models/request_fixedAssets_depreciationAmount';
import type { request_fixedAssets_depreciationMethod } from '../models/request_fixedAssets_depreciationMethod';
import type { response_fixedAsset } from '../models/response_fixedAsset';
import type { response_fixedAssets } from '../models/response_fixedAssets';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FixedAssetService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 固定資産作成API
     * 固定資産を作成する
     * @param requestBody
     * @returns response_fixedAsset Example response
     * @throws ApiError
     */
    public postFixedAssets(
        requestBody?: request_fixedAssets,
    ): CancelablePromise<response_fixedAsset> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/fixed_assets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 固定資産一覽取得API
     * 固定資産の一覽を取得する
     * @param scope
     * @param depreciationMethod
     * @returns response_fixedAssets Example response
     * @throws ApiError
     */
    public getFixedAssets(
        scope?: 'unjournalized',
        depreciationMethod?: 'straight_line' | 'declining_balance' | 'petty_sum' | 'lump_sum' | 'straight_line_method' | 'voluntary',
    ): CancelablePromise<response_fixedAssets> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fixed_assets',
            query: {
                'scope': scope,
                'depreciation_method': depreciationMethod,
            },
        });
    }

    /**
     * 固定資産取得API
     * 固定資産を取得するAPI
     * @param id
     * @returns response_fixedAsset Example response
     * @throws ApiError
     */
    public getFixedAssetsId(
        id: string,
    ): CancelablePromise<response_fixedAsset> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fixed_assets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 固定資産削除API
     * 固定資産を削除する。
     * 固定資産に紐づく減価償却(fixed_asset_depreciations)、償却仕訳（journals）も同時に削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteFixedAssetsId(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/fixed_assets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 固定資産 基本情報更新API
     * 固定資産の基本情報を更新する
     * @param id
     * @param requestBody
     * @returns response_fixedAsset Example response
     * @throws ApiError
     */
    public patchFixedAssetsIdBasicInformation(
        id: string,
        requestBody?: request_fixedAssets,
    ): CancelablePromise<response_fixedAsset> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/fixed_assets/{id}/basic_information',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 固定資産 償却のやり方更新API
     * 固定資産の償却のやり方を更新する
     * @param id
     * @param requestBody
     * @returns response_fixedAsset Example response
     * @throws ApiError
     */
    public patchFixedAssetsIdDepreciationMethod(
        id: string,
        requestBody?: request_fixedAssets_depreciationMethod,
    ): CancelablePromise<response_fixedAsset> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/fixed_assets/{id}/depreciation_method',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 固定資産 普通償却額更新API
     * 任意償却の固定資産の普通償却額を更新する。
     * @param id
     * @param requestBody
     * @returns response_fixedAsset Example response
     * @throws ApiError
     */
    public patchFixedAssetsIdDepreciationAmount(
        id: string,
        requestBody?: request_fixedAssets_depreciationAmount,
    ): CancelablePromise<response_fixedAsset> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/fixed_assets/{id}/depreciation_amount',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
