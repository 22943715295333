import { createIcon } from '@chakra-ui/react'

export const SelectTriangleIcon = createIcon({
  displayName: 'TriangleUnderIcon',
  viewBox: '0 0 12 7',
  path: (
    <>
      <path
        d="M11 0.916016L6 5.91601L1 0.916016H11Z"
        fill="#A0A0A0"
        stroke="#A0A0A0"
        strokeLinejoin="bevel"
      />
    </>
  )
})
