/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { headOfHouseholdRelationship } from './headOfHouseholdRelationship';
import type { industryCode } from './industryCode';

export type request_basicInformation_personalInfo = {
    last_name: string;
    first_name: string;
    last_name_kana: string;
    first_name_kana: string;
    birthday: string;
    /**
     * home: 自宅、
     * office: 職場、
     * mobile: 携帯
     */
    tel_division: request_basicInformation_personalInfo.tel_division;
    /**
     * ハイフン区切り
     */
    tel: string;
    industry_code: industryCode;
    occupation: string;
    head_of_household_relationship: headOfHouseholdRelationship;
    head_of_household_last_name: string;
    head_of_household_first_name: string;
    is_working_student: boolean;
    is_vocational_school_student: boolean | null;
    is_single_parent: boolean;
    is_widows: boolean;
    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還
     */
    widows_reason: request_basicInformation_personalInfo.widows_reason | null;
    has_disability: boolean;
    /**
     * disabled: 障がい者、
     * specially_disabled: 特別障がい者
     */
    disability_division: request_basicInformation_personalInfo.disability_division | null;
    business_name: string;
};

export namespace request_basicInformation_personalInfo {

    /**
     * home: 自宅、
     * office: 職場、
     * mobile: 携帯
     */
    export enum tel_division {
        HOME = 'home',
        OFFICE = 'office',
        MOBILE = 'mobile',
    }

    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還
     */
    export enum widows_reason {
        BEREAVED = 'bereaved',
        DIVORCED = 'divorced',
        DEAD_OR_ALIVE = 'dead_or_alive',
        NOT_RETURNED = 'not_returned',
    }

    /**
     * disabled: 障がい者、
     * specially_disabled: 特別障がい者
     */
    export enum disability_division {
        DISABLED = 'disabled',
        SPECIALLY_DISABLED = 'specially_disabled',
    }


}

