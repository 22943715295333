import type {
  SystemStyleFunction,
  SystemStyleInterpolation,
  SystemStyleObject
} from '@chakra-ui/theme-tools'
import { InputTheme } from './input'

const baseStyle: SystemStyleFunction = (props) => ({
  ...InputTheme.baseStyle.field,
  p: '12px',
  minHeight: '140px',
  verticalAlign: 'top'
})

const variants: Record<string, SystemStyleInterpolation> = {
  outline: (props) => InputTheme.variants.outline?.(props).field ?? {}
  //   flushed: (props) => InputTheme.variants.flushed?.(props).field ?? {},
  //   filled: (props) => InputTheme.variants.filled?.(props).field ?? {},
  //   unstyled: InputTheme.variants.unstyled.field ?? {}
}

const sizes: Record<string, SystemStyleObject> = {
  //   xs: InputTheme.sizes.xs.field ?? {},
  sm: InputTheme.sizes.sm.field ?? {},
  md: InputTheme.sizes.md.field ?? {}
  //   lg: InputTheme.sizes.lg.field ?? {}
}

const defaultProps = {
  size: 'md',
  variant: 'outline'
}

export const TextareaTheme = {
  baseStyle,
  sizes,
  variants,
  defaultProps
}
