/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { zoAdmin_ultra_statementFiles_importedFile } from './zoAdmin_ultra_statementFiles_importedFile';
import type { zoAdmin_ultra_statementFiles_uploadedFile } from './zoAdmin_ultra_statementFiles_uploadedFile';

export type response_zoAdmin_ultra_statementFile = {
    account_type: response_zoAdmin_ultra_statementFile.account_type;
    account_name: string;
    user_id: number;
    user_name: string;
    has_incompleted: boolean;
    incompleted_uploaded_files: Array<zoAdmin_ultra_statementFiles_uploadedFile>;
    uploaded_files: Array<zoAdmin_ultra_statementFiles_uploadedFile>;
    imported_files: Array<zoAdmin_ultra_statementFiles_importedFile>;
};

export namespace response_zoAdmin_ultra_statementFile {

    export enum account_type {
        BANK = 'bank',
        CARD = 'card',
    }


}

