/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_ultra_survey = {
    /**
     * TypeForm の Form タイトルを入力する。
     */
    name: string;
    code: request_ultra_survey.code;
    /**
     * TypeForm の Form ID を入力する。
     */
    survey_id: string;
};

export namespace request_ultra_survey {

    export enum code {
        INITIAL = 'initial',
    }


}

