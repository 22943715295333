/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_ultra_survey = {
    survey_result: {
        /**
         * TypeForm の Form タイトル
         */
        name: string;
        code: response_ultra_survey.code;
        /**
         * TypeForm の Form ID
         */
        survey_id: string;
        status: response_ultra_survey.status;
    } | null;
};

export namespace response_ultra_survey {

    export enum code {
        INITIAL = 'initial',
    }

    export enum status {
        COMPLETED = 'completed',
    }


}

