/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_taxReturnQuestions = {
    business_income: {
        checked_business_income: response_taxReturnQuestions.checked_business_income | null;
    };
    basic_information: {
        personal_info: response_taxReturnQuestions.personal_info | null;
        address: response_taxReturnQuestions.address | null;
        tax_office_name: response_taxReturnQuestions.tax_office_name | null;
        submission_method: response_taxReturnQuestions.submission_method | null;
        open_date: response_taxReturnQuestions.open_date | null;
        tax_return_type: response_taxReturnQuestions.tax_return_type | null;
        special_deduction_amount: response_taxReturnQuestions.special_deduction_amount | null;
    };
    income_and_outgo: {
        salaries: response_taxReturnQuestions.salaries | null;
        retirement_incomes: response_taxReturnQuestions.retirement_incomes | null;
        stock_incomes: response_taxReturnQuestions.stock_incomes | null;
        miscellaneous_incomes_crypto: response_taxReturnQuestions.miscellaneous_incomes_crypto | null;
        miscellaneous_incomes_business: response_taxReturnQuestions.miscellaneous_incomes_business | null;
        business_income_details: response_taxReturnQuestions.business_income_details | null;
        exc_social_insurances: response_taxReturnQuestions.exc_social_insurances | null;
        family_spouse: response_taxReturnQuestions.family_spouse | null;
        other_families: response_taxReturnQuestions.other_families | null;
        social_insurance_pension: response_taxReturnQuestions.social_insurance_pension | null;
        social_insurance_health: response_taxReturnQuestions.social_insurance_health | null;
        social_insurance_pension_fund: response_taxReturnQuestions.social_insurance_pension_fund | null;
        other_social_insurances: response_taxReturnQuestions.other_social_insurances | null;
        small_business_mutual_aid_pensions: response_taxReturnQuestions.small_business_mutual_aid_pensions | null;
        small_business_mutual_aids: response_taxReturnQuestions.small_business_mutual_aids | null;
        medical_expenses: response_taxReturnQuestions.medical_expenses | null;
        donation_prefecture: response_taxReturnQuestions.donation_prefecture | null;
        other_donations: response_taxReturnQuestions.other_donations | null;
        estimated_tax_prepayments: response_taxReturnQuestions.estimated_tax_prepayments | null;
        house_loan_deductions: response_taxReturnQuestions.house_loan_deductions | null;
        counselor_fees: response_taxReturnQuestions.counselor_fees | null;
        employee_salaries: response_taxReturnQuestions.employee_salaries | null;
        carried_forward_losses: response_taxReturnQuestions.carried_forward_losses | null;
    };
    confirmation: {
        refund_accounts: response_taxReturnQuestions.refund_accounts | null;
    };
    submission: {
        e_tax_user_id: response_taxReturnQuestions.e_tax_user_id | null;
        my_number: response_taxReturnQuestions.my_number | null;
        submit_date: response_taxReturnQuestions.submit_date | null;
    };
};

export namespace response_taxReturnQuestions {

    export enum checked_business_income {
        NO = 'no',
        YES = 'yes',
    }

    export enum personal_info {
        NO = 'no',
        YES = 'yes',
    }

    export enum address {
        NO = 'no',
        YES = 'yes',
    }

    export enum tax_office_name {
        NO = 'no',
        YES = 'yes',
    }

    export enum submission_method {
        NO = 'no',
        YES = 'yes',
    }

    export enum open_date {
        NO = 'no',
        YES = 'yes',
    }

    export enum tax_return_type {
        NO = 'no',
        YES = 'yes',
    }

    export enum special_deduction_amount {
        NO = 'no',
        YES = 'yes',
    }

    export enum salaries {
        NO = 'no',
        YES = 'yes',
    }

    export enum retirement_incomes {
        NO = 'no',
        YES = 'yes',
    }

    export enum stock_incomes {
        NO = 'no',
        YES = 'yes',
    }

    export enum miscellaneous_incomes_crypto {
        NO = 'no',
        YES = 'yes',
    }

    export enum miscellaneous_incomes_business {
        NO = 'no',
        YES = 'yes',
    }

    export enum business_income_details {
        NO = 'no',
        YES = 'yes',
    }

    export enum exc_social_insurances {
        NO = 'no',
        YES = 'yes',
    }

    export enum family_spouse {
        NO = 'no',
        YES = 'yes',
    }

    export enum other_families {
        NO = 'no',
        YES = 'yes',
    }

    export enum social_insurance_pension {
        NO = 'no',
        YES = 'yes',
    }

    export enum social_insurance_health {
        NO = 'no',
        YES = 'yes',
    }

    export enum social_insurance_pension_fund {
        NO = 'no',
        YES = 'yes',
    }

    export enum other_social_insurances {
        NO = 'no',
        YES = 'yes',
    }

    export enum small_business_mutual_aid_pensions {
        NO = 'no',
        YES = 'yes',
    }

    export enum small_business_mutual_aids {
        NO = 'no',
        YES = 'yes',
    }

    export enum medical_expenses {
        NO = 'no',
        YES = 'yes',
    }

    export enum donation_prefecture {
        NO = 'no',
        YES = 'yes',
    }

    export enum other_donations {
        NO = 'no',
        YES = 'yes',
    }

    export enum estimated_tax_prepayments {
        NO = 'no',
        YES = 'yes',
    }

    export enum house_loan_deductions {
        NO = 'no',
        YES = 'yes',
    }

    export enum counselor_fees {
        NO = 'no',
        YES = 'yes',
    }

    export enum employee_salaries {
        NO = 'no',
        YES = 'yes',
    }

    export enum carried_forward_losses {
        NO = 'no',
        YES = 'yes',
    }

    export enum refund_accounts {
        NO = 'no',
        YES = 'yes',
    }

    export enum e_tax_user_id {
        NO = 'no',
        YES = 'yes',
    }

    export enum my_number {
        NO = 'no',
        YES = 'yes',
    }

    export enum submit_date {
        NO = 'no',
        YES = 'yes',
    }


}

