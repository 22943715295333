import { NextPageContext } from 'next'
import { parseCookies, setCookie as setCookieNookies } from 'nookies'
import { COOKIE_AGE } from '../constants'

export function setCookie(
  name: string,
  value: string,
  domain = '',
  maxAge: number = COOKIE_AGE,
  ctx?: NextPageContext
) {
  setCookieNookies(ctx, name, value, {
    maxAge,
    path: '/',
    domain,
    secure:
      process.env.NEXT_PUBLIC_ENV === 'production' ||
      process.env.NEXT_PUBLIC_ENV === 'staging'
  })
}

export function parseCookie(ctx?: NextPageContext) {
  return parseCookies(ctx)
}
