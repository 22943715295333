/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_report_config } from '../models/request_report_config';
import type { response_report_config } from '../models/response_report_config';
import type { response_report_config_patch } from '../models/response_report_config_patch';
import type { response_report_estimatedIncomeTax } from '../models/response_report_estimatedIncomeTax';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * レポート設定取得API
     * レポートの設定を取得する
     * @returns response_report_config Example response
     * @throws ApiError
     */
    public getReportConfig(): CancelablePromise<response_report_config> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/config',
        });
    }

    /**
     * レポート設定更新API
     * レポートの設定を更新する
     * @param requestBody
     * @returns response_report_config_patch Example response
     * @throws ApiError
     */
    public patchReportConfig(
        requestBody?: request_report_config,
    ): CancelablePromise<response_report_config_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/report/config',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 推定納税額取得API
     * 現状の納税額と推定納税額を取得する
     * @returns response_report_estimatedIncomeTax Example response
     * @throws ApiError
     */
    public getReportEstimatedIncomeTax(): CancelablePromise<response_report_estimatedIncomeTax> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/report/estimated_income_tax',
        });
    }

}
