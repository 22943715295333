/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type houseLoanDeduction = {
    house_loan_deduction: {
        item: houseLoanDeduction.item;
        living_house_start_date: string;
        living_address_start_date: string | null;
        contract_date: string;
        house_type: houseLoanDeduction.house_type;
        certified_housing_type: houseLoanDeduction.certified_housing_type;
        house_amount: number;
        house_subsidy_amount: number;
        house_tax_rate: number | null;
        house_tax_amount: number | null;
        land_amount: number | null;
        land_subsidy_amount: number | null;
        house_total_area: number;
        house_living_area: number;
        land_total_area: number | null;
        land_living_area: number | null;
        house_real_estate_number: string;
        land_real_estate_number: string | null;
        year_end_loan_balance_amount: number;
        loan_start_date: string;
        total_loan_amount: number;
        repayment_start_date: string;
        repayment_end_date: string;
        certification_date: string;
        creditor_address: string;
        creditor_name: string;
        is_living_delayed_by_covid_19: boolean;
        deduction_calculation_method: houseLoanDeduction.deduction_calculation_method;
        partner_name_kana: string | null;
        partner_name: string | null;
        partner_house_share_top: number | null;
        partner_house_share_bottom: number | null;
        applicant_house_share_top: number | null;
        applicant_house_share_bottom: number | null;
        partner_land_share_top: number | null;
        partner_land_share_bottom: number | null;
        applicant_land_share_top: number | null;
        applicant_land_share_bottom: number | null;
        partner_deposit_amount: number | null;
        applicant_deposit_amount: number | null;
        partner_solo_debt_amount: number | null;
        applicant_solo_debt_amount: number | null;
        partner_solo_debt_year_end_loan_balance_amount: number | null;
        applicant_solo_debt_year_end_loan_balance_amount: number | null;
    } | null;
};

export namespace houseLoanDeduction {

    export enum item {
        HOUSE = 'house',
        HOUSE_AND_LAND = 'house_and_land',
    }

    export enum house_type {
        BUILD_HOUSE = 'build_house',
        PURCHASE_HOUSE = 'purchase_house',
        BUILD_ORDER_HOUSE = 'build_order_house',
        PURCHASE_DWELLING_HOUSE = 'purchase_dwelling_house',
        PURCHASE_AND_RESALE_HOUSE = 'purchase_and_resale_house',
        USED_HOUSE = 'used_house',
    }

    export enum certified_housing_type {
        NOT_CERTIFIED = 'not_certified',
        LOW_CARBON = 'low_carbon',
        ZEH = 'zeh',
        STANDARD = 'standard',
    }

    export enum deduction_calculation_method {
        ALONE_DEBT = 'alone_debt',
        SHARE = 'share',
        JOINT_DEBT = 'joint_debt',
    }


}

