/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type surveyExpenseInformation = {
    divided_items: Array<{
        name: string;
        rate: number;
    }>;
    legal_and_tax_fee_status: surveyExpenseInformation.legal_and_tax_fee_status | null;
    cash_status: surveyExpenseInformation.cash_status | null;
    installment_status: surveyExpenseInformation.installment_status | null;
    startup_status: surveyExpenseInformation.startup_status | null;
};

export namespace surveyExpenseInformation {

    export enum legal_and_tax_fee_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum cash_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum installment_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum startup_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }


}

