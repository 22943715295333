/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_ultra_tasks_medicalExpenses = {
    progress?: request_ultra_tasks_medicalExpenses.progress;
};

export namespace request_ultra_tasks_medicalExpenses {

    export enum progress {
        INCOMPLETED = 'incompleted',
        COMPLETED = 'completed',
    }


}

