/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_basicInformation = {
    id: number;
    year?: number;
    last_name: string | null;
    first_name: string | null;
    last_name_kana: string | null;
    first_name_kana: string | null;
    birthday: string | null;
    /**
     * home: 自宅、
     * office: 職場、
     * mobile: 携帯
     */
    tel_division: response_basicInformation.tel_division | null;
    tel: string | null;
    industry_code: response_basicInformation.industry_code | null;
    occupation: string | null;
    head_of_household_relationship: string | null;
    head_of_household_last_name: string | null;
    head_of_household_first_name: string | null;
    is_working_student: boolean;
    /**
     * true: いいえ、
     * false: はい
     */
    is_vocational_school_student: boolean;
    is_single_parent: boolean;
    is_widows: boolean;
    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還、
     */
    widows_reason: response_basicInformation.widows_reason | null;
    has_disability: boolean;
    /**
     * disabled: 障がい者、
     * specially_disabled: 特別障がい者
     */
    disability_division: response_basicInformation.disability_division | null;
    business_name: string | null;
    zip_code: string | null;
    prefecture_code: string | null;
    address: string | null;
    new_years_day_zip_code: string | null;
    new_years_day_prefecture_code: string | null;
    new_years_day_address: string | null;
    business_zip_code: string | null;
    business_prefecture_code: string | null;
    business_address: string | null;
    is_office_tax_payment_place: boolean | null;
    tax_office_name: string | null;
    /**
     * e_tax: オンライン提出、
     * mail: 郵送、
     * bring: 税務署で手渡し
     */
    submission_method: response_basicInformation.submission_method | null;
    /**
     * 開業日が前年度以前の場合値が入る
     */
    open_year: number | null;
    /**
     * 開業日が今年度の場合値が入る
     */
    open_date: string | null;
    /**
     * white: 白色、
     * blue： 青色
     */
    tax_return_type: response_basicInformation.tax_return_type | null;
    special_deduction_amount: response_basicInformation.special_deduction_amount | null;
};

export namespace response_basicInformation {

    /**
     * home: 自宅、
     * office: 職場、
     * mobile: 携帯
     */
    export enum tel_division {
        HOME = 'home',
        OFFICE = 'office',
        MOBILE = 'mobile',
    }

    export enum industry_code {
        _01 = '01',
        _02 = '02',
        _03 = '03',
        _04 = '04',
        _05 = '05',
        _06 = '06',
        _07 = '07',
        _08 = '08',
        _09 = '09',
        _10 = '10',
        _11 = '11',
        _12 = '12',
        _13 = '13',
        _14 = '14',
        _15 = '15',
        _16 = '16',
        _17 = '17',
        _18 = '18',
        _19 = '19',
        _20 = '20',
        _21 = '21',
        _22 = '22',
        _23 = '23',
        _24 = '24',
        _25 = '25',
    }

    /**
     * bereaved: 死別、
     * divorced: 離婚、
     * dead_or_alive: 生死不明、
     * not_returned: 未帰還、
     */
    export enum widows_reason {
        BEREAVED = 'bereaved',
        DIVORCED = 'divorced',
        DEAD_OR_ALIVE = 'dead_or_alive',
        NOT_RETURNED = 'not_returned',
    }

    /**
     * disabled: 障がい者、
     * specially_disabled: 特別障がい者
     */
    export enum disability_division {
        DISABLED = 'disabled',
        SPECIALLY_DISABLED = 'specially_disabled',
    }

    /**
     * e_tax: オンライン提出、
     * mail: 郵送、
     * bring: 税務署で手渡し
     */
    export enum submission_method {
        E_TAX = 'e_tax',
        MAIL = 'mail',
        BRING = 'bring',
    }

    /**
     * white: 白色、
     * blue： 青色
     */
    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }

    export enum special_deduction_amount {
        '_0' = 0,
        '_100000' = 100000,
        '_550000' = 550000,
        '_650000' = 650000,
    }


}

