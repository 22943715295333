/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_basicInformation_taxableType = {
    taxable_type: response_basicInformation_taxableType.taxable_type;
    start_taxable_date: string | null;
    will_use_invoice_exception: boolean;
    /**
     * 事業区分
     * 1: 第一種: 卸売業
     * 2: 第二種: 小売業
     * 3: 第三種: 農業・林業・漁業
     * 4: 第四種: 飲食店業
     * 5: 第五種: 金融・保険業、運輸通信業、サービス業
     * 6: 第六種: 不動産業
     */
    simple_taxable_business_division: number | null;
};

export namespace response_basicInformation_taxableType {

    export enum taxable_type {
        EXEMPTION = 'exemption',
        SIMPLE = 'simple',
        PROPORTION = 'proportion',
        ITEM = 'item',
        FULL = 'full',
    }


}

