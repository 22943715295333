/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { response_cashReceiptImage } from '../models/response_cashReceiptImage';
import type { response_cashReceiptImages } from '../models/response_cashReceiptImages';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CashReceiptService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 現金の領収書一覧取得API
     * 現金の領収書一覧を取得する。
     * @param page
     * @param isDeleted
     * @param since
     * @param until
     * @param clientIds 顧客IDの配列
     * @param remarks 概要
     * @param amountMin 最小金額
     * @param amountMax 金額最大
     * @param fileName
     * @returns response_cashReceiptImages Example response
     * @throws ApiError
     */
    public getCashReceiptImages(
        page?: number,
        isDeleted?: 'true' | 'false',
        since?: string,
        until?: string,
        clientIds?: any[],
        remarks?: string,
        amountMin?: number,
        amountMax?: number,
        fileName?: string,
    ): CancelablePromise<response_cashReceiptImages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cash_receipt_images',
            query: {
                'page': page,
                'is_deleted': isDeleted,
                'since': since,
                'until': until,
                'client_ids[]': clientIds,
                'remarks': remarks,
                'amount_min': amountMin,
                'amount_max': amountMax,
                'file_name': fileName,
            },
        });
    }

    /**
     * 現金の領収書一覧削除API
     * 現金の領収書一覧を削除する。
     * @param ids
     * @returns void
     * @throws ApiError
     */
    public deleteCashReceiptImages(
        ids: any[],
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/cash_receipt_images',
            query: {
                'ids[]': ids,
            },
        });
    }

    /**
     * 現金の領収書詳細取得API
     * 現金の領収書詳細を取得する。
     * @param id
     * @returns response_cashReceiptImage Example response
     * @throws ApiError
     */
    public getCashReceiptImagesId(
        id: number,
    ): CancelablePromise<response_cashReceiptImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cash_receipt_images/{id}',
            path: {
                'id': id,
            },
        });
    }

}
