/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { prefectureCode } from './prefectureCode';

export type response_opening_jobInfo = {
    occupation: string;
    job_description: string;
    workplace: response_opening_jobInfo.workplace;
    /**
     * ハイフン区切り
     */
    business_zip_code: string;
    business_prefecture_code: prefectureCode;
    business_address: string;
    /**
     * ハイフン区切り
     */
    business_tel: string;
    is_office_tax_payment_place: boolean;
    open_date: string;
    business_name: string;
    business_name_kana: string;
    has_income_from_real_estate: boolean;
    has_income_from_deforestation: boolean;
};

export namespace response_opening_jobInfo {

    export enum workplace {
        HOME = 'home',
        OFFICE = 'office',
        NOT_DETERMINED = 'not_determined',
    }


}

