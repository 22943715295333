/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_marunageProgresses = {
    servey: {
        advance: response_marunageProgresses.advance | null;
    };
    document: {
        salaries: response_marunageProgresses.salaries | null;
        retirement_incomes: response_marunageProgresses.retirement_incomes | null;
        miscellaneous_incomes_crypto: response_marunageProgresses.miscellaneous_incomes_crypto | null;
        miscellaneous_incomes_business: response_marunageProgresses.miscellaneous_incomes_business | null;
        business_income_details: response_marunageProgresses.business_income_details | null;
        exc_social_insurances: response_marunageProgresses.exc_social_insurances | null;
        family_spouse: response_marunageProgresses.family_spouse | null;
        other_families: response_marunageProgresses.other_families | null;
        social_insurance_pension: response_marunageProgresses.social_insurance_pension | null;
        social_insurance_health: response_marunageProgresses.social_insurance_health | null;
        social_insurance_pension_fund: response_marunageProgresses.social_insurance_pension_fund | null;
        other_social_insurances: response_marunageProgresses.other_social_insurances | null;
        small_business_mutual_aid_pensions: response_marunageProgresses.small_business_mutual_aid_pensions | null;
        small_business_mutual_aids: response_marunageProgresses.small_business_mutual_aids | null;
        medical_expenses: response_marunageProgresses.medical_expenses | null;
        donation_prefecture: response_marunageProgresses.donation_prefecture | null;
        other_donations: response_marunageProgresses.other_donations | null;
        estimated_tax_prepayments: response_marunageProgresses.estimated_tax_prepayments | null;
        counselor_fees: response_marunageProgresses.counselor_fees | null;
    };
    review: {
        user_journals_review: response_marunageProgresses.user_journals_review | null;
        user_fixed_assets_review: response_marunageProgresses.user_fixed_assets_review | null;
        tax_accountant_journals_review: response_marunageProgresses.tax_accountant_journals_review | null;
        tax_accountant_tax_return_document_review: response_marunageProgresses.tax_accountant_tax_return_document_review | null;
    };
    meeting: {
        first_with_tax_accountant: response_marunageProgresses.first_with_tax_accountant | null;
        second_with_tax_accountant: response_marunageProgresses.second_with_tax_accountant | null;
    };
};

export namespace response_marunageProgresses {

    export enum advance {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum salaries {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum retirement_incomes {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum miscellaneous_incomes_crypto {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum miscellaneous_incomes_business {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum business_income_details {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum exc_social_insurances {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum family_spouse {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum other_families {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum social_insurance_pension {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum social_insurance_health {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum social_insurance_pension_fund {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum other_social_insurances {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum small_business_mutual_aid_pensions {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum small_business_mutual_aids {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum medical_expenses {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum donation_prefecture {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum other_donations {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum estimated_tax_prepayments {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum counselor_fees {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum user_journals_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum user_fixed_assets_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum tax_accountant_journals_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum tax_accountant_tax_return_document_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum first_with_tax_accountant {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum second_with_tax_accountant {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

