/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_partnerTaxAccountant_confirmation } from '../models/request_partnerTaxAccountant_confirmation';
import type { request_partnerTaxAccountant_journals } from '../models/request_partnerTaxAccountant_journals';
import type { request_partnerTaxAccountant_review } from '../models/request_partnerTaxAccountant_review';
import type { request_partnerTaxAccountant_signIn } from '../models/request_partnerTaxAccountant_signIn';
import type { request_password_patch } from '../models/request_password_patch';
import type { request_password_post } from '../models/request_password_post';
import type { response_accounts } from '../models/response_accounts';
import type { response_journals_get } from '../models/response_journals_get';
import type { response_journals_manual } from '../models/response_journals_manual';
import type { response_partnerTaxAccountant_review } from '../models/response_partnerTaxAccountant_review';
import type { response_partnerTaxAccountant_users } from '../models/response_partnerTaxAccountant_users';
import type { response_preview_consumptionTax_appendix_page13 } from '../models/response_preview_consumptionTax_appendix_page13';
import type { response_preview_consumptionTax_appendix_page23 } from '../models/response_preview_consumptionTax_appendix_page23';
import type { response_preview_consumptionTax_appendix_page43 } from '../models/response_preview_consumptionTax_appendix_page43';
import type { response_preview_consumptionTax_appendix_page53 } from '../models/response_preview_consumptionTax_appendix_page53';
import type { response_preview_consumptionTax_appendix_page6 } from '../models/response_preview_consumptionTax_appendix_page6';
import type { response_preview_consumptionTax_general_page1 } from '../models/response_preview_consumptionTax_general_page1';
import type { response_preview_consumptionTax_page2 } from '../models/response_preview_consumptionTax_page2';
import type { response_preview_consumptionTax_simple_page1 } from '../models/response_preview_consumptionTax_simple_page1';
import type { response_preview_donation_npo } from '../models/response_preview_donation_npo';
import type { response_preview_earningsAndExpensesStatement } from '../models/response_preview_earningsAndExpensesStatement';
import type { response_preview_financialStatement } from '../models/response_preview_financialStatement';
import type { response_preview_houseLoanDeduction } from '../models/response_preview_houseLoanDeduction';
import type { response_preview_houseLoanDeduction_appendix_jointDebt } from '../models/response_preview_houseLoanDeduction_appendix_jointDebt';
import type { response_preview_incomeDetail } from '../models/response_preview_incomeDetail';
import type { response_preview_listedStockCarryForwardLossStatement } from '../models/response_preview_listedStockCarryForwardLossStatement';
import type { response_preview_medicalExpense } from '../models/response_preview_medicalExpense';
import type { response_preview_stockIncomeStatement } from '../models/response_preview_stockIncomeStatement';
import type { response_preview_taxReturn1 } from '../models/response_preview_taxReturn1';
import type { response_preview_taxReturn2 } from '../models/response_preview_taxReturn2';
import type { response_preview_taxReturn3 } from '../models/response_preview_taxReturn3';
import type { response_preview_taxReturn4 } from '../models/response_preview_taxReturn4';
import type { response_zoAdmin_taxes } from '../models/response_zoAdmin_taxes';
import type { response_zoAdmin_ultra_informations } from '../models/response_zoAdmin_ultra_informations';
import type { response_zoAdmin_ultra_reportCategories } from '../models/response_zoAdmin_ultra_reportCategories';
import type { response_zoAdmin_ultra_userStatus_accounts } from '../models/response_zoAdmin_ultra_userStatus_accounts';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaxAccountantService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 提携税理士ログインAPI
     * 提携税理士がログインする。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postAuthPartnerTaxAccountantSignIn(
        requestBody?: request_partnerTaxAccountant_signIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/partner_tax_accountant/sign_in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 提携税理士ログアウトAPI
     * 提携税理士がログアウトする。
     * @returns void
     * @throws ApiError
     */
    public deleteAuthPartnerTaxAccountantSignOut(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/partner_tax_accountant/sign_out',
        });
    }

    /**
     * 提携税理士本人確認API
     * 提携税理士が本人確認を行いパスワードを登録する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postAuthPartnerTaxAccountantConfirmation(
        requestBody?: request_partnerTaxAccountant_confirmation,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/partner_tax_accountant/confirmation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 提携税理士パスワードリセットAPI
     * 提携税理士がパスワードリセットのリクエストをする。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postAuthPartnerTaxAccountantPassword(
        requestBody?: request_password_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/partner_tax_accountant/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 提携税理士パスワードリセットAPI
     * 提携税理士パスワードリセットをする。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchAuthPartnerTaxAccountantPassword(
        requestBody?: request_password_patch,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/partner_tax_accountant/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 提携税理士担当ユーザー一覧取得API
     * 提携税理士が担当ユーザー一覧を取得する。
     * @param year
     * @returns response_partnerTaxAccountant_users Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantUsers(
        year: number,
    ): CancelablePromise<response_partnerTaxAccountant_users> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/users',
            query: {
                'year': year,
            },
        });
    }

    /**
     * 担当ユーザー確定申告書第一表プレビューAPI
     * 担当ユーザーの確定申告書第一表プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_taxReturn1 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewTaxReturn1(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_taxReturn1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/tax_return1',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー確定申告書第二表プレビューAPI
     * 担当ユーザーの確定申告書第二表プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_taxReturn2 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewTaxReturn2(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_taxReturn2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/tax_return2',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー確定申告書第三表プレビューAPI
     * 担当ユーザーの確定申告書第三表プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_taxReturn3 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewTaxReturn3(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_taxReturn3> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/tax_return3',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー収支内訳書プレビューAPI
     * 担当ユーザーの収支内訳書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_earningsAndExpensesStatement Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewEarningsAndExpensesStatement(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_earningsAndExpensesStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/earnings_and_expenses_statement',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー青色申告決算書プレビューAPI
     * 担当ユーザーの青色申告決算書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_financialStatement Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewFinancialStatement(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_financialStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/financial_statement',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー所得の内訳書プレビューAPI
     * 担当ユーザーの所得の内訳書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_incomeDetail Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewIncomeDetail(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_incomeDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/income_detail',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー医療費控除の明細書プレビューAPI
     * 担当ユーザーの医療費控除の明細書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_medicalExpense Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewMedicalExpense(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_medicalExpense> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/medical_expense',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書第一表（一般用）プレビューAPI
     * 担当ユーザーの消費税の確定申告書第一表（一般用）プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_general_page1 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxGeneralPage1(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_general_page1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/general/page_1',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書第一表（簡易課税用）プレビューAPI
     * 担当ユーザーの消費税の確定申告書第一表（簡易課税用）プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_simple_page1 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxSimplePage1(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_simple_page1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/simple/page_1',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書第二表プレビューAPI
     * 担当ユーザーの消費税の確定申告書第二表プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_page2 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxPage2(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_page2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/page_2',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書付表1-3プレビューAPI
     * 担当ユーザーの消費税の確定申告書付表1-3プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_appendix_page13 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxAppendixPage13(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_appendix_page13> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/appendix/page_1_3',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書付表2-3プレビューAPI
     * 担当ユーザーの消費税の確定申告書付表2-3プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_appendix_page23 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxAppendixPage23(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_appendix_page23> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/appendix/page_2_3',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書付表4-3プレビューAPI
     * 担当ユーザーの消費税の確定申告書付表4-3プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_appendix_page43 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxAppendixPage43(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_appendix_page43> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/appendix/page_4_3',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー消費税の確定申告書付表5-3プレビューAPI
     * 担当ユーザーの消費税の確定申告書付表5-3プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_consumptionTax_appendix_page53 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxAppendixPage53(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_consumptionTax_appendix_page53> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/appendix/page_5_3',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー株式等に係る譲渡所得等の金額の計算明細書プレビューAPI
     * 担当ユーザーの株式等に係る譲渡所得等の金額の計算明細書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_stockIncomeStatement Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewStockIncomeStatement(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_stockIncomeStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/stock_income_statement',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー上場株式等に係る譲渡損失の損益通算及び繰越控除用の付表プレビューAPI
     * 担当ユーザーの上場株式等に係る譲渡損失の損益通算及び繰越控除用の付表プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_listedStockCarryForwardLossStatement Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewListedStockCarryForwardLossStatement(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_listedStockCarryForwardLossStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/listed_stock_carry_forward_loss_statement',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー帳簿取得API
     * 担当ユーザー帳簿情報を取得する。
     * @param year
     * @param userId ユーザーID
     * @param page
     * @param since
     * @param until
     * @param remarks
     * @param accountItemIds
     * @param type
     * @param accountIds
     * @param amountMax
     * @param amountMin
     * @param scope
     * @param count 件数
     * @param includeBackDetailsInSearch 勘定科目検索時にBackDetailの勘定科目まで含めるかどうか
     * @returns response_journals_get Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantJournals(
        year: number,
        userId: number,
        page?: number,
        since?: string,
        until?: string,
        remarks?: string,
        accountItemIds?: any[],
        type?: 'income' | 'outgo',
        accountIds?: any[],
        amountMax?: number,
        amountMin?: number,
        scope?: 'fixed_asset' | 'business_income' | 'unregistered_fixed_asset',
        count?: number,
        includeBackDetailsInSearch?: boolean,
    ): CancelablePromise<response_journals_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/journals',
            query: {
                'year': year,
                'user_id': userId,
                'page': page,
                'since': since,
                'until': until,
                'remarks': remarks,
                'account_item_ids[]': accountItemIds,
                'type': type,
                'account_ids[]': accountIds,
                'amount_max': amountMax,
                'amount_min': amountMin,
                'scope': scope,
                'count': count,
                'include_back_details_in_search': includeBackDetailsInSearch,
            },
        });
    }

    /**
     * 担当ユーザー帳簿更新API
     * 担当ユーザー帳簿情報を取得する。
     * @param journalId
     * @param requestBody
     * @returns response_journals_manual Example response
     * @throws ApiError
     */
    public patchPartnerTaxAccountantJournalsJournalId(
        journalId: number,
        requestBody?: request_partnerTaxAccountant_journals,
    ): CancelablePromise<response_journals_manual> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/partner_tax_accountant/journals/{journal_id}',
            path: {
                'journal_id': journalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 担当ユーザーレビュー情報取得API
     * 担当ユーザーのレビュー情報を取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_partnerTaxAccountant_review Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantReview(
        year: number,
        userId: number,
    ): CancelablePromise<response_partnerTaxAccountant_review> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/review',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザーレビュー情報更新API
     * 担当ユーザーのレビュー情報を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchPartnerTaxAccountantReview(
        requestBody?: request_partnerTaxAccountant_review,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/partner_tax_accountant/review',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 担当ユーザー勘定科目取得API
     * 担当ユーザーの勘定科目を取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_reportCategories Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantReportCategories(
        year: number,
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_reportCategories> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/report_categories',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー入出金手段取得API
     * 担当ユーザーの入出金手段を取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_accounts Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantAccounts(
        year: number,
        userId: number,
    ): CancelablePromise<response_accounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/accounts',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー税区分取得API
     * 担当ユーザーの税区分を取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_zoAdmin_taxes Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxes(
        year: number,
        userId: number,
    ): CancelablePromise<response_zoAdmin_taxes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/taxes',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー住宅借入金等特別控除額の計算明細書プレビューAPI
     * 担当ユーザーの住宅借入金等特別控除額の計算明細書プレビューを返す。
     * @param userId ユーザーID
     * @param year
     * @returns response_preview_houseLoanDeduction Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewHouseLoanDeduction(
        userId: number,
        year: number,
    ): CancelablePromise<response_preview_houseLoanDeduction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/house_loan_deduction',
            query: {
                'user_id': userId,
                'year': year,
            },
        });
    }

    /**
     * 担当ユーザー利用金融機関一覧取得API
     * 担当ユーザーの利用金融機関一覧を取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_informations Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantInformations(
        year: number,
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_informations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/informations',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザーステータス取得API
     * 担当ユーザーのステータスを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_userStatus_accounts Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantUserStatusAccounts(
        year: number,
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_userStatus_accounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/user_status/accounts',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー 消費税の確定申告書付表6プレビューAPI
     * 担当ユーザーの消費税の確定申告書付表6のプレビューを返す。
     * @param userId ユーザーID
     * @param year
     * @returns response_preview_consumptionTax_appendix_page6 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewConsumptionTaxAppendixPage6(
        userId: number,
        year: number,
    ): CancelablePromise<response_preview_consumptionTax_appendix_page6> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/consumption_tax/appendix/page_6',
            query: {
                'user_id': userId,
                'year': year,
            },
        });
    }

    /**
     * 担当ユーザー確定申告書第四表プレビューAPI
     * 担当ユーザーの確定申告書第四表プレビューを返す。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_taxReturn4 Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewTaxReturn4(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_taxReturn4> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/tax_return4',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー 連帯債務がある場合の住宅借入金等の年末残高の計算明細書プレビューAPI
     * 担当ユーザーの連帯債務がある場合の住宅借入金等の年末残高の計算明細書プレビューを返す。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_houseLoanDeduction_appendix_jointDebt Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewHouseLoanDeductionAppendixJointDebt(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_houseLoanDeduction_appendix_jointDebt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/house_loan_deduction/appendix/joint_debt',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 担当ユーザー認定NPO法人等寄附金特別控除額の計算明細書プレビューAPI
     * 担当ユーザーの認定NPO法人等寄附金特別控除額の計算明細書プレビューを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_preview_donation_npo Example response
     * @throws ApiError
     */
    public getPartnerTaxAccountantTaxReturnPreviewDonationNpo(
        year: number,
        userId: number,
    ): CancelablePromise<response_preview_donation_npo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/partner_tax_accountant/tax_return/preview/donation/npo',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

}
