/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type refundAccount_patch = {
    type: refundAccount_patch.type;
    receiving_method: refundAccount_patch.receiving_method | null;
    name: string | null;
    bank_kind: refundAccount_patch.bank_kind | null;
    branch_name: string | null;
    branch_type: refundAccount_patch.branch_type | null;
    refund_account_type: refundAccount_patch.refund_account_type | null;
    account_number: string | null;
    branch_number: string | null;
};

export namespace refundAccount_patch {

    export enum type {
        REFUND_EXCEPT_YUCHO_ACCOUNT = 'RefundExceptYuchoAccount',
        REFUND_YUCHO_ACCOUNT = 'RefundYuchoAccount',
    }

    export enum receiving_method {
        TRANSFER = 'transfer',
        TELLER = 'teller',
    }

    export enum bank_kind {
        BANK = 'bank',
        VAULT = 'vault',
        UNION = 'union',
        AGRICULTURAL = 'agricultural',
        FISHING = 'fishing',
    }

    export enum branch_type {
        HEAD = 'head',
        BRANCH = 'branch',
        SUB_BRANCH = 'sub_branch',
        MAIN = 'main',
        SUB = 'sub',
    }

    export enum refund_account_type {
        SAVINGS = 'savings',
        CHECKING = 'checking',
        TAX_PREPARATION = 'tax_preparation',
        DEPOSIT = 'deposit',
        ANOTHER_ACCOUNT_TYPE = 'another_account_type',
    }


}

