/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_basicInformation_taxReturnType = {
    /**
     * white: 白色、
     * blue: 青色
     */
    tax_return_type: request_basicInformation_taxReturnType.tax_return_type;
};

export namespace request_basicInformation_taxReturnType {

    /**
     * white: 白色、
     * blue: 青色
     */
    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

