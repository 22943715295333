/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_basicInformation_submissionMethod = {
    /**
     * e_tax: オンライン提出（e-Tax）、
     * mail: 郵送、
     * bring: 税務署で手渡し
     */
    submission_method: request_basicInformation_submissionMethod.submission_method;
};

export namespace request_basicInformation_submissionMethod {

    /**
     * e_tax: オンライン提出（e-Tax）、
     * mail: 郵送、
     * bring: 税務署で手渡し
     */
    export enum submission_method {
        E_TAX = 'e_tax',
        MAIL = 'mail',
        BRING = 'bring',
    }


}

