/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_ultra_topics = {
    /**
     * topicable_type & topicable_id もしくは user_id が必須となる。
     */
    topicable_type: request_zoAdmin_ultra_topics.topicable_type | null;
    /**
     * topicable_type & topicable_id もしくは user_id が必須となる。
     */
    topicable_id: number | null;
    /**
     * topicable_type & topicable_id もしくは user_id が必須となる。
     */
    user_id: number | null;
    name: string | null;
    message: {
        question_type: request_zoAdmin_ultra_topics.question_type;
        text: string | null;
    };
};

export namespace request_zoAdmin_ultra_topics {

    /**
     * topicable_type & topicable_id もしくは user_id が必須となる。
     */
    export enum topicable_type {
        AT_TRANSACTION = 'AtTransaction',
    }

    export enum question_type {
        TEXT = 'text',
        JOURNAL_PRIVATE_OR_NOT_REPLIED = 'journal.private_or_not_replied',
        JOURNAL_USAGE_REPLIED = 'journal.usage_replied',
    }


}

