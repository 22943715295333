/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_payment_invoices = {
    payment_method_id: string;
    price: request_payment_invoices.price;
    name: string;
};

export namespace request_payment_invoices {

    export enum price {
        ULTRA = 'ultra',
        SUPER = 'super',
    }


}

