/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_topicMessagePostbackActions } from '../models/request_topicMessagePostbackActions';
import type { request_topicMessages } from '../models/request_topicMessages';
import type { response_topicMessagePostbackActions } from '../models/response_topicMessagePostbackActions';
import type { response_topicMessages } from '../models/response_topicMessages';
import type { response_topics } from '../models/response_topics';
import type { response_topics_openingCount } from '../models/response_topics_openingCount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessagingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * トピック一覧取得API
     * トピック一覧を取得する。
     * @returns response_topics Example response
     * @throws ApiError
     */
    public getTopics(): CancelablePromise<response_topics> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topics',
        });
    }

    /**
     * 進行中トピック数取得API
     * 進行中トピック数を取得する。
     * @returns response_topics_openingCount Example response
     * @throws ApiError
     */
    public getTopicsOpeningCount(): CancelablePromise<response_topics_openingCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topics/opening_count',
        });
    }

    /**
     * トピックメッセージ一覧取得API
     * トピックメッセージの一覧を取得する。
     * @param id トピックID
     * @returns response_topicMessages Example response
     * @throws ApiError
     */
    public getTopicsIdMessages(
        id: number,
    ): CancelablePromise<response_topicMessages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/topics/{id}/messages',
            path: {
                'id': id,
            },
        });
    }

    /**
     * メッセージ作成API
     * メッセージを作成する。
     * @param requestBody
     * @returns response_topicMessages Example response
     * @throws ApiError
     */
    public postTopicMessages(
        requestBody?: request_topicMessages,
    ): CancelablePromise<response_topicMessages> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/topic_messages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ポストバックアクション実行API
     * ポストバックアクションを実行する。
     * @param requestBody
     * @returns response_topicMessagePostbackActions Example response
     * @throws ApiError
     */
    public postTopicMessagePostbackActions(
        requestBody?: request_topicMessagePostbackActions,
    ): CancelablePromise<response_topicMessagePostbackActions> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/topic_message_postback_actions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
