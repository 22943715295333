/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_payment_subscriptions = {
    payment_method_id: string;
    price: request_payment_subscriptions.price;
    name: string;
    with_freetrial?: boolean;
    promotion_code?: string;
    contract_year?: number;
};

export namespace request_payment_subscriptions {

    export enum price {
        YEARLY = 'yearly',
        MONTHLY = 'monthly',
        ULTRA = 'ultra',
    }


}

