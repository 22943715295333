/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type zoAdmin_client = {
    id: number;
    name: string;
    honorific: zoAdmin_client.honorific;
    department_name: string;
    person_in_charge: string;
    person_in_charge_honorific: zoAdmin_client.person_in_charge_honorific;
    zip_code: string;
    address: string;
    tel: string;
    email: string;
};

export namespace zoAdmin_client {

    export enum honorific {
        ONCHU = 'onchu',
        SAMA = 'sama',
        WITHOUT_HONORIFIC = 'without_honorific',
    }

    export enum person_in_charge_honorific {
        PERSON_ONCHU = 'person_onchu',
        PERSON_SAMA = 'person_sama',
        PERSON_WITHOUT_HONORIFIC = 'person_without_honorific',
    }


}

