/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { headOfHouseholdRelationship } from './headOfHouseholdRelationship';
import type { industryCode } from './industryCode';

export type response_basicInformation_personalInfo = {
    last_name: string;
    first_name: string;
    last_name_kana: string;
    first_name_kana: string;
    birthday: string;
    tel_division: response_basicInformation_personalInfo.tel_division;
    tel: string;
    industry_code: industryCode;
    occupation: string;
    head_of_household_relationship: headOfHouseholdRelationship;
    head_of_household_last_name: string;
    head_of_household_first_name: string;
    is_working_student: boolean;
    is_vocational_school_student: boolean | null;
    is_single_parent: boolean;
    is_widows: boolean;
    widows_reason: response_basicInformation_personalInfo.widows_reason | null;
    has_disability: boolean;
    disability_division: response_basicInformation_personalInfo.disability_division | null;
    business_name: string;
};

export namespace response_basicInformation_personalInfo {

    export enum tel_division {
        HOME = 'home',
        OFFICE = 'office',
        MOBILE = 'mobile',
    }

    export enum widows_reason {
        BEREAVED = 'bereaved',
        DIVORCED = 'divorced',
        DEAD_OR_ALIVE = 'dead_or_alive',
        NOT_RETURNED = 'not_returned',
    }

    export enum disability_division {
        DISABLED = 'disabled',
        SPECIALLY_DISABLED = 'specially_disabled',
    }


}

