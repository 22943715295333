/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccountItemService } from './services/AccountItemService';
import { AdminService } from './services/AdminService';
import { AtTransactionService } from './services/AtTransactionService';
import { BasicInformationService } from './services/BasicInformationService';
import { CarriedForwardJobService } from './services/CarriedForwardJobService';
import { CashReceiptService } from './services/CashReceiptService';
import { ClosingService } from './services/ClosingService';
import { ConsumptionTaxablePlayerService } from './services/ConsumptionTaxablePlayerService';
import { ExportService } from './services/ExportService';
import { FixedAssetService } from './services/FixedAssetService';
import { HistoricalBalanceSheetDetailService } from './services/HistoricalBalanceSheetDetailService';
import { ImageService } from './services/ImageService';
import { InvitationService } from './services/InvitationService';
import { InvoiceService } from './services/InvoiceService';
import { InvoiceImageService } from './services/InvoiceImageService';
import { JournalService } from './services/JournalService';
import { MarunageService } from './services/MarunageService';
import { MessagingService } from './services/MessagingService';
import { OnboardingService } from './services/OnboardingService';
import { OpeningService } from './services/OpeningService';
import { OpeningBalanceQuestionService } from './services/OpeningBalanceQuestionService';
import { PaymentService } from './services/PaymentService';
import { ReportService } from './services/ReportService';
import { RevisedHistoricalInformationService } from './services/RevisedHistoricalInformationService';
import { StartupExpenseService } from './services/StartupExpenseService';
import { TaskService } from './services/TaskService';
import { TaxService } from './services/TaxService';
import { TaxAccountantService } from './services/TaxAccountantService';
import { TaxReturnService } from './services/TaxReturnService';
import { UltraService } from './services/UltraService';
import { UploadService } from './services/UploadService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly accountItem: AccountItemService;
    public readonly admin: AdminService;
    public readonly atTransaction: AtTransactionService;
    public readonly basicInformation: BasicInformationService;
    public readonly carriedForwardJob: CarriedForwardJobService;
    public readonly cashReceipt: CashReceiptService;
    public readonly closing: ClosingService;
    public readonly consumptionTaxablePlayer: ConsumptionTaxablePlayerService;
    public readonly export: ExportService;
    public readonly fixedAsset: FixedAssetService;
    public readonly historicalBalanceSheetDetail: HistoricalBalanceSheetDetailService;
    public readonly image: ImageService;
    public readonly invitation: InvitationService;
    public readonly invoice: InvoiceService;
    public readonly invoiceImage: InvoiceImageService;
    public readonly journal: JournalService;
    public readonly marunage: MarunageService;
    public readonly messaging: MessagingService;
    public readonly onboarding: OnboardingService;
    public readonly opening: OpeningService;
    public readonly openingBalanceQuestion: OpeningBalanceQuestionService;
    public readonly payment: PaymentService;
    public readonly report: ReportService;
    public readonly revisedHistoricalInformation: RevisedHistoricalInformationService;
    public readonly startupExpense: StartupExpenseService;
    public readonly task: TaskService;
    public readonly tax: TaxService;
    public readonly taxAccountant: TaxAccountantService;
    public readonly taxReturn: TaxReturnService;
    public readonly ultra: UltraService;
    public readonly upload: UploadService;
    public readonly user: UserService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:3000',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accountItem = new AccountItemService(this.request);
        this.admin = new AdminService(this.request);
        this.atTransaction = new AtTransactionService(this.request);
        this.basicInformation = new BasicInformationService(this.request);
        this.carriedForwardJob = new CarriedForwardJobService(this.request);
        this.cashReceipt = new CashReceiptService(this.request);
        this.closing = new ClosingService(this.request);
        this.consumptionTaxablePlayer = new ConsumptionTaxablePlayerService(this.request);
        this.export = new ExportService(this.request);
        this.fixedAsset = new FixedAssetService(this.request);
        this.historicalBalanceSheetDetail = new HistoricalBalanceSheetDetailService(this.request);
        this.image = new ImageService(this.request);
        this.invitation = new InvitationService(this.request);
        this.invoice = new InvoiceService(this.request);
        this.invoiceImage = new InvoiceImageService(this.request);
        this.journal = new JournalService(this.request);
        this.marunage = new MarunageService(this.request);
        this.messaging = new MessagingService(this.request);
        this.onboarding = new OnboardingService(this.request);
        this.opening = new OpeningService(this.request);
        this.openingBalanceQuestion = new OpeningBalanceQuestionService(this.request);
        this.payment = new PaymentService(this.request);
        this.report = new ReportService(this.request);
        this.revisedHistoricalInformation = new RevisedHistoricalInformationService(this.request);
        this.startupExpense = new StartupExpenseService(this.request);
        this.task = new TaskService(this.request);
        this.tax = new TaxService(this.request);
        this.taxAccountant = new TaxAccountantService(this.request);
        this.taxReturn = new TaxReturnService(this.request);
        this.ultra = new UltraService(this.request);
        this.upload = new UploadService(this.request);
        this.user = new UserService(this.request);
    }
}

