/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_opening_submissionTasks = {
    printed: boolean;
    stamped_on_opening: boolean;
    filled_in_my_number: boolean;
    filled_in_opening_submit_date: boolean;
    stamped_on_blue: boolean;
    filled_in_blue_submit_date: boolean;
    submission_method: response_opening_submissionTasks.submission_method;
    submitted_by_mail: boolean;
    submitted_by_bring: boolean;
};

export namespace response_opening_submissionTasks {

    export enum submission_method {
        MAIL = 'mail',
        BRING = 'bring',
    }


}

