/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type topic = {
    id: number;
    topicable_type: topic.topicable_type | null;
    name: string;
    account_name: string | null;
    recognized_at: string | null;
    is_income: boolean | null;
    amount: number | null;
    status: topic.status;
    sent_last_message_at: string;
};

export namespace topic {

    export enum topicable_type {
        AT_TRANSACTION = 'AtTransaction',
    }

    export enum status {
        OPENING = 'opening',
        CLOSED = 'closed',
    }


}

