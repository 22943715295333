/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_ultra_statementFiles_importStatus = {
    import_status: request_zoAdmin_ultra_statementFiles_importStatus.import_status;
};

export namespace request_zoAdmin_ultra_statementFiles_importStatus {

    export enum import_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }


}

