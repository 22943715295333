/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_badRequest = {
    status: response_errors_badRequest.status;
    message: response_errors_badRequest.message;
};

export namespace response_errors_badRequest {

    export enum status {
        ERROR = 'error',
    }

    export enum message {
        BAD_REQUEST = 'Bad Request',
    }


}

