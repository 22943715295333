/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_fixedAssets_depreciationMethod = {
    /**
     * straight_line: 定額法, declining_balance: 定率法, petty_sum: 少額償却, lump_sum: 一括償却, straight_line_method: 均等償却, voluntary :任意償却
     *
     */
    depreciation_method: request_fixedAssets_depreciationMethod.depreciation_method;
    /**
     * 償却方法が任意償却の場合は不要
     */
    durable_year?: number;
    service_date: string;
    business_rate: number;
    /**
     * round: 四捨五入, floor: 切り捨て, ceil: 切り上げ
     */
    amount_rounding: request_fixedAssets_depreciationMethod.amount_rounding;
    /**
     * 償却方法が任意償却の場合のみ必要
     */
    depreciation_amount?: number;
    special_depreciation_amount: number;
    beginning_balance: number;
    revised_acquisition_amount: number;
};

export namespace request_fixedAssets_depreciationMethod {

    /**
     * straight_line: 定額法, declining_balance: 定率法, petty_sum: 少額償却, lump_sum: 一括償却, straight_line_method: 均等償却, voluntary :任意償却
     *
     */
    export enum depreciation_method {
        STRAIGHT_LINE = 'straight_line',
        DECLINING_BALANCE = 'declining_balance',
        PETTY_SUM = 'petty_sum',
        LUMP_SUM = 'lump_sum',
        STRAIGHT_LINE_METHOD = 'straight_line_method',
        VOLUNTARY = 'voluntary',
    }

    /**
     * round: 四捨五入, floor: 切り捨て, ceil: 切り上げ
     */
    export enum amount_rounding {
        ROUND = 'round',
        FLOOR = 'floor',
        CEIL = 'ceil',
    }


}

