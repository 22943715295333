// See https://chakra-ui.com/docs/theming/theme
import { extendTheme } from '@chakra-ui/react'
import { CalendarDefaultTheme } from '@uselessdev/datepicker'
import { ButtonTheme } from './components/button'
import {
  CalendarControlTheme,
  CalendarDayTheme,
  CalendarTheme
} from './components/calender'
import { CheckboxTheme } from './components/checkbox'
import { FormTheme } from './components/form'
import { FormErrorTheme } from './components/formError'
import { FormLabelTheme } from './components/formLabel'
import { InputTheme } from './components/input'
import { MenuTheme } from './components/menu'
import { ModalTheme } from './components/modal'
import { NumberInputTheme } from './components/numberInput'
import { RadioTheme } from './components/radio'
import { SelectTheme } from './components/select'
import { TextareaTheme } from './components/textarea'

const Link = {
  baseStyle: {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none'
    }
  }
}

export const customTheme = extendTheme(CalendarDefaultTheme, {
  styles: {
    global: {
      'html, body': {
        color: 'black.87',
        lineHeight: 'tall',
        height: '100%',
        scrollBehavior: 'smooth'
      },
      '#__next': {
        height: '100%'
      },
      a: {
        fontWeight: 700,
        cursor: 'pointer',
        color: 'purple.accent',
        textDecoration: 'underline'
      },
      '.chakra-collapse--manual-regist': {
        overflow: 'initial !important' //子要素にoveflow:hiddenがかからないよう指定
      },
      '.chakra-popover__arrow': {
        left: '5px !important'
      },
      '.__floater__arrow': {
        display: 'none'
      },
      '.__floater': {
        paddingTop: '2px !important',
        paddingBottom: '2px !important'
      },
      '#react-joyride-portal': {
        cursor: 'pointer'
      },
      '.react-joyride__overlay': {
        cursor: 'pointer'
      }
    }
  },
  breakpoints: ['0em', '768px', '1028px'],
  fonts: {
    body: '"Noto Sans JP", "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif'
  },
  shadows: {
    pdf: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',
    sm: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)'
  },
  colors: {
    // figmaの右側にあるColor Stylesを参照している
    // see https://www.figma.com/file/MfYaLTBjOMB4j3L6lg7Mao/FFC?node-id=0%3A1
    key: '#2980B9',
    //FIXME:階層構造にする
    purple: {
      50: '#F6F6FD',
      100: '#EFEFF9',
      200: '#EAEAF8',
      300: '#CDCFE3',
      700: '#6471B8',
      900: '#555978',
      lighter: 'rgba(232, 234, 251, 0.4)',
      light: '#F6F6FD',
      default: '#363742',
      middle: {
        light: '#EAEAF8',
        default: '#CDCFE3'
      },
      accent: '#6E7DCA'
    },
    green: {
      50: '#EEFAF4',
      60: '#CEF0E0',
      70: '#C3E5D5',
      500: '#34A46E',
      600: '#2B885B',
      700: '#24714C',
      900: '#194E34'
    },
    primary: {
      purple: '#6E7DCA',
      green: '#41C384',
      black: '#0D0D0D',
      alert: '#D82C0D',
      orange: '#E86C37'
    },
    orange: {
      primary: '#E86C37',
      50: '#FEF5F1',
      100: '#F8D2C2',
      200: '#F4BAA1',
      300: '#F1A483',
      400: '#EE9067',
      500: '#EB7D4E',
      700: '#E55B20',
      800: '#C24A17'
    },
    cost: {
      primary: '#D8584C',
      100: '#FFF4F4',
      900: '#96281E'
    },
    revenue: {
      primary: '#2980B9',
      100: '#DEEFFC',
      900: '#1C5880'
    },
    sidebar: {
      gray: '#A4A6B3'
    },
    secondaryBtnBackground: '#FAFAFA',
    background: {
      base: '#EEF0F5'
    },
    highlight: '#BBDAF6',
    gray: {
      F1F: '#F1F1F1',
      '8D8': '#8D8F91',
      F0F: '#F0F0F0',
      FCF: '#FCFCFC',
      FAF: '#FAFAFA',
      EAE: '#EAEAEA',
      F8F: '#F8F8F8',
      25: '#FDFDFD',
      50: '#FCFCFC',
      70: '#F8F8F8',
      80: '#F4F4F4',
      100: '#F0F0F0',
      200: '#EAEAEA',
      250: '#E2E2E2',
      300: '#D3D3D3',
      400: '#C4C4C4',
      500: '#B3B3B3',
      600: '#A0A0A0',
      700: '#8D8D8D',
      800: '#6C6C6C',
      900: '#3F3F3F',
      950: '#000000',
      EFE: '#EFEFEF'
    },
    black: {
      standard: '#0D0D0D',
      4: 'rgba(0, 0, 0, 0.04)',
      6: 'rgba(0, 0, 0, 0.06)',
      8: 'rgba(0, 0, 0, 0.08)',
      12: 'rgba(0, 0, 0, 0.12)',
      38: 'rgba(0, 0, 0, 0.38)',
      56: 'rgba(0, 0, 0, 0.56)',
      87: 'rgba(0, 0, 0, 0.87)',
      100: '#000000'
    },
    white: {
      12: 'rgba(256, 256, 256, 0.12)',
      38: 'rgba(256, 256, 256, 0.38)',
      100: '#ffffff',
      primary: '#ffffff'
    },
    navy: {
      primary: '#425B76',
      50: '#F5F8FA',
      100: '#EAF0F6',
      200: '#CBD6E2',
      250: '#B9C8D8',
      400: '#7492B1',
      500: '#58799D',
      800: '#33475B',
      900: '#1F2B38'
    },

    transaction: {
      error: '#C94136',
      incomeBlue: {
        default: '#2980B9',
        label: '#2980B9',
        light: '#DEEFFC',
        border: '#2980B9'
      },
      paymentRed: {
        label: '#D8584C',
        border: '#D8584C',
        backGround: '#D8584C',
        default: '#C94136',
        light: '#FAE0DE'
      }
    },

    tax_tasks: {
      onePointBackGroundColor: '#FAFAFA'
    },
    alert: {
      primary: '#D82C0D',
      backGround: '#EB5757',
      100: '#FDF2F2',
      300: 'rgba(224, 179, 178, 1)',
      900: '#881111'
    },
    disable: '#CCCCCC'
  },

  components: {
    Button: ButtonTheme,
    Link,
    Input: InputTheme,
    Checkbox: CheckboxTheme,
    Calendar: CalendarTheme,
    CalendarControl: CalendarControlTheme,
    CalendarDay: CalendarDayTheme,
    Modal: ModalTheme,
    Radio: RadioTheme,
    Form: FormTheme,
    FormLabel: FormLabelTheme,
    FormError: FormErrorTheme,
    Select: SelectTheme,
    Menu: MenuTheme,
    Textarea: TextareaTheme,
    NumberInput: NumberInputTheme
  }
})
