/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_openingBalanceQuestion_taxReturnType = {
    tax_return_type: request_openingBalanceQuestion_taxReturnType.tax_return_type;
};

export namespace request_openingBalanceQuestion_taxReturnType {

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

