/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_opening_export = {
    tax_office_name: string;
    tax_payment_place: {
        place: response_opening_export.place;
        zip_code: string;
        address: string;
        tel: string;
    };
    another_place: {
        zip_code?: string;
        address?: string;
        tel?: string;
    } | null;
    name: string;
    name_kana: string;
    birth_era_name: response_opening_export.birth_era_name | null;
    birth_era_year: number | null;
    birthday: string | null;
    occupation: string;
    business_name: string;
    business_name_kana: string;
    notification_classification: response_opening_export.notification_classification;
    has_income_from_real_estate: boolean;
    has_income_from_deforestation: boolean;
    has_income_from_business: boolean;
    open_date: string | null;
    has_blue_submission: boolean;
    has_taxpayer_submission: boolean;
    job_description: string;
    has_withholding_submission: boolean;
};

export namespace response_opening_export {

    export enum place {
        HOME = 'home',
        OFFICE = 'office',
    }

    export enum birth_era_name {
        TAISHO = 'taisho',
        SHOWA = 'showa',
        HEISEI = 'heisei',
        REIWA = 'reiwa',
    }

    export enum notification_classification {
        OPENING = 'opening',
    }


}

