/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_basicInformation_address } from '../models/request_basicInformation_address';
import type { request_basicInformation_openDate } from '../models/request_basicInformation_openDate';
import type { request_basicInformation_personalInfo } from '../models/request_basicInformation_personalInfo';
import type { request_basicInformation_specialDeductionAmount } from '../models/request_basicInformation_specialDeductionAmount';
import type { request_basicInformation_submissionMethod } from '../models/request_basicInformation_submissionMethod';
import type { request_basicInformation_taxOfficeName } from '../models/request_basicInformation_taxOfficeName';
import type { request_basicInformation_taxReturnType } from '../models/request_basicInformation_taxReturnType';
import type { response_basicInformation } from '../models/response_basicInformation';
import type { response_basicInformation_address } from '../models/response_basicInformation_address';
import type { response_basicInformation_isUltraUrgent } from '../models/response_basicInformation_isUltraUrgent';
import type { response_basicInformation_openDate } from '../models/response_basicInformation_openDate';
import type { response_basicInformation_personalInfo } from '../models/response_basicInformation_personalInfo';
import type { response_basicInformation_specialDeductionAmount } from '../models/response_basicInformation_specialDeductionAmount';
import type { response_basicInformation_submissionMethod } from '../models/response_basicInformation_submissionMethod';
import type { response_basicInformation_taxOfficeName } from '../models/response_basicInformation_taxOfficeName';
import type { response_basicInformation_taxReturnType } from '../models/response_basicInformation_taxReturnType';
import type { response_basicInformations_downloaded } from '../models/response_basicInformations_downloaded';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BasicInformationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 確定申告完了ステータス取得API
     * パラメータで指定された年度にTAX HEROで確定申告を行っているかどうかのステータスを返すAPI。
     * @param year
     * @returns response_basicInformations_downloaded Example response
     * @throws ApiError
     */
    public getBasicInformationsDownloaded(
        year: number,
    ): CancelablePromise<response_basicInformations_downloaded> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_informations/downloaded',
            query: {
                'year': year,
            },
        });
    }

    /**
     * 確定申告基本情報取得API
     * 会計年度の確定申告基本情報を取得する
     * @returns response_basicInformation Example response
     * @throws ApiError
     */
    public getBasicInformation(): CancelablePromise<response_basicInformation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_information',
        });
    }

    /**
     * 確定申告 あなたの情報更新API
     * 確定申告アンケであなたの情報を更新する
     * @param requestBody
     * @returns response_basicInformation_personalInfo Example response
     * @throws ApiError
     */
    public patchBasicInformationPersonalInfo(
        requestBody?: request_basicInformation_personalInfo,
    ): CancelablePromise<response_basicInformation_personalInfo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/personal_info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 住所更新API
     * 会計年度の住所を更新する
     * @param requestBody
     * @returns response_basicInformation_address Example response
     * @throws ApiError
     */
    public patchBasicInformationAddress(
        requestBody?: request_basicInformation_address,
    ): CancelablePromise<response_basicInformation_address> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/address',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 提出先税務署更新API
     * 会計年度の提出先税務署を更新する
     * @param requestBody
     * @returns response_basicInformation_taxOfficeName Example response
     * @throws ApiError
     */
    public patchBasicInformationTaxOfficeName(
        requestBody?: request_basicInformation_taxOfficeName,
    ): CancelablePromise<response_basicInformation_taxOfficeName> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/tax_office_name',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Example response`,
            },
        });
    }

    /**
     * 提出方法更新API
     * 会計年度の確定申告書の提出方法を更新する
     * @param requestBody
     * @returns response_basicInformation_submissionMethod Example response
     * @throws ApiError
     */
    public patchBasicInformationSubmissionMethod(
        requestBody?: request_basicInformation_submissionMethod,
    ): CancelablePromise<response_basicInformation_submissionMethod> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/submission_method',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業日更新API
     * 開業日を更新する
     * @param requestBody
     * @returns response_basicInformation_openDate Example response
     * @throws ApiError
     */
    public patchBasicInformationOpenDate(
        requestBody?: request_basicInformation_openDate,
    ): CancelablePromise<response_basicInformation_openDate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/open_date',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 申告方式更新API
     * 申告方式を更新する
     * @param requestBody
     * @returns response_basicInformation_taxReturnType Example response
     * @throws ApiError
     */
    public patchBasicInformationTaxReturnType(
        requestBody?: request_basicInformation_taxReturnType,
    ): CancelablePromise<response_basicInformation_taxReturnType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/tax_return_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 控除額更新API
     * 確定申告の控除額を更新する
     * @param requestBody
     * @returns response_basicInformation_specialDeductionAmount Example response
     * @throws ApiError
     */
    public patchBasicInformationSpecialDeductionAmount(
        requestBody?: request_basicInformation_specialDeductionAmount,
    ): CancelablePromise<response_basicInformation_specialDeductionAmount> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/special_deduction_amount',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ULTRA年明けフラグ取得API
     * ULTRA年明けフラグを取得する。
     * @returns response_basicInformation_isUltraUrgent Example response
     * @throws ApiError
     */
    public getBasicInformationIsUltraUrgent(): CancelablePromise<response_basicInformation_isUltraUrgent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_information/is_ultra_urgent',
        });
    }

}
