/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { archivedInvoice } from '../models/archivedInvoice';
import type { request_task } from '../models/request_task';
import type { response_task } from '../models/response_task';
import type { response_task_current } from '../models/response_task_current';
import type { response_taskTodos } from '../models/response_taskTodos';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaskService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 請求書タスクアーカイブAPI
     * 今月のタスクで表示されている請求書タスクをアーカイブするAPI。
     * @param requestBody
     * @returns archivedInvoice Example response
     * @throws ApiError
     */
    public patchArchivedInvoices(
        requestBody?: archivedInvoice,
    ): CancelablePromise<archivedInvoice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/archived_invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * タスク更新API
     * タスクをアーカイブする際に使用する。
     * @param taskId タスクID
     * @param requestBody
     * @returns response_task Example response
     * @throws ApiError
     */
    public patchTasksTaskId(
        taskId: number,
        requestBody?: request_task,
    ): CancelablePromise<response_task> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 今月タスク取得API
     * 今月のタスクを取得する。
     * @returns response_task_current Example response
     * @throws ApiError
     */
    public getTasksCurrent(): CancelablePromise<response_task_current> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tasks/current',
        });
    }

    /**
     * タスクTODO取得API
     * Pathパラメータで指定されたタスクの詳細情報を取得する。
     * @param taskCode タスクの管理コード
     * @returns response_taskTodos Example response
     * @throws ApiError
     */
    public getTaskTodosTaskCode(
        taskCode: string,
    ): CancelablePromise<response_taskTodos> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/task_todos/{task_code}',
            path: {
                'task_code': taskCode,
            },
        });
    }

}
