/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_unprocessableEntity = {
    status: response_errors_unprocessableEntity.status;
    message: response_errors_unprocessableEntity.message;
};

export namespace response_errors_unprocessableEntity {

    export enum status {
        ERROR = 'error',
    }

    export enum message {
        UNPROCESSABLE_ENTITY = 'Unprocessable Entity',
    }


}

