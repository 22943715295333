/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type surveySubmissionInformation = {
    my_number_card_created_status: surveySubmissionInformation.my_number_card_created_status | null;
    digital_certificate_reissue_required: surveySubmissionInformation.digital_certificate_reissue_required | null;
    e_tax_user_id_issued_status: surveySubmissionInformation.e_tax_user_id_issued_status | null;
    e_tax_login_succeeded_status: surveySubmissionInformation.e_tax_login_succeeded_status | null;
};

export namespace surveySubmissionInformation {

    export enum my_number_card_created_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
        UNKNOWN = 'unknown',
    }

    export enum digital_certificate_reissue_required {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum e_tax_user_id_issued_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
        UNKNOWN = 'unknown',
    }

    export enum e_tax_login_succeeded_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
        UNKNOWN = 'unknown',
    }


}

