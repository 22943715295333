/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type surveyDeductionInformation = {
    earthquake_insurance_status: surveyDeductionInformation.earthquake_insurance_status | null;
    life_insurance_status: surveyDeductionInformation.life_insurance_status | null;
    national_pension_status: surveyDeductionInformation.national_pension_status | null;
    national_health_status: surveyDeductionInformation.national_health_status | null;
    national_pension_fund_status: surveyDeductionInformation.national_pension_fund_status | null;
    other_insurance_status: surveyDeductionInformation.other_insurance_status | null;
    private_pension_status: surveyDeductionInformation.private_pension_status | null;
    corporate_pension_status: surveyDeductionInformation.corporate_pension_status | null;
    small_business_mutual_aid_status: surveyDeductionInformation.small_business_mutual_aid_status | null;
    medical_expense_status: surveyDeductionInformation.medical_expense_status | null;
    hometown_donation_status: surveyDeductionInformation.hometown_donation_status | null;
    exc_hometown_donation_status: surveyDeductionInformation.exc_hometown_donation_status | null;
    spouse_status: surveyDeductionInformation.spouse_status | null;
    family_status: surveyDeductionInformation.family_status | null;
    housing_loan_status: surveyDeductionInformation.housing_loan_status | null;
    estimated_tax_prepayment_status: surveyDeductionInformation.estimated_tax_prepayment_status | null;
};

export namespace surveyDeductionInformation {

    export enum earthquake_insurance_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum life_insurance_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum national_pension_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum national_health_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum national_pension_fund_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum other_insurance_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum private_pension_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum corporate_pension_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum small_business_mutual_aid_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum medical_expense_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum hometown_donation_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum exc_hometown_donation_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum spouse_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum family_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum housing_loan_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum estimated_tax_prepayment_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }


}

