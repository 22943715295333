/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * spouse: 配偶者,
 * child: 子,
 * father: 父,
 * mother: 母,
 * grandfather: 祖父,
 * grandmother: 祖母,
 * grandchild: 孫,
 * other: その他
 */
export enum familyRelationship {
    SPOUSE = 'spouse',
    CHILD = 'child',
    FATHER = 'father',
    MOTHER = 'mother',
    GRANDFATHER = 'grandfather',
    GRANDMOTHER = 'grandmother',
    GRANDCHILD = 'grandchild',
    OTHER = 'other',
}
