/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_zoAdmin_ultra_marunageProgress_staffJournalize = {
    status: response_zoAdmin_ultra_marunageProgress_staffJournalize.status;
};

export namespace response_zoAdmin_ultra_marunageProgress_staffJournalize {

    export enum status {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

