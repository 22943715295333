/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_badRequestWithMessage = {
    status: response_errors_badRequestWithMessage.status;
    messages: Array<string>;
};

export namespace response_errors_badRequestWithMessage {

    export enum status {
        ERROR = 'error',
    }


}

