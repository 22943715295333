/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { response_invoice_numbers_isDuplicated } from '../models/response_invoice_numbers_isDuplicated';
import type { response_invoice_presignedUrl } from '../models/response_invoice_presignedUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 請求書画像アップロード用の署名付きURL取得API
     * 請求書画像アップロード用の署名付きURLを取得する。
     * @param kind
     * @param extension
     * @returns response_invoice_presignedUrl Example response
     * @throws ApiError
     */
    public getInvoicePresignedUrl(
        kind: 'seal' | 'logo',
        extension: 'jpeg' | 'png' | 'gif',
    ): CancelablePromise<response_invoice_presignedUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invoice/presigned_url',
            query: {
                'kind': kind,
                'extension': extension,
            },
        });
    }

    /**
     * 捺印画像削除API
     * 捺印画像を削除する
     * @returns void
     * @throws ApiError
     */
    public deleteCompanySealUrl(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/company/seal_url',
        });
    }

    /**
     * 自社ロゴ削除API
     * 自社ロゴを削除する
     * @returns void
     * @throws ApiError
     */
    public deleteCompanyLogoUrl(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/company/logo_url',
        });
    }

    /**
     * 請求書番号重複チェックAPI
     * 請求書番号の重複チェックを行う。
     * @param number
     * @param id
     * @returns response_invoice_numbers_isDuplicated Example response
     * @throws ApiError
     */
    public getInvoiceNumbersIsDuplicated(
        number: string,
        id?: number,
    ): CancelablePromise<response_invoice_numbers_isDuplicated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invoice/numbers/is_duplicated',
            query: {
                'number': number,
                'id': id,
            },
        });
    }

}
