/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { zoAdmin_cashReceiptImages_journal } from './zoAdmin_cashReceiptImages_journal';

export type response_zoAdmin_cashReceiptImage = {
    id: number;
    file_name: string;
    url: string;
    uploaded_at: string;
    user_id: number;
    user_name: string;
    taxable_type: response_zoAdmin_cashReceiptImage.taxable_type;
    journalized: boolean;
    journal: zoAdmin_cashReceiptImages_journal | null;
};

export namespace response_zoAdmin_cashReceiptImage {

    export enum taxable_type {
        EXEMPTION = 'exemption',
        SIMPLE = 'simple',
        PROPORTION = 'proportion',
        ITEM = 'item',
        FULL = 'full',
    }


}

