/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_export_books_mail = {
    character_code: request_export_books_mail.character_code;
};

export namespace request_export_books_mail {

    export enum character_code {
        SHIFT_JIS = 'Shift_JIS',
        UTF_8 = 'UTF-8',
    }


}

