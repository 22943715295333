/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_journals_import = {
    file_name: string;
    file_type: request_journals_import.file_type;
    file: Blob;
};

export namespace request_journals_import {

    export enum file_type {
        TEXT_CSV = 'text/csv',
    }


}

