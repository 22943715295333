/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_image_logo } from '../models/request_image_logo';
import type { request_image_seal } from '../models/request_image_seal';
import type { response_image_logo } from '../models/response_image_logo';
import type { response_image_seal } from '../models/response_image_seal';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 捺印画像アップロードAPI
     * 捺印画像をアップロードする
     * @param formData
     * @returns response_image_seal Example response
     * @throws ApiError
     */
    public postImagesSeal(
        formData?: request_image_seal,
    ): CancelablePromise<response_image_seal> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/images/seal',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * 自社ロゴアップロードAPI
     * 自社のロゴをアップロードする
     * @param formData
     * @returns response_image_logo Example response
     * @throws ApiError
     */
    public postImagesLogo(
        formData?: request_image_logo,
    ): CancelablePromise<response_image_logo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/images/logo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
