/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_upload_taxReturn_images } from '../models/request_upload_taxReturn_images';
import type { response_upload_taxReturn_presignedUrl } from '../models/response_upload_taxReturn_presignedUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UploadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 確定申告用画像アップロードURL取得API
     * 確定申告用の画像アップロードURLを取得する。
     * @param kind
     * @param extension
     * @returns response_upload_taxReturn_presignedUrl Example response
     * @throws ApiError
     */
    public getUploadTaxReturnPresignedUrl(
        kind: 'opening' | 'blue' | 'last_statement' | 'balance' | 'invoice' | 'cash_receipt' | 'income' | 'deduction' | 'transaction' | 'required_document',
        extension: 'jpeg' | 'png' | 'pdf' | 'csv',
    ): CancelablePromise<response_upload_taxReturn_presignedUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/upload/tax_return/presigned_url',
            query: {
                'kind': kind,
                'extension': extension,
            },
        });
    }

    /**
     * 確定申告用画像アップロード情報作成API
     * 確定申告用の画像アップロード情報を作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postUploadTaxReturnImages(
        requestBody?: request_upload_taxReturn_images,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/upload/tax_return/images',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
