/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_preview_consumptionTax_appendix_page53 = {
    is_necessary: boolean;
    page_5_3: {
        page_1: {
            tax_start_era_code: response_preview_consumptionTax_appendix_page53.tax_start_era_code;
            tax_start_era_year: number;
            tax_start_month: number;
            tax_start_date: number;
            tax_end_era_code: response_preview_consumptionTax_appendix_page53.tax_end_era_code;
            tax_end_era_year: number;
            tax_end_month: number;
            tax_end_date: number;
            name: string;
            basis_of_deductible_purchase_tax: {
                sales_tax: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
                collection_bad_debt_tax: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
                sales_return_tax: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
                basis_of_deductible_purchase_tax: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
            };
            one_type_of_business: {
                business_division: number;
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            } | null;
            two_or_more_type_of_business: {
                taxable_sales_amount_without_tax_by_division: {
                    total: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    };
                    division_1: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                    division_2: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                    division_3: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                    division_4: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                    division_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                    division_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total: {
                            amount: number;
                            sales_rate: number;
                        };
                    } | null;
                } | null;
                sales_tax_amount_by_business_division: {
                    total: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    };
                    division_1: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_2: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_3: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_4: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                } | null;
            };
        };
        page_2: {
            principle: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            } | null;
            exceptions: {
                exception_1: {
                    business_division: number;
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                } | null;
                exception_2: {
                    division_1_2: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_1_3: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_1_4: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_1_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_1_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_2_3: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_2_4: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_2_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_2_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_3_4: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_3_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_3_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_4_5: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_4_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                    division_5_6: {
                        rate_6_24_amount: number;
                        rate_7_8_amount: number;
                        total_amount: number;
                    } | null;
                } | null;
            };
            deductible_purchase_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
        };
    } | null;
};

export namespace response_preview_consumptionTax_appendix_page53 {

    export enum tax_start_era_code {
        R = 'R',
    }

    export enum tax_end_era_code {
        R = 'R',
    }


}

