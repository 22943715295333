/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_journals_totalAmount = {
    /**
     * 決算報告書カテゴリ
     */
    category_type: response_journals_totalAmount.category_type;
    /**
     * 借方の合計金額
     */
    debit_amount: number;
    /**
     * 貸方の合計金額
     */
    credit_amount: number;
};

export namespace response_journals_totalAmount {

    /**
     * 決算報告書カテゴリ
     */
    export enum category_type {
        ASSET = 'asset',
        LIABILITIES = 'liabilities',
        EQUITY = 'equity',
        REVENUE = 'revenue',
        EXPENSE = 'expense',
    }


}

