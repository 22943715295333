/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum requiredDocuments_code {
    SALARY = 'salary',
    PREFECTURE_DONATION = 'prefecture_donation',
    NATIONAL_PENSION = 'national_pension',
    NATIONAL_PENSION_FUND = 'national_pension_fund',
    NATIONAL_HEALTH = 'national_health',
    LIFE = 'life',
    EARTHQUAKE = 'earthquake',
    ESTIMATED_TAX_PREPAYMENT = 'estimated_tax_prepayment',
    OTHER_SOCIAL_INSURANCE = 'other_social_insurance',
    INDIVIDUAL_PENSION = 'individual_pension',
    CORPORATE_PENSION = 'corporate_pension',
    SMBC = 'smbc',
    COUNSELOR_FEE = 'counselor_fee',
}
