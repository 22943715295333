/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { journal } from './journal';

export type atTransaction = {
    id: number;
    amount: number;
    remarks: string;
    is_income: boolean;
    issued_at: string;
    is_editable: boolean;
    is_bank: boolean;
    journals: Array<journal>;
    account_id: number | null;
    account_name: string | null;
    is_created_topic: boolean;
    topic_status: atTransaction.topic_status | null;
};

export namespace atTransaction {

    export enum topic_status {
        OPENING = 'opening',
        CLOSED = 'closed',
    }


}

