import { Box, BoxProps } from '@chakra-ui/react'

export const RequiredIndicator: React.VFC<BoxProps> = (props) => {
  return (
    <Box
      bgColor="alert.primary"
      fontSize="10px"
      lineHeight={1}
      pos="absolute"
      bottom="2px"
      py="2px"
      px="4px"
      color="white.100"
      borderRadius="4px"
      display="inline-block"
      w="max-content"
      ml="8px"
      {...props}
    >
      必須
    </Box>
  )
}
