/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type family_patch = {
    last_name: string;
    first_name: string;
    relationship: family_patch.relationship;
    income_amount: number;
    birthday: string;
    residence_division: family_patch.residence_division;
    address: string;
    disability_division: family_patch.disability_division;
    has_another_relative: boolean;
};

export namespace family_patch {

    export enum relationship {
        SPOUSE = 'spouse',
        CHILD = 'child',
        FATHER = 'father',
        MOTHER = 'mother',
        GRANDFATHER = 'grandfather',
        GRANDMOTHER = 'grandmother',
        GRANDCHILD = 'grandchild',
        OTHER = 'other',
    }

    export enum residence_division {
        TOGETHER = 'together',
        SEPARATELY = 'separately',
        OUTSIDE_JAPAN = 'outside_japan',
    }

    export enum disability_division {
        NOT_DISABILITY = 'not_disability',
        DISABLED = 'disabled',
        SPECIALLY_DISABLED = 'specially_disabled',
    }


}

