/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_ultra_tasks_taxReturnType = {
    has_blue_declaration: boolean;
    submit_date: string | null;
    applied_blue_year: number | null;
    opening_date: string | null;
    is_submitted: boolean | null;
    is_applicable_this_year: boolean | null;
    tax_return_type: request_ultra_tasks_taxReturnType.tax_return_type;
};

export namespace request_ultra_tasks_taxReturnType {

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

