/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_topicMessages = {
    topic_id: number;
    message_type: request_topicMessages.message_type;
    contents: {
        text: string;
    };
};

export namespace request_topicMessages {

    export enum message_type {
        TEXT = 'text',
    }


}

