/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_notFound = {
    status: response_errors_notFound.status;
    message: response_errors_notFound.message;
};

export namespace response_errors_notFound {

    export enum status {
        ERROR = 'error',
    }

    export enum message {
        NOT_FOUND = 'Not Found',
    }


}

