/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type medicalExpenseDetail = {
    insurer_name: string;
    insured_first_name: string;
    insured_last_name: string;
    /**
     * care: 診療・治療、
     * nursing: 介護保険サービス、
     * medicine: 医薬品購入、
     * other: その他の医療費
     */
    medical_expense_division: medicalExpenseDetail.medical_expense_division;
    payment_amount: number;
    transportation_expense_amount: number;
    compensation_amount: number;
};

export namespace medicalExpenseDetail {

    /**
     * care: 診療・治療、
     * nursing: 介護保険サービス、
     * medicine: 医薬品購入、
     * other: その他の医療費
     */
    export enum medical_expense_division {
        CARE = 'care',
        NURSING = 'nursing',
        MEDICINE = 'medicine',
        OTHER = 'other',
    }


}

