/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_marunageProgresses } from '../models/request_marunageProgresses';
import type { response_marunageProgresses } from '../models/response_marunageProgresses';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MarunageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * MARUNAGE進捗取得API
     * MARUNAGEのタスクの進捗を取得する。
     * @returns response_marunageProgresses Example response
     * @throws ApiError
     */
    public getMarunageProgresses(): CancelablePromise<response_marunageProgresses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/marunage_progresses',
        });
    }

    /**
     * MARUNAGE進捗更新API
     * MARUNAGEのタスクの進捗を更新する。
     * @param requestBody
     * @returns response_marunageProgresses Example response
     * @throws ApiError
     */
    public patchMarunageProgresses(
        requestBody?: request_marunageProgresses,
    ): CancelablePromise<response_marunageProgresses> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/marunage_progresses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
