/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { nullablePrefectureCode } from './nullablePrefectureCode';

export type response_opening = {
    last_name: string;
    first_name: string;
    last_name_kana: string;
    first_name_kana: string;
    birthday: string | null;
    /**
     * ハイフン区切り
     */
    zip_code: string;
    prefecture_code: nullablePrefectureCode;
    address: string;
    /**
     * ハイフン区切り
     */
    tel: string;
    occupation: string;
    job_description: string;
    workplace: response_opening.workplace;
    /**
     * ハイフン区切り
     */
    business_zip_code: string;
    business_prefecture_code: nullablePrefectureCode;
    business_address: string;
    /**
     * ハイフン区切り
     */
    business_tel: string;
    is_office_tax_payment_place: boolean;
    open_date: string | null;
    business_name: string;
    business_name_kana: string;
    has_income_from_real_estate: boolean;
    has_income_from_deforestation: boolean;
    tax_return_type: response_opening.tax_return_type | null;
    tax_office_name: string;
};

export namespace response_opening {

    export enum workplace {
        HOME = 'home',
        OFFICE = 'office',
        NOT_DETERMINED = 'not_determined',
    }

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

