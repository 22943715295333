/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type socialInsurance = {
    /**
     * health: 健康保険、
     * national_health: 国民健康保険、
     * employee_pension: 厚生年金、
     * employee_pension_fund: 厚生年金基金、
     * national_pension: 国民年金、
     * national_pension_fund: 国民年金基金、
     * care: 介護保険、
     * senior_medical: 後期高齢者医療保険、
     * other: その他
     */
    insurance_type: socialInsurance.insurance_type;
    insurance_type_name: string | null;
    amount: number;
};

export namespace socialInsurance {

    /**
     * health: 健康保険、
     * national_health: 国民健康保険、
     * employee_pension: 厚生年金、
     * employee_pension_fund: 厚生年金基金、
     * national_pension: 国民年金、
     * national_pension_fund: 国民年金基金、
     * care: 介護保険、
     * senior_medical: 後期高齢者医療保険、
     * other: その他
     */
    export enum insurance_type {
        HEALTH = 'health',
        NATIONAL_HEALTH = 'national_health',
        EMPLOYEE_PENSION = 'employee_pension',
        EMPLOYEE_PENSION_FUND = 'employee_pension_fund',
        NATIONAL_PENSION = 'national_pension',
        NATIONAL_PENSION_FUND = 'national_pension_fund',
        CARE = 'care',
        SENIOR_MEDICAL = 'senior_medical',
        OTHER = 'other',
    }


}

