/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_export_books_mail } from '../models/request_export_books_mail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * 帳簿一覧ダウンロードAPI
     * 帳簿一覧のzipファイルをダウンロードする
     * @returns binary OK
     * @throws ApiError
     */
    public getExportBooks(): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/export/books',
        });
    }

    /**
     * 帳簿ダウンロードAPI（非同期）
     * 帳簿ダウンロードのリクエストを行う。（帳簿は非同期で作成後メール送信される）
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public postExportBooksMail(
        requestBody?: request_export_books_mail,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/export/books/mail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
