/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_ultra_tasks_eTax = {
    my_number_card_created_status: request_ultra_tasks_eTax.my_number_card_created_status | null;
    e_tax_user_id_issued_status: request_ultra_tasks_eTax.e_tax_user_id_issued_status | null;
    e_tax_user_id: string | null;
    e_tax_login_succeeded_status: request_ultra_tasks_eTax.e_tax_login_succeeded_status | null;
    e_certificate_signature_password_status: request_ultra_tasks_eTax.e_certificate_signature_password_status | null;
    digital_certificate_reissue_status: request_ultra_tasks_eTax.digital_certificate_reissue_status | null;
};

export namespace request_ultra_tasks_eTax {

    export enum my_number_card_created_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum e_tax_user_id_issued_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum e_tax_login_succeeded_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum e_certificate_signature_password_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum digital_certificate_reissue_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }


}

