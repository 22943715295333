/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_topicMessagePostbackActions = {
    topic_id: number;
    action_type: request_topicMessagePostbackActions.action_type;
    data: string;
};

export namespace request_topicMessagePostbackActions {

    export enum action_type {
        JOURNAL_PRIVATE_OR_NOT_REPLIED = 'journal.private_or_not_replied',
        JOURNAL_USAGE_REPLIED = 'journal.usage_replied',
    }


}

