/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_preview_consumptionTax_appendix_page6 = {
    is_necessary: boolean;
    page_6: {
        tax_start_era_code: response_preview_consumptionTax_appendix_page6.tax_start_era_code;
        tax_start_era_year: number;
        tax_start_month: number;
        tax_start_date: number;
        tax_end_era_code: response_preview_consumptionTax_appendix_page6.tax_end_era_code;
        tax_end_era_year: number;
        tax_end_month: number;
        tax_end_date: number;
        name: string;
        taxable_assets_transfer: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        sales_tax_base: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        sales_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        collection_bad_debt_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        sales_return_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        basis_of_deductible_purchase_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        deductible_purchase_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        bad_debt_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
    } | null;
};

export namespace response_preview_consumptionTax_appendix_page6 {

    export enum tax_start_era_code {
        R = 'R',
    }

    export enum tax_end_era_code {
        R = 'R',
    }


}

