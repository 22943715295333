/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_ultra_marunageProgress_staffJournalize = {
    user_id: number;
    year: number;
    status: request_zoAdmin_ultra_marunageProgress_staffJournalize.status;
};

export namespace request_zoAdmin_ultra_marunageProgress_staffJournalize {

    export enum status {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

