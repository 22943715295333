import { SystemStyleFunction } from '@chakra-ui/theme-tools'

// カテゴリ登録ボタンで使われるボタンのテーマ
const variantWithIcon: SystemStyleFunction = (props) => ({
  borderRadius: '8px',
  border: '1px solid',
  borderWidth: props['aria-selected'] ? '2px' : '1px',
  borderColor: props['aria-selected'] ? 'primary.purple' : 'gray.200',
  _hover: {
    bgColor: props['aria-selected'] ? 'purple.50' : 'gray.70',
    border: '1px solid',
    borderWidth: props['aria-selected'] ? '2px' : '1px',
    borderColor: props['aria-selected'] ? 'primary.purple' : 'gray.200'
  },
  _focus: {
    boxShadow: 'none'
  }
})

export const ButtonTheme = {
  baseStyle: {
    fontWeight: 500, // Normally, it is "semibold"
    borderRadius: '4px',
    fontSize: '14px',
    px: '12px',
    textDecoration: 'none',
    _focus: {
      boxShadow: 'none'
    }
  },

  sizes: {
    sm: {
      minHeight: '30px',
      h: '30px',
      fontSize: '13px',
      lineHeight: '13px',
      minW: 0,
      w: 'fit-content',
      px: '12px',
      py: '8.5px'
    },

    md: {
      minH: '36px',
      h: '36px',
      minW: '36px',
      px: '12px',
      fontSize: '13px',
      lineHeight: '13px'
    },

    lg: {
      minH: '48px',
      h: '48px',
      w: 'fit-content',
      px: '20px',
      py: '8.5px',
      fontSize: '14px',
      lineHeight: '14px'
    },
    small: {
      minHeight: '44px',
      fontSize: '14px'
    }
  },

  variants: {
    secondary: {
      bg: 'primary.black',
      color: 'white.100',
      boxShadow: '0px 2px 4px 0px rgb(0, 0, 0, 0.04)',
      _disabled: {
        opacity: 1,
        bgColor: 'disable'
      },
      _hover: {
        _disabled: {
          bgColor: 'disable'
        }
      }
    },
    primary: {
      bgColor: 'primary.black',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
      color: 'white.100',
      _disabled: {
        bgColor: 'gray.200',
        opacity: 1,
        color: 'gray.500',
        _hover: {
          bgColor: 'gray.200'
        }
      },
      _hover: {
        _disabled: {
          opacity: 1,
          bgColor: 'gray.200'
        }
      },
      _focus: {
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)'
      }
    },
    orange: {
      bgColor: 'primary.orange',
      color: 'white.100',
      borderRadius: '8px',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.2)'
    },
    danger: {
      bg: 'alert.primary',
      color: 'white.100'
    },
    outline: {
      bg: 'white.100',
      borderColor: 'gray.200',
      color: 'primary.black'
    },
    cancel: {
      bg: 'white.100',
      border: '1px solid',
      borderColor: 'gray.200',
      color: 'gray.800'
    },
    zoAdmin: {
      bgColor: 'navy.primary',
      color: 'white.100'
    },
    zoAdminOutline: {
      bgColor: 'navy.50',
      border: '1px solid',
      borderColor: 'navy.200'
    },
    withIcon: variantWithIcon
  },

  defaultProps: {
    variant: 'primary',
    size: 'lg'
  }
}
