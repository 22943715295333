/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_upload_taxReturn_images = {
    kind: request_upload_taxReturn_images.kind;
    images: Array<{
        file_name: string;
        url: string;
    }>;
};

export namespace request_upload_taxReturn_images {

    export enum kind {
        OPENING = 'opening',
        BLUE = 'blue',
        LAST_STATEMENT = 'last_statement',
        BALANCE = 'balance',
        INVOICE = 'invoice',
        CASH_RECEIPT = 'cash_receipt',
        INCOME = 'income',
        DEDUCTION = 'deduction',
    }


}

