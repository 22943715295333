/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_openingBalanceQuestion_filledTaxReturn } from '../models/request_openingBalanceQuestion_filledTaxReturn';
import type { request_openingBalanceQuestion_taxReturnType } from '../models/request_openingBalanceQuestion_taxReturnType';
import type { response_openingBalanceQuestion } from '../models/response_openingBalanceQuestion';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OpeningBalanceQuestionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 開始残高入力に関する質問情報取得API
     * 開始残高の入力に関する質問情報を返すAPI。
     * @returns response_openingBalanceQuestion Example response
     * @throws ApiError
     */
    public getOpeningBalanceQuestion(): CancelablePromise<response_openingBalanceQuestion> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening_balance_question',
        });
    }

    /**
     * 開始残高入力に関する「確定申告の経験」質問更新API
     * 開始残高の入力に関する質問のうち、「確定申告の経験」情報を更新するAPI。
     * @param requestBody
     * @returns response_openingBalanceQuestion Example response
     * @throws ApiError
     */
    public patchOpeningBalanceQuestionFilledTaxReturn(
        requestBody?: request_openingBalanceQuestion_filledTaxReturn,
    ): CancelablePromise<response_openingBalanceQuestion> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening_balance_question/filled_tax_return',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開始残高入力に関する「申告種別」質問更新API
     * 開始残高の入力に関する質問のうち、「申告種別」情報を更新するAPI。
     * @param requestBody
     * @returns response_openingBalanceQuestion Example response
     * @throws ApiError
     */
    public patchOpeningBalanceQuestionTaxReturnType(
        requestBody?: request_openingBalanceQuestion_taxReturnType,
    ): CancelablePromise<response_openingBalanceQuestion> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening_balance_question/tax_return_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
