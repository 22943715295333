export const CalendarTheme = {
  parts: ['calendar', 'months'],

  baseStyle: {
    calendar: {
      borderColor: 'white',
      shadow: 'none',
      p: 0
    },
    months: {
      p: 0
    }
  }
}

export const CalendarControlTheme = {
  parts: ['controls', 'button'],

  baseStyle: {
    button: {
      w: '10px',
      h: '30px',
      minH: '30px',
      color: 'primary.black',
      bg: 'transparent',
      lineHeight: 0,
      fontSize: 'md',
      rounded: 'md'
    },
    controls: {
      p: 0
    }
  },
  defaultProps: {
    button: {
      variant: 'ghost'
    }
  }
}

export const CalendarDayTheme = {
  baseStyle: {
    borderRadius: '4px',
    minW: '32px',
    w: '32px',
    minH: '32px',
    h: '32px',
    m: '4px',
    color: 'primary.black',
    _disabled: {
      color: 'gray.600',
      _hover: {
        cursor: 'initial',
        bgColor: 'transparent'
      }
    }
  },
  variants: {
    selected: {
      bgColor: 'primary.purple',
      color: 'white',
      _hover: {
        bgColor: 'primary.purple'
      }
    },

    range: {
      bgColor: 'purple.100',
      color: 'primary.black',

      _hover: {
        bgColor: 'purple.100'
      },

      _disabled: {
        _hover: {
          bgColor: 'purple.300'
        }
      }
    },

    outside: {
      color: 'gray.300'
    }
  }
}
