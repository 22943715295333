/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_zoAdmin_partnerTaxAccountantUsers = {
    user_type: response_zoAdmin_partnerTaxAccountantUsers.user_type;
    is_partner_tax_accountant_changeable: boolean;
    completed_first_meeting: boolean;
    completed_second_meeting: boolean;
    completed_third_meeting: boolean;
    first_meeting_id: number | null;
    second_meeting_id: number | null;
    third_meeting_id: number | null;
    partner_tax_accountants: Array<{
        id: number;
        name: string;
        meetings: Array<{
            id: number;
            processing_year: number;
            code: 'first' | 'second' | 'third';
            name: string;
            memo: string;
        }>;
    }>;
};

export namespace response_zoAdmin_partnerTaxAccountantUsers {

    export enum user_type {
        MARUNAGE = 'marunage',
        SEMI_MARUNAGE = 'semi_marunage',
    }


}

