/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_opening_taxReturnType = {
    tax_return_type: response_opening_taxReturnType.tax_return_type;
};

export namespace response_opening_taxReturnType {

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

