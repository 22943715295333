/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_preview_consumptionTax_appendix_page23 = {
    is_necessary: boolean;
    page_2_3: {
        tax_start_era_code: response_preview_consumptionTax_appendix_page23.tax_start_era_code;
        tax_start_era_year: number;
        tax_start_month: number;
        tax_start_date: number;
        tax_end_era_code: response_preview_consumptionTax_appendix_page23.tax_end_era_code;
        tax_end_era_year: number;
        tax_end_month: number;
        tax_end_date: number;
        name: string;
        taxable_assets_transfer: {
            taxable_sales_without_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            tax_free_sales_amount: number;
            exempt_sales_amount: number;
            taxable_assets_transfer_amount: number;
        };
        assets_transfer: {
            taxable_assets_transfer_amount: number;
            exempt_sales_amount: number;
            assets_transfer_amount: number;
        };
        taxable_sales_rate: number;
        purchase_tax: {
            purchase: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            purchase_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            specific_taxable_purchase: {
                rate_7_8_amount: number;
                total_amount: number;
            };
            specific_taxable_purchase_tax: {
                rate_7_8_amount: number;
                total_amount: number;
            };
            taxable_goods_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            change_taxable_type_adjustment: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            total_purchase_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            ineligible_purchase: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            ineligible_purchase_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
        };
        taxable_type_full: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        } | null;
        taxable_type_others: {
            taxable_type_item: {
                taxable_purchase: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
                common_purchase: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
                deductible_purchase_tax: {
                    rate_6_24_amount: number;
                    rate_7_8_amount: number;
                    total_amount: number;
                };
            } | null;
            taxable_type_proportion: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            } | null;
        };
        deduction: {
            deductible_purchase_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
            deductible_overadjusted_tax: {
                rate_6_24_amount: number;
                rate_7_8_amount: number;
                total_amount: number;
            };
        };
        collection_bad_debt_tax: {
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
    } | null;
};

export namespace response_preview_consumptionTax_appendix_page23 {

    export enum tax_start_era_code {
        R = 'R',
    }

    export enum tax_end_era_code {
        R = 'R',
    }


}

