/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_taxReturnQuestions = {
    category: request_taxReturnQuestions.category;
    code: request_taxReturnQuestions.code;
    status: request_taxReturnQuestions.status;
};

export namespace request_taxReturnQuestions {

    export enum category {
        BUSINESS_INCOME = 'business_income',
        BASIC_INFORMATION = 'basic_information',
        INCOME_AND_OUTGO = 'income_and_outgo',
        CONFIRMATION = 'confirmation',
        SUBMISSION = 'submission',
    }

    export enum code {
        CHECKED_BUSINESS_INCOME = 'checked_business_income',
        PERSONAL_INFO = 'personal_info',
        ADDRESS = 'address',
        TAX_OFFICE_NAME = 'tax_office_name',
        SUBMISSION_METHOD = 'submission_method',
        OPEN_DATE = 'open_date',
        TAX_RETURN_TYPE = 'tax_return_type',
        SPECIAL_DEDUCTION_AMOUNT = 'special_deduction_amount',
        SALARIES = 'salaries',
        RETIREMENT_INCOMES = 'retirement_incomes',
        STOCK_INCOMES = 'stock_incomes',
        MISCELLANEOUS_INCOMES_CRYPTO = 'miscellaneous_incomes_crypto',
        MISCELLANEOUS_INCOMES_BUSINESS = 'miscellaneous_incomes_business',
        BUSINESS_INCOME_DETAILS = 'business_income_details',
        EXC_SOCIAL_INSURANCES = 'exc_social_insurances',
        FAMILY_SPOUSE = 'family_spouse',
        OTHER_FAMILIES = 'other_families',
        SOCIAL_INSURANCE_PENSION = 'social_insurance_pension',
        SOCIAL_INSURANCE_HEALTH = 'social_insurance_health',
        SOCIAL_INSURANCE_PENSION_FUND = 'social_insurance_pension_fund',
        OTHER_SOCIAL_INSURANCES = 'other_social_insurances',
        SMALL_BUSINESS_MUTUAL_AID_PENSIONS = 'small_business_mutual_aid_pensions',
        SMALL_BUSINESS_MUTUAL_AIDS = 'small_business_mutual_aids',
        MEDICAL_EXPENSES = 'medical_expenses',
        DONATION_PREFECTURE = 'donation_prefecture',
        OTHER_DONATIONS = 'other_donations',
        ESTIMATED_TAX_PREPAYMENTS = 'estimated_tax_prepayments',
        HOUSE_LOAN_DEDUCTIONS = 'house_loan_deductions',
        COUNSELOR_FEES = 'counselor_fees',
        EMPLOYEE_SALARIES = 'employee_salaries',
        CARRIED_FORWARD_LOSSES = 'carried_forward_losses',
        REFUND_ACCOUNTS = 'refund_accounts',
        E_TAX_USER_ID = 'e_tax_user_id',
        MY_NUMBER = 'my_number',
        SUBMIT_DATE = 'submit_date',
    }

    export enum status {
        NO = 'no',
        YES = 'yes',
    }


}

