/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type listedStockNoWithholdingSpecifiedAccountIncome = {
    id: number;
    listed_transfer_revenue_amount: number;
    listed_transfer_expense_amount: number;
    specified_margin_transfer_revenue_amount: number;
    specified_margin_transfer_expense_amount: number;
    another_transfer_expense_name: string;
    another_transfer_expense_amount: number;
    trader_name: string;
    trader_division: listedStockNoWithholdingSpecifiedAccountIncome.trader_division | null;
    another_trader_division_name: string | null;
    trader_branch_name: string;
    trader_branch_division: listedStockNoWithholdingSpecifiedAccountIncome.trader_branch_division | null;
    another_trader_branch_division_name: string | null;
    dividend_brand: string;
    dividend_payer_name: string;
    dividend_revenue_amount: number;
    dividend_withholding_amount: number;
    dividend_unpaid_withholding_amount: number;
    dividend_residential_tax_amount: number;
    debt_interest_amount: number;
};

export namespace listedStockNoWithholdingSpecifiedAccountIncome {

    export enum trader_division {
        SECURITIES_FIRM = 'securities_firm',
        BANK = 'bank',
        ANOTHER_TRADER_DIVISION = 'another_trader_division',
    }

    export enum trader_branch_division {
        HEAD = 'head',
        BRANCH = 'branch',
        SUB_BRANCH = 'sub_branch',
        ANOTHER_BRANCH_DIVISION = 'another_branch_division',
    }


}

