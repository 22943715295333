/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_payment_invoices } from '../models/request_payment_invoices';
import type { request_payment_subscriptions } from '../models/request_payment_subscriptions';
import type { response_payment_validatePromotionCode } from '../models/response_payment_validatePromotionCode';
import type { response_paymentMethod } from '../models/response_paymentMethod';
import type { response_subscription } from '../models/response_subscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * サブスクリプション作成API
     * POSTされた支払情報を元にサブスクリプションを作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postPaymentSubscriptions(
        requestBody?: request_payment_subscriptions,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/subscriptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Example response`,
                404: `Example response`,
            },
        });
    }

    /**
     * 請求処理実行API
     * POSTされた支払情報を元に請求処理を実行する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postPaymentInvoices(
        requestBody?: request_payment_invoices,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * プロモーションコード検証API
     * プロモーションコードを検証する。
     * @param promotionCode
     * @returns response_payment_validatePromotionCode Example response
     * @throws ApiError
     */
    public getPaymentValidatePromotionCode(
        promotionCode: string,
    ): CancelablePromise<response_payment_validatePromotionCode> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/validate_promotion_code',
            query: {
                'promotion_code': promotionCode,
            },
        });
    }

    /**
     * 有効サブスクリプション取得API
     * 現在有効となっているサブスクリプションデータを取得する。
     * @returns response_subscription Example response
     * @throws ApiError
     */
    public getSubscriptionsActive(): CancelablePromise<response_subscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/subscriptions/active',
        });
    }

    /**
     * 最新サブスクリプション取得API
     * 最新のサブスクリプションデータを取得する。
     * @returns response_subscription Example response
     * @throws ApiError
     */
    public getSubscriptionsLatest(): CancelablePromise<response_subscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/subscriptions/latest',
        });
    }

    /**
     * 支払い方法取得API
     * 支払い方法を取得する。
     * @returns response_paymentMethod Example response
     * @throws ApiError
     */
    public getPaymentMethod(): CancelablePromise<response_paymentMethod> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment_method',
        });
    }

}
