/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_fixedAsset = {
    id: number;
    name: string;
    acquisition_date: string;
    service_date: string;
    acquisition_amount: number;
    revised_acquisition_amount: number | null;
    /**
     * straight_line: 定額法, declining_balance: 定率法, petty_sum: 少額償却, lump_sum: 一括償却, straight_line_method: 均等償却, voluntary: 任意償却
     *
     */
    depreciation_method: response_fixedAsset.depreciation_method | null;
    durable_year: number | null;
    depreciation_rate: number | null;
    /**
     * round: 四捨五入, floor: 切り捨て, ceil: 切り上げ
     */
    amount_rounding: response_fixedAsset.amount_rounding;
    /**
     * draft: 下書き, depreciating: 償却中, removed: 除去済み, sold: 売却済み
     */
    status: response_fixedAsset.status;
    depreciation: {
        year: number;
        quantity: number;
        beginning_balance: number;
        depreciation_amount: number;
        special_depreciation_amount: number;
        business_rate: number;
    } | null;
    account_item: {
        id: number;
        name: string;
    };
    has_pre_year_depreciation: boolean;
};

export namespace response_fixedAsset {

    /**
     * straight_line: 定額法, declining_balance: 定率法, petty_sum: 少額償却, lump_sum: 一括償却, straight_line_method: 均等償却, voluntary: 任意償却
     *
     */
    export enum depreciation_method {
        STRAIGHT_LINE = 'straight_line',
        DECLINING_BALANCE = 'declining_balance',
        PETTY_SUM = 'petty_sum',
        LUMP_SUM = 'lump_sum',
        STRAIGHT_LINE_METHOD = 'straight_line_method',
        VOLUNTARY = 'voluntary',
    }

    /**
     * round: 四捨五入, floor: 切り捨て, ceil: 切り上げ
     */
    export enum amount_rounding {
        ROUND = 'round',
        FLOOR = 'floor',
        CEIL = 'ceil',
    }

    /**
     * draft: 下書き, depreciating: 償却中, removed: 除去済み, sold: 売却済み
     */
    export enum status {
        DRAFT = 'draft',
        DEPRECIATING = 'depreciating',
        REMOVED = 'removed',
        SOLD = 'sold',
    }


}

