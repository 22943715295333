/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { response_invitation } from '../models/response_invitation';
import type { response_invitation_code } from '../models/response_invitation_code';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvitationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 招待コード取得API
     * ログインユーザーの招待コードを返す
     * @returns response_invitation_code Example response
     * @throws ApiError
     */
    public getInvitationCode(): CancelablePromise<response_invitation_code> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invitation/code',
        });
    }

    /**
     * 招待情報取得API
     * パラメータで指定されたCodeを持つ招待者ユーザー名を返す
     * @param code 招待コード
     * @returns response_invitation Example response
     * @throws ApiError
     */
    public getInvitations(
        code: string,
    ): CancelablePromise<response_invitation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invitations/{code}',
            path: {
                'code': code,
            },
        });
    }

}
