/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type openingBalanceQuestion = {
    filled_tax_return: boolean | null;
    tax_return_type: openingBalanceQuestion.tax_return_type | null;
};

export namespace openingBalanceQuestion {

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

