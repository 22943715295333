/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_ultra_topics_id_status = {
    status: request_zoAdmin_ultra_topics_id_status.status;
};

export namespace request_zoAdmin_ultra_topics_id_status {

    export enum status {
        OPENING = 'opening',
        CLOSED = 'closed',
    }


}

