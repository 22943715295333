/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_accountItems = {
    status: response_accountItems.status;
    account_items: Array<{
        id: number;
        name: string;
        /**
         * -1: プライベート, 0: 資産, 1: 負債, 2: 純資産, 3: 収益, 4: 費用
         */
        category_type: -1 | 0 | 1 | 2 | 3 | 4;
        /**
         * 登録画面では使用しない
         */
        order_no: number;
        income_order_no: number | null;
        outgo_order_no: number | null;
        asset_outgo_order_no: number | null;
        has_deposit_and_withdrawal_date: boolean;
        enable_divide: boolean;
        enable_withholding: boolean;
        is_frequent: boolean;
        /**
         * is_frequentがtrueの場合はnullを返す
         */
        latest_used_type: 'income' | 'outgo' | null;
        account_sub_items: Array<{
            id: number;
            name: string;
            order_no: number;
        }>;
    }>;
};

export namespace response_accountItems {

    export enum status {
        SUCCESS = 'success',
    }


}

