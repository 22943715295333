/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_unauthorized = {
    status: response_errors_unauthorized.status;
    message: response_errors_unauthorized.message;
};

export namespace response_errors_unauthorized {

    export enum status {
        ERROR = 'error',
    }

    export enum message {
        UNAUTHORIZED = 'Unauthorized',
    }


}

