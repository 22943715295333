/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_historicalBalanceSheetDetails_patch } from '../models/request_historicalBalanceSheetDetails_patch';
import type { response_historicalBalanceSheetDetails } from '../models/response_historicalBalanceSheetDetails';
import type { response_historicalBalanceSheetDetails_differences } from '../models/response_historicalBalanceSheetDetails_differences';
import type { response_historicalBalanceSheetDetails_savingAccounts } from '../models/response_historicalBalanceSheetDetails_savingAccounts';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HistoricalBalanceSheetDetailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 開始残高取得API
     * 開始残高を取得する。
     * @returns response_historicalBalanceSheetDetails Example response
     * @throws ApiError
     */
    public getHistoricalBalanceSheetDetails(): CancelablePromise<response_historicalBalanceSheetDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/historical_balance_sheet_details',
        });
    }

    /**
     * 開始残高更新API
     * 開始残高を更新する。
     * @param requestBody
     * @returns response_historicalBalanceSheetDetails Example response
     * @throws ApiError
     */
    public patchHistoricalBalanceSheetDetails(
        requestBody?: request_historicalBalanceSheetDetails_patch,
    ): CancelablePromise<response_historicalBalanceSheetDetails> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/historical_balance_sheet_details',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 普通預金開始残高取得API
     * 普通預金の開始残高を取得する。
     * @returns response_historicalBalanceSheetDetails_savingAccounts Example response
     * @throws ApiError
     */
    public getHistoricalBalanceSheetDetailsSavingAccounts(): CancelablePromise<response_historicalBalanceSheetDetails_savingAccounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/historical_balance_sheet_details/saving_accounts',
        });
    }

    /**
     * 残高差分取得API
     * 勘定科目ごとの期末残高で差分のあるデータを取得する。
     * @returns response_historicalBalanceSheetDetails_differences Example response
     * @throws ApiError
     */
    public getHistoricalBalanceSheetDetailsDifferences(): CancelablePromise<response_historicalBalanceSheetDetails_differences> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/historical_balance_sheet_details/differences',
        });
    }

}
