/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_basicInformation_submissionMethod = {
    submission_method: response_basicInformation_submissionMethod.submission_method;
};

export namespace response_basicInformation_submissionMethod {

    export enum submission_method {
        E_TAX = 'e_tax',
        MAIL = 'mail',
        BRING = 'bring',
    }


}

