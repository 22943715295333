/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * myself: 本人,
 * husband: 夫,
 * wife: 妻,
 * child: 子,
 * father: 父,
 * mother: 母,
 * grandfather: 祖父,
 * grandmother: 祖母,
 * grandchild: 孫,
 * other: その他
 */
export enum headOfHouseholdRelationship {
    MYSELF = 'myself',
    HUSBAND = 'husband',
    WIFE = 'wife',
    CHILD = 'child',
    FATHER = 'father',
    MOTHER = 'mother',
    GRANDFATHER = 'grandfather',
    GRANDMOTHER = 'grandmother',
    GRANDCHILD = 'grandchild',
    OTHER = 'other',
}
