/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_user_userType = {
    user_type: response_user_userType.user_type;
};

export namespace response_user_userType {

    export enum user_type {
        NORMAL = 'normal',
        MARUNAGE = 'marunage',
        SEMI_MARUNAGE = 'semi_marunage',
    }


}

