import { selectAnatomy as parts } from '@chakra-ui/anatomy'
import { Select } from '@chakra-ui/react'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { mergeWith } from '@chakra-ui/utils'
import { SelectTriangleIcon } from 'src/components/common/Icons/SelectTriangleIcon'
import { InputTheme } from './input'

const baseStyleField: SystemStyleFunction = (props) => {
  return {
    ...InputTheme.baseStyle.field,
    pr: '24px',
    bgColor: 'white.100',
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    '> option, > optgroup': {
      bg: mode('white', 'gray.700')(props)
    }
  }
}

const baseStyleIcon: SystemStyleObject = {
  width: '20px',
  height: '20px',
  insetEnd: '8px',
  position: 'relative',
  color: 'currentColor',
  fontSize: '10px'
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon
})

const iconSpacing = { paddingInlineEnd: '2rem' }

const sizes: Record<string, PartsStyleObject<typeof parts>> = mergeWith(
  {},
  InputTheme.sizes,
  {
    lg: {
      field: iconSpacing
    },
    md: {
      field: iconSpacing
    },
    sm: {
      field: iconSpacing,
      height: '32px'
    },
    xs: {
      field: iconSpacing,
      icon: { insetEnd: '0.25rem' }
    }
  }
)

Select.defaultProps = {
  ...Select.defaultProps,
  icon: <SelectTriangleIcon />
}

export const SelectTheme = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants: InputTheme.variants,
  defaultProps: InputTheme.defaultProps
}
