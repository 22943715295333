import { formAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleRequiredIndicator: SystemStyleFunction = (props) => {
  return {
    marginStart: 1,
    color: mode('red.500', 'red.300')(props)
  }
}

const baseStyleHelperText: SystemStyleFunction = (props) => {
  return {
    fontWeight: 500,
    mt: '8px',
    color: 'primary.purple',
    bgColor: 'purple.50',
    lineHeight: '19.2px',
    fontSize: '12px',
    py: '8px',
    px: '12px',
    w: 'fit-content'
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: { width: '100%', position: 'relative' },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props)
})

export const FormTheme = {
  parts: parts.keys,
  baseStyle
}
