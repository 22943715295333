/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_errors_internalServerError = {
    status: response_errors_internalServerError.status;
    message: response_errors_internalServerError.message;
};

export namespace response_errors_internalServerError {

    export enum status {
        ERROR = 'error',
    }

    export enum message {
        INTERNAL_SERVER_ERROR = 'Internal Server Error',
    }


}

