/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type zoAdmin_ultra_statementFiles_uploadedFile = {
    upload_required_account_file_id: number;
    uploaded_at: string;
    file_name: string;
    url: string;
    import_status: zoAdmin_ultra_statementFiles_uploadedFile.import_status;
};

export namespace zoAdmin_ultra_statementFiles_uploadedFile {

    export enum import_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }


}

