/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_invoiceImage } from '../models/request_invoiceImage';
import type { response_invoiceImage } from '../models/response_invoiceImage';
import type { response_invoiceImages } from '../models/response_invoiceImages';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceImageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 請求書ファイル情報一覧取得API
     * 請求書ファイル情報の一覧を取得する。
     * @param page
     * @param since
     * @param until
     * @param fileName
     * @param isDeleted
     * @param sortColumns 並び替えを行うカラム名の配列
     * @param sortDirections 並び替えの方法の配列
     * @param clientIds 顧客IDの配列
     * @param remarks 概要
     * @param amountMin 最小金額
     * @param amountMax 金額最大
     * @returns response_invoiceImages Example response
     * @throws ApiError
     */
    public getInvoiceImages(
        page?: number,
        since?: string,
        until?: string,
        fileName?: string,
        isDeleted?: 'true' | 'false',
        sortColumns?: any[],
        sortDirections?: any[],
        clientIds?: any[],
        remarks?: string,
        amountMin?: number,
        amountMax?: number,
    ): CancelablePromise<response_invoiceImages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invoice_images',
            query: {
                'page': page,
                'since': since,
                'until': until,
                'file_name': fileName,
                'is_deleted': isDeleted,
                'sort_columns[]': sortColumns,
                'sort_directions[]': sortDirections,
                'client_ids[]': clientIds,
                'remarks': remarks,
                'amount_min': amountMin,
                'amount_max': amountMax,
            },
        });
    }

    /**
     * 請求書ファイル情報削除API
     * 請求書ファイル情報を削除する。
     * @param ids
     * @returns void
     * @throws ApiError
     */
    public deleteInvoiceImages(
        ids: any[],
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/invoice_images',
            query: {
                'ids[]': ids,
            },
        });
    }

    /**
     * 請求書ファイル詳細情報取得API
     * 請求書ファイル詳細情報を取得する。
     * @param id
     * @returns response_invoiceImage Example response
     * @throws ApiError
     */
    public getInvoiceImagesId(
        id: number,
    ): CancelablePromise<response_invoiceImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/invoice_images/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 請求書ファイル詳細情報更新API
     * 請求書ファイル詳細情報を更新する。
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchInvoiceImagesId(
        id: number,
        requestBody?: request_invoiceImage,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/invoice_images/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
