/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type excSocialInsurance = {
    company_name: string;
    division: excSocialInsurance.division;
    institution: excSocialInsurance.institution | null;
    amount: number;
};

export namespace excSocialInsurance {

    export enum division {
        LIFE = 'life',
        PERSONAL_PENSION = 'personal_pension',
        NURSING = 'nursing',
        EARTHQUAKE = 'earthquake',
        LONG_TERM = 'long_term',
    }

    export enum institution {
        LATEST = 'latest',
        OLDEST = 'oldest',
    }


}

