/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { accountItem } from './accountItem';

export type reportCategory = {
    /**
     * 0: asset, 1: liabilities, 2: equity, 3: revenue, 4: expense
     */
    category_type?: reportCategory.category_type;
    account_items?: Array<accountItem>;
};

export namespace reportCategory {

    /**
     * 0: asset, 1: liabilities, 2: equity, 3: revenue, 4: expense
     */
    export enum category_type {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

