import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject
} from '@chakra-ui/theme-tools'
import { getColor, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    width: '100%',
    bg: 'white.100',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 1,
      bgColor: 'gray.50'
    }
  }
}

const size: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'md'
  },

  md: {
    fontSize: '13px',
    lineHeight: '13px',
    px: '8px',
    h: '44px',
    borderRadius: '2px'
  },

  sm: {
    fontSize: '13px',
    lineHeight: '13px',
    px: '8px',
    h: '30px',
    borderRadius: '2px'
  },

  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm'
  }
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    field: size.lg,
    addon: size.lg
  },
  md: {
    field: size.md,
    addon: size.md
  },
  sm: {
    field: size.sm,
    addon: size.sm
  },
  xs: {
    field: size.xs,
    addon: size.xs
  }
}

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props)
  }
}

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      border: '1px solid',
      borderColor: 'gray.200',
      bg: 'white.100',
      _hover: {
        borderColor: mode('gray.300', 'whiteAlpha.400')(props)
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all'
      },
      _disabled: {
        cursor: 'not-allowed',
        opacity: 1,
        color: 'gray.600',
        bgColor: 'gray.200'
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
        bg: 'alert.100'
      },
      _focus: {
        zIndex: 1,
        bgColor: 'purple.50',
        borderColor: 'primary.purple'
      },
      _placeholder: {
        color: 'gray.400'
      }
    },
    addon: {
      border: '1px solid',
      borderColor: mode('inherit', 'whiteAlpha.50')(props),
      bg: mode('gray.100', 'whiteAlpha.300')(props)
    }
  }
}

const variantFilled: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('gray.200', 'whiteAlpha.100')(props)
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all'
      },
      _disabled: {
        cursor: 'not-allowed'
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        bg: 'alert.100'
      },
      _focus: {
        bg: 'transparent',
        borderColor: getColor(theme, fc)
      }
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props)
    }
  }
}

const variantFlushed: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      borderBottom: '1px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all'
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, ec)}`
      },
      _focus: {
        borderColor: getColor(theme, fc),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, fc)}`
      }
    },
    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent'
    }
  }
}

const variantUnstyled: PartsStyleObject<typeof parts> = {
  field: {
    bg: 'transparent',
    px: 0,
    height: 'auto'
  },
  addon: {
    bg: 'transparent',
    px: 0,
    height: 'auto'
  }
}

const varantTaxReturnDetailEdit: PartsStyleObject<typeof parts> = {
  field: {
    bgColor: 'gray.70',
    height: '30px',
    border: '1px solid',
    borderColor: 'gray.200',
    _disabled: {
      bgColor: 'gray.200'
    }
  }
}

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
  taxReturnDetailEdit: varantTaxReturnDetailEdit
}

const defaultProps = {
  size: 'md',
  variant: 'outline'
}

export const InputTheme = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps
}
