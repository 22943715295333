import Router from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { ApiClient as NewApiClient, response_user_userType } from 'src/api'
import {
  COOKIE_IS_ADMIN_LOGIN_KEY,
  LOCAL_STORAGE_IS_LOGIN_KEY,
  LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY
} from 'src/constants'
import { useSWRConfig } from 'swr'
import ApiClient from '../clients/ApiClient'
import { parseCookie, setCookie } from '../common/CookieManager'
import { GlobalState } from '../interfaces/GlobalState'
import {
  ACTIVATE_PATH,
  DEMO_PATH,
  INVITATION_COMPLETED_PATH,
  INVITATION_WELCOME_PATH,
  JICOO_RESERVATION_COMPLETE_PATH,
  LOGIN_PATH,
  MARUNAGE_PATH,
  PAGE_PATH,
  PASSWORD_PATH,
  PAYMENTS_PATH,
  PRICING_AFTER_FREETRIAL_PATH,
  PRICING_PATH,
  RESET_PASSWORD_EDIT,
  RESET_PASSWORD_NEW,
  RESTART_PATH,
  ROOT_PATH,
  START_PATH,
  TAX_ACCOUNTANT_PATH,
  TUTORIAL_PATH,
  ULTRA_PATH
} from '../routes'
import GlobalContext from './GlobalContext'

const COOKIE_DOMAIN =
  process.env.NEXT_PUBLIC_ENV === 'local' ? '' : 'taxhero.jp'
const COOKIE_STATE_KEY =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? 'state'
    : `${process.env.NEXT_PUBLIC_ENV || 'local'}_state`

const INIT_STATE: GlobalState = {
  loginUser: {
    uid: undefined,
    name: undefined,
    accessToken: undefined,
    client: undefined,
    expiry: undefined
  }
}

const initState = (): GlobalState => {
  try {
    const cookie = parseCookie()
    const currentState = cookie[COOKIE_STATE_KEY]
      ? (JSON.parse(cookie[COOKIE_STATE_KEY]) as GlobalState)
      : INIT_STATE

    // TODO: 2023-03-18に削除
    // 影響範囲はdev, stgのみと考えられるが念の為
    // ドメイン指定なしのCookieを削除し、.taxhero.jpドメインとしてCookieを再登録
    setCookie(COOKIE_STATE_KEY, '', '', 0)
    setCookie(COOKIE_STATE_KEY, JSON.stringify(currentState), COOKIE_DOMAIN)

    return currentState
  } catch (err) {
    console.error(err)
    return INIT_STATE
  }
}

const { MARUNAGE, SEMI_MARUNAGE } = response_user_userType.user_type

const GlobalStateProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [state, setState] = useState<GlobalState>(initState)
  const { cache } = useSWRConfig()

  const clearState = (): void => {
    setState({ ...INIT_STATE })
  }

  const updateState = (value: GlobalState): void => {
    setState({ ...state, ...value })
  }

  const login = (): void => {
    sessionStorage.removeItem('uid')
    localStorage.setItem(LOCAL_STORAGE_IS_LOGIN_KEY, 'true')
  }

  const loginTaxAccountant = (): void => {
    localStorage.setItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY, 'true')
  }

  const logout = async (): Promise<void> => {
    const { uid, accessToken, client } = state.loginUser
    const apiClient = new ApiClient(uid, accessToken, client)
    const newApiClient = new NewApiClient({
      BASE: process.env.NEXT_PUBLIC_APP_API_URL,
      HEADERS: {
        'Content-Type': 'application/json',
        'token-type': 'Bearer',
        uid: uid || '',
        'access-token': accessToken || '',
        client: client || ''
      },
      WITH_CREDENTIALS: true
    })
    try {
      const isTaxAccountant =
        localStorage.getItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY) ===
        'true'
      let path = ''
      if (isTaxAccountant) {
        path = PAGE_PATH.tax_accountant.sign_in
      } else {
        const userType = await newApiClient.user.getUserUserType()
        if (
          userType.user_type === MARUNAGE ||
          userType.user_type === SEMI_MARUNAGE
        ) {
          path = PAGE_PATH.ultra.login
        } else {
          path = START_PATH
        }
        await apiClient.logout()
      }

      await clearState()
      localStorage.removeItem(LOCAL_STORAGE_IS_LOGIN_KEY)
      localStorage.removeItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY)
      setCookie(COOKIE_IS_ADMIN_LOGIN_KEY, '', '', 0)

      // すべてのCacheを削除
      // https://github.com/vercel/swr/issues/1887#issuecomment-1197358905
      const c = cache as any
      c.clear()

      await Router.push(path)
    } catch (err) {
      console.log(err)
    }
  }

  const isLogin = (): boolean => {
    return (
      (state.loginUser.accessToken &&
      state.loginUser.expiry &&
      Date.now() / 1000 < state.loginUser.expiry
        ? true
        : false) ||
      (typeof localStorage !== 'undefined' &&
        (localStorage.getItem(LOCAL_STORAGE_IS_LOGIN_KEY) === 'true' ||
          localStorage.getItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY) ===
            'true'))
    )
  }

  const global = {
    state,
    clearState,
    updateState,
    login,
    loginTaxAccountant,
    logout,
    isLogin
  }

  useEffect(() => {
    const checkLogin = async () => {
      // Mockモードの場合は認証をスキップ
      if (process.env.NEXT_PUBLIC_IS_MOCK) return

      const location = Router.pathname
      try {
        if (isLogin()) {
          if (location.startsWith(INVITATION_WELCOME_PATH)) {
            await Router.push(ROOT_PATH)
            return
          }

          const isTaxAccountant =
            localStorage.getItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY) ===
            'true'

          if (isTaxAccountant) {
            if (!location.startsWith(TAX_ACCOUNTANT_PATH)) {
              await Router.push(PAGE_PATH.tax_accountant.users.index)
            } else if (
              location === PAGE_PATH.tax_accountant.sign_in ||
              location === PAGE_PATH.tax_accountant.password ||
              location === PAGE_PATH.tax_accountant.reset_password.new ||
              location === PAGE_PATH.tax_accountant.reset_password.edit
            ) {
              await Router.push(PAGE_PATH.tax_accountant.users.index)
            }
          } else {
            /**
             * オンボの画面遷移
             * 1. Google認証の場合
             *   /start -> /demo -> /login/google -> /
             * 2. メアド認証の場合
             *   /start -> /demo -> /activate -> mail app -> /password -> /
             * 3. 招待経由のメアド認証の場合
             *   /invitation/welcome -> /activate
             */
            const { uid, accessToken, client } = state.loginUser
            const apiClient = new ApiClient(uid, accessToken, client)
            const { onboarding_status: onboardingStatus } =
              await apiClient.getUserOnboardingStatus()
            const newApiClient = new NewApiClient({
              BASE: process.env.NEXT_PUBLIC_APP_API_URL,
              HEADERS: {
                'Content-Type': 'application/json',
                'token-type': 'Bearer',
                uid: uid || '',
                'access-token': accessToken || '',
                client: client || ''
              },
              WITH_CREDENTIALS: true
            })
            const userType = await newApiClient.user.getUserUserType()
            // 以下、初回読み込み時にuser_type === MARUNAGEかどうかで遷移先を分岐
            if (
              userType.user_type === MARUNAGE ||
              userType.user_type === SEMI_MARUNAGE
            ) {
              // TODO: expiredの対応
              if (
                !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
                onboardingStatus === 'unconfirmed'
              ) {
                if (
                  location !== PAGE_PATH.ultra.activate &&
                  location !== PASSWORD_PATH
                ) {
                  await Router.push(PAGE_PATH.ultra.activate)
                }
              } else if (
                !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
                onboardingStatus === 'unpaid'
              ) {
                // OnboardingStatus が unpaid の場合、/pricing, /activate, /password 以外へはアクセスさせない
                // クレカ登録後でもWebhookが飛んでくるまでは未課金状態になってしまうので、/activate 以降はアクセスを許容する
                if (
                  location !== PAGE_PATH.ultra.pricing &&
                  location !== PAGE_PATH.ultra.activate &&
                  location !== PASSWORD_PATH
                ) {
                  await Router.push(PAGE_PATH.ultra.pricing)
                }
              } else {
                if (location === ROOT_PATH) {
                  // MARUNAGEユーザーは / にアクセスできない
                  await Router.push(PAGE_PATH.ultra.index)
                } else if (
                  location === START_PATH ||
                  location.startsWith(LOGIN_PATH) ||
                  location === DEMO_PATH ||
                  location === TUTORIAL_PATH ||
                  location.startsWith(PAYMENTS_PATH) ||
                  location === ACTIVATE_PATH ||
                  location === PASSWORD_PATH ||
                  location === RESTART_PATH ||
                  location === RESET_PASSWORD_NEW ||
                  location === RESET_PASSWORD_EDIT ||
                  location === JICOO_RESERVATION_COMPLETE_PATH ||
                  location === PAGE_PATH.ultra.sign_up ||
                  location === PAGE_PATH.ultra.login ||
                  location.startsWith(TAX_ACCOUNTANT_PATH)
                ) {
                  // OnboardingStatus が ok の場合、/start, /login/google, /login/email, /demo, /tutorial, /payments, /activate, /password 配下にはアクセスさせない
                  await Router.push(PAGE_PATH.ultra.index)
                }
              }
            } else {
              if (
                !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
                onboardingStatus === 'expired'
              ) {
                // OnboardingStatus が expired の場合、/restart, /pricing_after_freetrial, /login, /start, /password 以外へはアクセスさせない
                if (
                  location !== RESTART_PATH &&
                  location !== PRICING_AFTER_FREETRIAL_PATH &&
                  !location.startsWith(LOGIN_PATH) &&
                  location !== START_PATH &&
                  location !== PASSWORD_PATH
                ) {
                  await Router.push(RESTART_PATH)
                }
              } else if (
                !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
                onboardingStatus === 'unconfirmed'
              ) {
                // OnboardingStatus が unconfirmed の場合、/activate, /password 以外へはアクセスさせない
                if (location !== ACTIVATE_PATH && location !== PASSWORD_PATH) {
                  await Router.push(ACTIVATE_PATH)
                }
              } else if (
                !process.env.NEXT_PUBLIC_IGNORE_ONBOARDING_STATUS &&
                onboardingStatus === 'unpaid'
              ) {
                // OnboardingStatus が unpaid の場合、/tutorial, /pricing, /invitation/completed 以外へはアクセスさせない
                // クレカ登録後でもWebhookが飛んでくるまでは未課金状態になってしまうので、/activate 以降はアクセスを許容する
                if (
                  location !== TUTORIAL_PATH &&
                  location !== PRICING_PATH &&
                  location !== ACTIVATE_PATH &&
                  location !== PASSWORD_PATH &&
                  !location.startsWith(INVITATION_COMPLETED_PATH)
                ) {
                  await Router.push(PRICING_PATH)
                }
              } else {
                if (
                  location.startsWith(MARUNAGE_PATH) ||
                  location.startsWith(ULTRA_PATH)
                ) {
                  // 通常ユーザーはMARUNAGE以下アクセスできない
                  await Router.push(ROOT_PATH)
                } else if (
                  location === START_PATH ||
                  location.startsWith(LOGIN_PATH) ||
                  location === DEMO_PATH ||
                  location === TUTORIAL_PATH ||
                  location.startsWith(PAYMENTS_PATH) ||
                  location === ACTIVATE_PATH ||
                  location === PASSWORD_PATH ||
                  location === RESTART_PATH ||
                  location === RESET_PASSWORD_NEW ||
                  location === RESET_PASSWORD_EDIT ||
                  location === JICOO_RESERVATION_COMPLETE_PATH ||
                  location === PAGE_PATH.ultra.sign_up ||
                  location === PAGE_PATH.ultra.login ||
                  location.startsWith(TAX_ACCOUNTANT_PATH)
                ) {
                  // OnboardingStatus が ok の場合、/start, /login/google, /login/email, /demo, /tutorial, /payments, /activate, /password 配下にはアクセスさせない
                  await Router.push(ROOT_PATH)
                }
              }
            }
          }
        } else {
          // 未ログイン時に税理士の画面へアクセスした場合、税理士ログイン画面へダイレクトする
          if (
            location.startsWith(TAX_ACCOUNTANT_PATH) &&
            // パスワード再設定系は未ログインでも許容
            !location.startsWith(PAGE_PATH.tax_accountant.password) &&
            !location.startsWith(PAGE_PATH.tax_accountant.reset_password.new) &&
            !location.startsWith(PAGE_PATH.tax_accountant.reset_password.edit)
          ) {
            await Router.push(PAGE_PATH.tax_accountant.sign_in)
          } else if (
            // 未ログイン時に認証系以外へアクセスした場合、/start へダイレクトする
            !location.startsWith(INVITATION_WELCOME_PATH) &&
            !location.startsWith(LOGIN_PATH) &&
            !location.startsWith(PAGE_PATH.tax_accountant.sign_in) &&
            !location.startsWith(PAGE_PATH.tax_accountant.password) &&
            !location.startsWith(PAGE_PATH.tax_accountant.reset_password.new) &&
            !location.startsWith(
              PAGE_PATH.tax_accountant.reset_password.edit
            ) &&
            location !== START_PATH &&
            location !== PASSWORD_PATH &&
            location !== RESET_PASSWORD_NEW &&
            location !== RESET_PASSWORD_EDIT &&
            location !== JICOO_RESERVATION_COMPLETE_PATH &&
            location !== PAGE_PATH.ultra.sign_up &&
            location !== PAGE_PATH.ultra.login
          ) {
            await Router.push(`${START_PATH}?redirect=${location}`)
          }
        }
      } catch (err) {
        const isTaxAccountant =
          localStorage.getItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY) ===
          'true'
        if (err instanceof Error) {
          if (err.message.includes('401')) {
            localStorage.removeItem(LOCAL_STORAGE_IS_LOGIN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY)

            // Authorization Errorが発生した場合はブラウザのログイン情報に誤りがあるため、/start ヘ遷移させる
            await clearState()
          } else {
            console.error(err)
          }
        }
        if (isTaxAccountant) {
          await Router.push(PAGE_PATH.tax_accountant.sign_in)
        } else {
          await Router.push(`${START_PATH}?redirect=${location}`)
        }
      }
    }
    checkLogin()
  }, [])

  useEffect(() => {
    setCookie(COOKIE_STATE_KEY, JSON.stringify(state), COOKIE_DOMAIN)
  }, [state])

  return (
    <GlobalContext.Provider value={global}>{children}</GlobalContext.Provider>
  )
}

export default GlobalStateProvider
