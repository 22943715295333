/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type surveyBasicInformation = {
    is_last_tax_filing_submitted: boolean | null;
    number_of_tax_return_experiences: surveyBasicInformation.number_of_tax_return_experiences | null;
    e_tax_experience: surveyBasicInformation.e_tax_experience | null;
    last_tax_return_type: surveyBasicInformation.last_tax_return_type | null;
    opening_period: surveyBasicInformation.opening_period | null;
    opening_date: string | null;
    submit_opening_status: surveyBasicInformation.submit_opening_status | null;
    is_necessary_check_opening_date: boolean | null;
    own_office_status: surveyBasicInformation.own_office_status | null;
    tax_return_type: surveyBasicInformation.tax_return_type | null;
    blue_declaration_status: surveyBasicInformation.blue_declaration_status | null;
    is_necessary_check_blue: boolean | null;
    submit_blue_status: surveyBasicInformation.submit_blue_status | null;
    submission_method: surveyBasicInformation.submission_method | null;
    taxable_type: surveyBasicInformation.taxable_type | null;
    is_necessary_upload_last_blue_financial_statement: boolean | null;
    is_necessary_upload_second_last_tax_return: boolean | null;
    living_abroad_status: surveyBasicInformation.living_abroad_status | null;
    employee_status: surveyBasicInformation.employee_status | null;
    is_doctor_or_farmer: boolean | null;
    remarks: string | null;
};

export namespace surveyBasicInformation {

    export enum number_of_tax_return_experiences {
        ZERO = 'zero',
        ONCE = 'once',
        TWICE = 'twice',
        THREE_OR_MORE = 'three_or_more',
        OTHER_TIMES = 'other_times',
        UNKNOWN_TIMES = 'unknown_times',
    }

    export enum e_tax_experience {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum last_tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
        UNKNOWN = 'unknown',
    }

    export enum opening_period {
        BEFORE_LAST_YEAR = 'before_last_year',
        THIS_YEAR = 'this_year',
        OPENING_PERIOD_UNKNOWN = 'opening_period_unknown',
    }

    export enum submit_opening_status {
        YES = 'yes',
        NO = 'no',
        DONE = 'done',
        UNKNOWN = 'unknown',
    }

    export enum own_office_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
        TAX_RETURN_TYPE_UNKNOWN = 'tax_return_type_unknown',
    }

    export enum blue_declaration_status {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum submit_blue_status {
        YES = 'yes',
        NO = 'no',
        DONE = 'done',
        UNKNOWN = 'unknown',
    }

    export enum submission_method {
        E_TAX = 'e_tax',
        MAIL = 'mail',
        BRING = 'bring',
        SUBMISSION_METHOD_UNKNOWN = 'submission_method_unknown',
    }

    export enum taxable_type {
        EXEMPTION = 'exemption',
        SIMPLE = 'simple',
        PROPORTION = 'proportion',
        ITEM = 'item',
        FULL = 'full',
        TAXABLE_TYPE_UNKNOWN = 'taxable_type_unknown',
    }

    export enum living_abroad_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }

    export enum employee_status {
        YES = 'yes',
        NO = 'no',
        PLANNED = 'planned',
        UNKNOWN = 'unknown',
    }


}

