/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { response_accountItems } from '../models/response_accountItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountItemService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 勘定科目取得API
     * 勘定科目を取得する。
     * （頻出勘定科目および3ヶ月以内に使用された頻出以外の勘定科目を収支それぞれで3つ返す）
     * @param scope
     * @returns response_accountItems Example response
     * @throws ApiError
     */
    public getAccountItems(
        scope?: 'invoice' | 'fixed_asset' | 'opening_balance',
    ): CancelablePromise<response_accountItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/account_items',
            query: {
                'scope': scope,
            },
        });
    }

}
