/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_taxReturn_direct = {
    reference_number: string;
    continue_transfer: boolean;
    tax_accountant_name: string;
    tax_accountant_tel: string;
    tax_accountant_will_submit_article_30: boolean;
    tax_accountant_will_submit_article_33_2: boolean;
    general_transfer_income_revenue_amount_short: number;
    general_transfer_income_expense_amount_short: number;
    general_transfer_income_revenue_amount_long: number;
    general_transfer_income_expense_amount_long: number;
    special_situation: string;
    payment_method: request_taxReturn_direct.payment_method | null;
    non_taxable_income_number: number | null;
    non_taxable_income_amount: number;
    has_other_prefecture_office: boolean;
    family_id: number | null;
    family_exc_retirement_income_amount: number;
    enrolled_group_name: string;
    tax_accountant_address: string;
    earnings_and_expenses_statement_special_situation: string;
    financial_statement_special_situation: string;
};

export namespace request_taxReturn_direct {

    export enum payment_method {
        DEDUCTION = 'deduction',
        SELF = 'self',
    }


}

