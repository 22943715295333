/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type preview_refundAccount = {
    type: preview_refundAccount.type;
    receiving_method: preview_refundAccount.receiving_method | null;
    bank_name: string;
    /**
     * bank: 銀行、
     * vault: 金庫、
     * union: 組合、
     * agricultural: 農協、
     * fishing: 漁協
     * （ゆうちょの場合は null）
     */
    bank_kind: preview_refundAccount.bank_kind | null;
    branch_name: string;
    branch_number: string | null;
    /**
     * head: 本店、
     * branch: 支店、
     * sub_branch: 出張所、
     * main: 本所、
     * sub: 支所
     * （ゆうちょの場合は null）
     */
    branch_type: preview_refundAccount.branch_type | null;
    /**
     * savings: 普通、
     * checking: 当座、
     * tax_preparation: 納税準備、
     * notice: 通知、
     * separate: 別段、
     * deposit: 貯蓄、
     * other: その他
     * （ゆうちょの場合は null）
     */
    account_type: preview_refundAccount.account_type | null;
    account_number: string;
    post_office_name: string;
};

export namespace preview_refundAccount {

    export enum type {
        REFUND_EXCEPT_YUCHO_ACCOUNT = 'RefundExceptYuchoAccount',
        REFUND_YUCHO_ACCOUNT = 'RefundYuchoAccount',
    }

    export enum receiving_method {
        TRANSFER = 'transfer',
        TELLER = 'teller',
    }

    /**
     * bank: 銀行、
     * vault: 金庫、
     * union: 組合、
     * agricultural: 農協、
     * fishing: 漁協
     * （ゆうちょの場合は null）
     */
    export enum bank_kind {
        BANK = 'bank',
        VAULT = 'vault',
        UNION = 'union',
        AGRICULTURAL = 'agricultural',
        FISHING = 'fishing',
    }

    /**
     * head: 本店、
     * branch: 支店、
     * sub_branch: 出張所、
     * main: 本所、
     * sub: 支所
     * （ゆうちょの場合は null）
     */
    export enum branch_type {
        HEAD = 'head',
        BRANCH = 'branch',
        SUB_BRANCH = 'sub_branch',
        MAIN = 'main',
        SUB = 'sub',
    }

    /**
     * savings: 普通、
     * checking: 当座、
     * tax_preparation: 納税準備、
     * notice: 通知、
     * separate: 別段、
     * deposit: 貯蓄、
     * other: その他
     * （ゆうちょの場合は null）
     */
    export enum account_type {
        SAVINGS = 'savings',
        CHECKING = 'checking',
        TAX_PREPARATION = 'tax_preparation',
        NOTICE = 'notice',
        SEPARATE = 'separate',
        DEPOSIT = 'deposit',
        OTHER = 'other',
    }


}

