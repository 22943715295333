/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_ultra_tasks_taxableType = {
    taxable_type: request_ultra_tasks_taxableType.taxable_type;
    simple_taxable_business_division: request_ultra_tasks_taxableType.simple_taxable_business_division | null;
};

export namespace request_ultra_tasks_taxableType {

    export enum taxable_type {
        EXEMPTION = 'exemption',
        SIMPLE = 'simple',
        PROPORTION = 'proportion',
        ITEM = 'item',
        FULL = 'full',
    }

    export enum simple_taxable_business_division {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
    }


}

