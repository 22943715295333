/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type smallBusinessMutualAid = {
    item: smallBusinessMutualAid.item;
    amount: number;
};

export namespace smallBusinessMutualAid {

    export enum item {
        INDIVIDUAL_PENSION = 'individual_pension',
        SMBC = 'smbc',
        CORPORATE_PENSION = 'corporate_pension',
        DISABLED = 'disabled',
    }


}

