/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_startupExpense_progress = {
    progress: response_startupExpense_progress.progress | null;
};

export namespace response_startupExpense_progress {

    export enum progress {
        INCOMPLETED = 'incompleted',
        COMPLETED = 'completed',
    }


}

