/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_subscription = {
    has_record: boolean;
    is_freetrial: boolean | null;
    has_stripe_subscription: boolean | null;
    started_at: string | null;
    ended_at: string | null;
    contract_year: number | null;
    payment_status: response_subscription.payment_status | null;
};

export namespace response_subscription {

    export enum payment_status {
        UNPAID = 'unpaid',
        PAID = 'paid',
        FAIL = 'fail',
        PENDING = 'pending',
    }


}

