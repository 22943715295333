/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_basicInformation_taxableType } from '../models/request_basicInformation_taxableType';
import type { response_basicInformation_taxableType } from '../models/response_basicInformation_taxableType';
import type { response_preview_consumptionTax_appendix_page13 } from '../models/response_preview_consumptionTax_appendix_page13';
import type { response_preview_consumptionTax_appendix_page23 } from '../models/response_preview_consumptionTax_appendix_page23';
import type { response_preview_consumptionTax_appendix_page43 } from '../models/response_preview_consumptionTax_appendix_page43';
import type { response_preview_consumptionTax_appendix_page53 } from '../models/response_preview_consumptionTax_appendix_page53';
import type { response_preview_consumptionTax_appendix_page6 } from '../models/response_preview_consumptionTax_appendix_page6';
import type { response_preview_consumptionTax_general_page1 } from '../models/response_preview_consumptionTax_general_page1';
import type { response_preview_consumptionTax_page2 } from '../models/response_preview_consumptionTax_page2';
import type { response_preview_consumptionTax_simple_page1 } from '../models/response_preview_consumptionTax_simple_page1';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConsumptionTaxablePlayerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 課税事業者種別変更API
     * ユーザーが設定している作業年の課税事業者種別を変更する。
     * @param requestBody
     * @returns response_basicInformation_taxableType Example response
     * @throws ApiError
     */
    public patchBasicInformationTaxableType(
        requestBody?: request_basicInformation_taxableType,
    ): CancelablePromise<response_basicInformation_taxableType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/taxable_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 免税・課税事業者設定情報取得API
     * 免税・課税事業者設定情報を取得する。
     * @returns response_basicInformation_taxableType Example response
     * @throws ApiError
     */
    public getBasicInformationTaxableType(): CancelablePromise<response_basicInformation_taxableType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_information/taxable_type',
        });
    }

    /**
     * 消費税の確定申告書第一表（一般用）プレビューAPI
     * 消費税の確定申告書第一表（一般用）プレビュー情報を取得する。
     * @returns response_preview_consumptionTax_general_page1 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxGeneralPage1(): CancelablePromise<response_preview_consumptionTax_general_page1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/general/page_1',
        });
    }

    /**
     * 消費税の確定申告書第一表（簡易課税用）プレビューAPI
     * 消費税の確定申告書第一表（簡易課税用）プレビュー情報を取得する。
     * @returns response_preview_consumptionTax_simple_page1 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxSimplePage1(): CancelablePromise<response_preview_consumptionTax_simple_page1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/simple/page_1',
        });
    }

    /**
     * 消費税の確定申告書第二表プレビューAPI
     * 消費税の確定申告書第二表プレビュー情報を取得する。
     * @returns response_preview_consumptionTax_page2 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxPage2(): CancelablePromise<response_preview_consumptionTax_page2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/page_2',
        });
    }

    /**
     * 消費税の確定申告書付表1-3プレビューAPI
     * 消費税の確定申告書付表1-3のプレビューを取得する。
     * @returns response_preview_consumptionTax_appendix_page13 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxAppendixPage13(): CancelablePromise<response_preview_consumptionTax_appendix_page13> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/appendix/page_1_3',
        });
    }

    /**
     * 消費税の確定申告書付表2-3プレビューAPI
     * 消費税の確定申告書付表2-3のプレビューを取得する。
     * @returns response_preview_consumptionTax_appendix_page23 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxAppendixPage23(): CancelablePromise<response_preview_consumptionTax_appendix_page23> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/appendix/page_2_3',
        });
    }

    /**
     * 消費税の確定申告書付表4-3プレビューAPI
     * 消費税の確定申告書付表4-3のプレビューを取得する。
     * @returns response_preview_consumptionTax_appendix_page43 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxAppendixPage43(): CancelablePromise<response_preview_consumptionTax_appendix_page43> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/appendix/page_4_3',
        });
    }

    /**
     * 消費税の確定申告書付表5-3プレビューAPI
     * 消費税の確定申告書付表5-3のプレビューを取得する。
     * @returns response_preview_consumptionTax_appendix_page53 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxAppendixPage53(): CancelablePromise<response_preview_consumptionTax_appendix_page53> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/appendix/page_5_3',
        });
    }

    /**
     * 消費税の確定申告書付表6プレビューAPI
     * 消費税の確定申告書付表6のプレビュを返す。
     * @returns response_preview_consumptionTax_appendix_page6 Example response
     * @throws ApiError
     */
    public getPreviewConsumptionTaxAppendixPage6(): CancelablePromise<response_preview_consumptionTax_appendix_page6> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/consumption_tax/appendix/page_6',
        });
    }

}
