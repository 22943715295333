/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_preview_consumptionTax_page2 = {
    is_necessary: boolean;
    page_2: {
        address: string;
        tel: string;
        business_name_kana: string;
        business_name: string;
        name_kana: string;
        name: string;
        tax_start_era_code: response_preview_consumptionTax_page2.tax_start_era_code;
        tax_start_era_year: number;
        tax_start_month: number;
        tax_start_date: number;
        tax_end_era_year: number;
        tax_end_month: number;
        tax_end_date: number;
        declaration_type: string;
        sales_tax_base_amount: number;
        taxable_assets_transfer_detail: {
            rate_3_amount: number;
            rate_4_amount: number;
            rate_6_3_amount: number;
            rate_6_24_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        specific_taxable_purchase_detail: {
            rate_6_3_amount: number;
            rate_7_8_amount: number;
            total_amount: number;
        };
        sales_tax_amount: number;
        sales_tax_detail: {
            rate_3_amount: number;
            rate_4_amount: number;
            rate_6_3_amount: number;
            rate_6_24_amount: number;
            rate_7_8_amount: number;
        };
        sales_return_tax_amount: number;
        sales_return_tax_detail: {
            sales_return_tax_amount: number;
            specific_taxable_purchase_amount: number;
        };
        local_tax_detail: {
            total_amount: number;
            rate_4_amount: number;
            rate_6_3_amount: number;
            rate_6_24_and_7_8_amount: number;
        };
    } | null;
};

export namespace response_preview_consumptionTax_page2 {

    export enum tax_start_era_code {
        R = 'R',
    }


}

