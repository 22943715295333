import { FormLabel } from '@chakra-ui/react'
import type { SystemStyleObject } from '@chakra-ui/theme-tools'
import { RequiredIndicator } from 'src/components/common/RequiredIndicator'

const baseStyle: SystemStyleObject = {
  fontSize: '13px',
  lineHeight: '18px',
  h: '18px',
  mb: '8px',
  marginInlineEnd: 'auto',
  fontWeight: '700',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  opacity: 1,
  position: 'relative',
  _disabled: {
    opacity: 0.4
  }
}

FormLabel.defaultProps = {
  ...FormLabel.defaultProps,
  requiredIndicator: <RequiredIndicator />
}

export const FormLabelTheme = {
  baseStyle
}
