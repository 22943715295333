/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { carriedForwardJob } from '../models/carriedForwardJob';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CarriedForwardJobService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 実行中次年度繰越処理取得API
     * 実行中の次年度繰越処理を取得する。
     * @returns carriedForwardJob Example response
     * @throws ApiError
     */
    public getCarriedForwardJobsActive(): CancelablePromise<carriedForwardJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/carried_forward_jobs/active',
        });
    }

}
