/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type uploadRequiredAccount = {
    id: number;
    has_account: boolean;
    account_type: uploadRequiredAccount.account_type;
    name: string;
    brand: uploadRequiredAccount.brand | null;
    is_login_ready: boolean;
    min_issued_date: string;
};

export namespace uploadRequiredAccount {

    export enum account_type {
        BANK = 'bank',
        CARD = 'card',
    }

    export enum brand {
        VISA = 'visa',
        MASTER = 'master',
        AMEX = 'amex',
        DINERS = 'diners',
        UNION = 'union',
        DISCOVER = 'discover',
        OTHER = 'other',
    }


}

