/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_atTransactions_patch = {
    at_transaction_ids: Array<number>;
    account_item_id: number;
    recognized_at: string | null;
    divide_private_rate: number | null;
    withholding_amount: number | null;
    invoice_id: number | null;
    extension_account_item_id: number | null;
    sales_status?: request_atTransactions_patch.sales_status;
    sales_diff_journal_details?: Array<{
        account_item_id?: number;
        account_sub_item_id?: number;
        amount?: number;
    }>;
};

export namespace request_atTransactions_patch {

    export enum sales_status {
        SALES_IS_GREATER_THAN_DEPOSIT = 'sales_is_greater_than_deposit',
        SALES_IS_LESS_THAN_DEPOSIT = 'sales_is_less_than_deposit',
    }


}

