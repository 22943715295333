/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_startupExpense_progress = {
    progress: request_startupExpense_progress.progress;
};

export namespace request_startupExpense_progress {

    export enum progress {
        INCOMPLETED = 'incompleted',
        COMPLETED = 'completed',
    }


}

