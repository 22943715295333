/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_task = {
    id?: number | null;
    name: string;
    code: string;
    category: response_task.category;
    task_type: response_task.task_type;
    url_type: response_task.url_type;
    url: string;
    expiration_date?: string | null;
    order_no: number;
    is_archived: boolean;
};

export namespace response_task {

    export enum category {
        JOURNAL = 'journal',
        INVOICE = 'invoice',
        DOCUMENT = 'document',
        TAX = 'tax',
    }

    export enum task_type {
        REQUIRED = 'required',
        RECOMMENDED = 'recommended',
    }

    export enum url_type {
        INTERNAL = 'internal',
        EXTERNAL = 'external',
    }


}

