/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_ultra_tasks_openingDate = {
    submit_opening_status: response_ultra_tasks_openingDate.submit_opening_status | null;
    opening_date: string | null;
};

export namespace response_ultra_tasks_openingDate {

    export enum submit_opening_status {
        YES = 'yes',
        NO = 'no',
        DONE = 'done',
        UNKNOWN = 'unknown',
    }


}

