/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type historicalBalanceSheetDetails_difference = {
    category_type: historicalBalanceSheetDetails_difference.category_type;
    account_item_id: number;
    account_item_name: string;
    balance_sheet_amount: number;
    fixed_asset_amount: number;
};

export namespace historicalBalanceSheetDetails_difference {

    export enum category_type {
        ASSET = 'asset',
        LIABILITIES = 'liabilities',
        EQUITY = 'equity',
        REVENUE = 'revenue',
    }


}

