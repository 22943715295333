/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_historicalBalanceSheetDetail = {
    account_item_id: number;
    account_item_name: string;
    account_sub_item_id: number | null;
    account_sub_item_name: string | null;
    amount: number;
    is_saving_account: boolean;
    order_no: number;
    category_type: response_historicalBalanceSheetDetail.category_type;
};

export namespace response_historicalBalanceSheetDetail {

    export enum category_type {
        ASSET = 'asset',
        LIABILITIES = 'liabilities',
        EQUITY = 'equity',
    }


}

