/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { houseLoanDeduction } from '../models/houseLoanDeduction';
import type { refundAccount_get } from '../models/refundAccount_get';
import type { refundAccount_patch } from '../models/refundAccount_patch';
import type { request_basicInformation_eTaxUserId } from '../models/request_basicInformation_eTaxUserId';
import type { request_basicInformation_submitDate } from '../models/request_basicInformation_submitDate';
import type { request_businessIncomeDetails } from '../models/request_businessIncomeDetails';
import type { request_carriedForwardLosses } from '../models/request_carriedForwardLosses';
import type { request_counselorFees_patch } from '../models/request_counselorFees_patch';
import type { request_donations_patch } from '../models/request_donations_patch';
import type { request_employeeSalaries } from '../models/request_employeeSalaries';
import type { request_estimatedTaxPrepayment_patch } from '../models/request_estimatedTaxPrepayment_patch';
import type { request_excSocialInsurances_patch } from '../models/request_excSocialInsurances_patch';
import type { request_export_consumptionTax_xtx } from '../models/request_export_consumptionTax_xtx';
import type { request_export_xtx } from '../models/request_export_xtx';
import type { request_families_patch } from '../models/request_families_patch';
import type { request_medicalExpenses } from '../models/request_medicalExpenses';
import type { request_miscellaneousIncomes } from '../models/request_miscellaneousIncomes';
import type { request_officeRentExpenses } from '../models/request_officeRentExpenses';
import type { request_retirementIncome_patch } from '../models/request_retirementIncome_patch';
import type { request_salaries } from '../models/request_salaries';
import type { request_smallBusinessMutualAids_patch } from '../models/request_smallBusinessMutualAids_patch';
import type { request_socialInsurances } from '../models/request_socialInsurances';
import type { request_stockIncomes } from '../models/request_stockIncomes';
import type { request_taxReturn_direct } from '../models/request_taxReturn_direct';
import type { request_taxReturnQuestions } from '../models/request_taxReturnQuestions';
import type { response_basicInformation_eTaxUserId_get } from '../models/response_basicInformation_eTaxUserId_get';
import type { response_basicInformation_eTaxUserId_patch } from '../models/response_basicInformation_eTaxUserId_patch';
import type { response_basicInformation_submitDate_get } from '../models/response_basicInformation_submitDate_get';
import type { response_basicInformation_submitDate_patch } from '../models/response_basicInformation_submitDate_patch';
import type { response_businessIncomeDetails } from '../models/response_businessIncomeDetails';
import type { response_carriedForwardLosses } from '../models/response_carriedForwardLosses';
import type { response_counselorFees } from '../models/response_counselorFees';
import type { response_donations } from '../models/response_donations';
import type { response_employeeSalaries } from '../models/response_employeeSalaries';
import type { response_estimatedTaxPrepayment_get } from '../models/response_estimatedTaxPrepayment_get';
import type { response_estimatedTaxPrepayment_patch } from '../models/response_estimatedTaxPrepayment_patch';
import type { response_excSocialInsurances } from '../models/response_excSocialInsurances';
import type { response_families_get } from '../models/response_families_get';
import type { response_families_patch } from '../models/response_families_patch';
import type { response_industries } from '../models/response_industries';
import type { response_medicalExpenses } from '../models/response_medicalExpenses';
import type { response_miscellaneousIncomes } from '../models/response_miscellaneousIncomes';
import type { response_officeRentExpense } from '../models/response_officeRentExpense';
import type { response_officeRentExpenses } from '../models/response_officeRentExpenses';
import type { response_preview_donation_npo } from '../models/response_preview_donation_npo';
import type { response_preview_earningsAndExpensesStatement } from '../models/response_preview_earningsAndExpensesStatement';
import type { response_preview_financialStatement } from '../models/response_preview_financialStatement';
import type { response_preview_houseLoanDeduction } from '../models/response_preview_houseLoanDeduction';
import type { response_preview_houseLoanDeduction_appendix_jointDebt } from '../models/response_preview_houseLoanDeduction_appendix_jointDebt';
import type { response_preview_incomeDetail } from '../models/response_preview_incomeDetail';
import type { response_preview_listedStockCarryForwardLossStatement } from '../models/response_preview_listedStockCarryForwardLossStatement';
import type { response_preview_medicalExpense } from '../models/response_preview_medicalExpense';
import type { response_preview_stockIncomeStatement } from '../models/response_preview_stockIncomeStatement';
import type { response_preview_taxReturn1 } from '../models/response_preview_taxReturn1';
import type { response_preview_taxReturn2 } from '../models/response_preview_taxReturn2';
import type { response_preview_taxReturn3 } from '../models/response_preview_taxReturn3';
import type { response_preview_taxReturn4 } from '../models/response_preview_taxReturn4';
import type { response_retirementIncome_get } from '../models/response_retirementIncome_get';
import type { response_retirementIncome_patch } from '../models/response_retirementIncome_patch';
import type { response_salaries } from '../models/response_salaries';
import type { response_smallBusinessMutualAids } from '../models/response_smallBusinessMutualAids';
import type { response_socialInsurances } from '../models/response_socialInsurances';
import type { response_stockIncomes } from '../models/response_stockIncomes';
import type { response_taxReturn_attachedDocuments } from '../models/response_taxReturn_attachedDocuments';
import type { response_taxReturn_direct } from '../models/response_taxReturn_direct';
import type { response_taxReturn_summaryAmount } from '../models/response_taxReturn_summaryAmount';
import type { response_taxReturnQuestions } from '../models/response_taxReturnQuestions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TaxReturnService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * xtxファイルダウンロードAPI
     * 会計年度のxtxファイルをダウンロードする。
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public getExportXtx(
        requestBody?: request_export_xtx,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/export/xtx',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 消費税の確定申告書xtxファイルダウンロードAPI
     * 会計年度の消費税の確定申告書のxtxファイルをダウンロードする。
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public getExportConsumptionTaxXtx(
        requestBody?: request_export_consumptionTax_xtx,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/export/consumption_tax/xtx',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 業種取得API
     * 業種を取得する。
     * @returns response_industries Example response
     * @throws ApiError
     */
    public getIndustries(): CancelablePromise<response_industries> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/industries',
        });
    }

    /**
     * 確定申告書提出日更新API
     * 確定申告書提出日を更新する。
     * @param requestBody
     * @returns response_basicInformation_submitDate_patch Example response
     * @throws ApiError
     */
    public patchBasicInformationSubmitDate(
        requestBody?: request_basicInformation_submitDate,
    ): CancelablePromise<response_basicInformation_submitDate_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/submit_date',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 確定申告書提出日取得API
     * 確定申告書提出日を取得する。
     * @returns response_basicInformation_submitDate_get Example response
     * @throws ApiError
     */
    public getBasicInformationSubmitDate(): CancelablePromise<response_basicInformation_submitDate_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_information/submit_date',
        });
    }

    /**
     * 利用者識別番号更新API
     * 利用者識別番号を更新する。
     * @param requestBody
     * @returns response_basicInformation_eTaxUserId_patch Example response
     * @throws ApiError
     */
    public patchBasicInformationETaxUserId(
        requestBody?: request_basicInformation_eTaxUserId,
    ): CancelablePromise<response_basicInformation_eTaxUserId_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/basic_information/e_tax_user_id',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 利用者識別番号取得API
     * 利用者識別番号を取得する。
     * @returns response_basicInformation_eTaxUserId_get Example response
     * @throws ApiError
     */
    public getBasicInformationETaxUserId(): CancelablePromise<response_basicInformation_eTaxUserId_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/basic_information/e_tax_user_id',
        });
    }

    /**
     * 扶養家族情報更新API
     * 扶養家族の情報を更新する。
     * @param requestBody
     * @returns response_families_patch Example response
     * @throws ApiError
     */
    public patchFamilies(
        requestBody?: request_families_patch,
    ): CancelablePromise<response_families_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/families',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 扶養家族情報取得API
     * 扶養家族情報を取得する。
     * @returns response_families_get Example response
     * @throws ApiError
     */
    public getFamilies(): CancelablePromise<response_families_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/families',
        });
    }

    /**
     * 勤務先からの給与更新API
     * 会計年度の勤務先からの給与を更新する
     * @param requestBody
     * @returns response_salaries Example response
     * @throws ApiError
     */
    public patchBasicInformationSalaries(
        requestBody?: request_salaries,
    ): CancelablePromise<response_salaries> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/salaries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 勤務先からの給与取得API
     * 会計年度の勤務先からの給与を取得する
     * @returns response_salaries Example response
     * @throws ApiError
     */
    public getBasicInformationSalaries(): CancelablePromise<response_salaries> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/salaries',
        });
    }

    /**
     * 退職所得更新API
     * 会計年度の退職所得を更新する
     * @param requestBody
     * @returns response_retirementIncome_patch Example response
     * @throws ApiError
     */
    public patchBasicInformationSeverancePays(
        requestBody?: request_retirementIncome_patch,
    ): CancelablePromise<response_retirementIncome_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/retirement_income',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 退職所得取得API
     * 会計年度の退職所得を取得する
     * @returns response_retirementIncome_get Example response
     * @throws ApiError
     */
    public getBasicInformationSeverancePays(): CancelablePromise<response_retirementIncome_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/retirement_income',
        });
    }

    /**
     * 予定納税額更新API
     * 予定納税額を更新する。
     * @param requestBody
     * @returns response_estimatedTaxPrepayment_patch Example response
     * @throws ApiError
     */
    public patchEstimatedTaxPrepayment(
        requestBody?: request_estimatedTaxPrepayment_patch,
    ): CancelablePromise<response_estimatedTaxPrepayment_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/estimated_tax_prepayment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 予定納税額取得API
     * 予定納税額を取得する。
     * @returns response_estimatedTaxPrepayment_get Example response
     * @throws ApiError
     */
    public getEstimatedTaxPrepayment(): CancelablePromise<response_estimatedTaxPrepayment_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/estimated_tax_prepayment',
        });
    }

    /**
     * 寄附金控除更新API
     * 寄附金控除を更新する。
     * @param requestBody
     * @returns response_donations Example response
     * @throws ApiError
     */
    public patchDonations(
        requestBody?: request_donations_patch,
    ): CancelablePromise<response_donations> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/donations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 寄附金控除取得API
     * 寄附金控除を取得する。
     * @returns response_donations Example response
     * @throws ApiError
     */
    public getDonations(): CancelablePromise<response_donations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/donations',
        });
    }

    /**
     * 小規模企業共済の掛金更新API
     * 小規模企業共済の掛金を更新する。
     * @param requestBody
     * @returns response_smallBusinessMutualAids Example response
     * @throws ApiError
     */
    public patchSmallBusinessMutualAids(
        requestBody?: request_smallBusinessMutualAids_patch,
    ): CancelablePromise<response_smallBusinessMutualAids> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/small_business_mutual_aids',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 小規模企業共済の掛金取得API
     * 小規模企業共済の掛金を取得する。
     * @returns response_smallBusinessMutualAids Example response
     * @throws ApiError
     */
    public getSmallBusinessMutualAids(): CancelablePromise<response_smallBusinessMutualAids> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/small_business_mutual_aids',
        });
    }

    /**
     * 弁護士・税理士への報酬情報更新API
     * 弁護士・税理士への報酬情報を更新する。
     * @param requestBody
     * @returns response_counselorFees Example response
     * @throws ApiError
     */
    public patchCounselorFees(
        requestBody?: request_counselorFees_patch,
    ): CancelablePromise<response_counselorFees> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/counselor_fees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 弁護士・税理士への報酬情報取得API
     * 弁護士・税理士への報酬情報を取得する。
     * @returns response_counselorFees Example response
     * @throws ApiError
     */
    public getCounselorFees(): CancelablePromise<response_counselorFees> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/counselor_fees',
        });
    }

    /**
     * 生命保険・地震保険更新API
     * 生命保険・地震保険を更新する。
     * @param requestBody
     * @returns response_excSocialInsurances Example response
     * @throws ApiError
     */
    public patchExcSocialInsurances(
        requestBody?: request_excSocialInsurances_patch,
    ): CancelablePromise<response_excSocialInsurances> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/exc_social_insurances',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 生命保険・地震保険取得API
     * 生命保険・地震保険を取得する。
     * @returns response_excSocialInsurances Example response
     * @throws ApiError
     */
    public getExcSocialInsurances(): CancelablePromise<response_excSocialInsurances> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/exc_social_insurances',
        });
    }

    /**
     * 雑所得取得API
     * 会計年度の雑所得を取得する。
     * @returns response_miscellaneousIncomes Example response
     * @throws ApiError
     */
    public getMiscellaneousIncomes(): CancelablePromise<response_miscellaneousIncomes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/miscellaneous_incomes',
        });
    }

    /**
     * 雑所得更新API
     * 会計年度の雑所得を更新する。
     * @param requestBody
     * @returns response_miscellaneousIncomes Example response
     * @throws ApiError
     */
    public patchMiscellaneousIncomes(
        requestBody?: request_miscellaneousIncomes,
    ): CancelablePromise<response_miscellaneousIncomes> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/miscellaneous_incomes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 事業所得取得API
     * 会計年度の事業所得を取得する。
     * @returns response_businessIncomeDetails Example response
     * @throws ApiError
     */
    public getBusinessIncomeDetails(): CancelablePromise<response_businessIncomeDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/business_income_details',
        });
    }

    /**
     * 事業所得更新API
     * 会計年度の事業所得を更新する。
     * @param requestBody
     * @returns response_businessIncomeDetails Example response
     * @throws ApiError
     */
    public patchBusinessIncomeDetails(
        requestBody?: request_businessIncomeDetails,
    ): CancelablePromise<response_businessIncomeDetails> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/business_income_details',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 社会保険料控除取得API
     * 会計年度の社会保険料控除を取得する。
     * @returns response_socialInsurances Example response
     * @throws ApiError
     */
    public getSocialInsurances(): CancelablePromise<response_socialInsurances> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/social_insurances',
        });
    }

    /**
     * 社会保険料控除更新API
     * 会計年度の社会保険料控除を更新する。
     * @param requestBody
     * @returns response_socialInsurances Example response
     * @throws ApiError
     */
    public patchSocialInsurances(
        requestBody?: request_socialInsurances,
    ): CancelablePromise<response_socialInsurances> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/social_insurances',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 医療費控除取得API
     * 会計年度の医療費控除を取得する。
     * @returns response_medicalExpenses Example response
     * @throws ApiError
     */
    public getMedicalExpenses(): CancelablePromise<response_medicalExpenses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/medical_expenses',
        });
    }

    /**
     * 医療費控除更新API
     * 会計年度の医療費控除を更新する。
     * @param requestBody
     * @returns response_medicalExpenses Example response
     * @throws ApiError
     */
    public patchMedicalExpenses(
        requestBody?: request_medicalExpenses,
    ): CancelablePromise<response_medicalExpenses> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/medical_expenses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 確定申告に関する質問回答状況取得API
     * 確定申告に関する質問回答状況を取得する。
     * @returns response_taxReturnQuestions Example response
     * @throws ApiError
     */
    public getTaxReturnQuestions(): CancelablePromise<response_taxReturnQuestions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tax_return_questions',
        });
    }

    /**
     * 確定申告に関する質問回答状況更新API
     * 確定申告に関する質問回答状況を更新する。
     * @param requestBody
     * @returns response_taxReturnQuestions Example response
     * @throws ApiError
     */
    public patchTaxReturnQuestions(
        requestBody?: request_taxReturnQuestions,
    ): CancelablePromise<response_taxReturnQuestions> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tax_return_questions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 確定申告サマリ金額取得API
     * 確定申告サマリ金額を取得する。
     * @returns response_taxReturn_summaryAmount Example response
     * @throws ApiError
     */
    public getTaxReturnSummaryAmount(): CancelablePromise<response_taxReturn_summaryAmount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tax_return/summary_amount',
        });
    }

    /**
     * 還付先口座取得API
     * 還付先口座を取得する。
     * @returns refundAccount_get Example response
     * @throws ApiError
     */
    public getRefundAccount(): CancelablePromise<refundAccount_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/refund_account',
        });
    }

    /**
     * 還付先口座更新API
     * 還付先口座を更新する。
     * @param requestBody
     * @returns refundAccount_patch Example response
     * @throws ApiError
     */
    public patchRefundAccount(
        requestBody?: refundAccount_patch,
    ): CancelablePromise<refundAccount_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/refund_account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 地代家賃作成API
     * 地代家賃を作成する。
     * @param requestBody
     * @returns response_officeRentExpense Example response
     * @throws ApiError
     */
    public postOfficeRentExpenses(
        requestBody?: request_officeRentExpenses,
    ): CancelablePromise<response_officeRentExpense> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/office_rent_expenses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 地代家賃取得API
     * 地代家賃を取得する。
     * @returns response_officeRentExpenses Example response
     * @throws ApiError
     */
    public getOfficeRentExpenses(): CancelablePromise<response_officeRentExpenses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/office_rent_expenses',
        });
    }

    /**
     * 地代家賃更新API
     * 地代家賃を更新する。
     * @param officeRentExpenseId
     * @param requestBody
     * @returns response_officeRentExpense Example response
     * @throws ApiError
     */
    public patchOfficeRentExpenses(
        officeRentExpenseId: number,
        requestBody?: request_officeRentExpenses,
    ): CancelablePromise<response_officeRentExpense> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/office_rent_expenses/{office_rent_expense_id}',
            path: {
                'office_rent_expense_id': officeRentExpenseId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 地代家賃削除API
     * 地代家賃を削除する。
     * @param officeRentExpenseId
     * @returns void
     * @throws ApiError
     */
    public deleteOfficeRentExpenses(
        officeRentExpenseId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/office_rent_expenses/{office_rent_expense_id}',
            path: {
                'office_rent_expense_id': officeRentExpenseId,
            },
        });
    }

    /**
     * 確定申告書第一表プレビューAPI
     * 確定申告書第一表のプレビューに必要な値を取得する
     * @returns response_preview_taxReturn1 Example response
     * @throws ApiError
     */
    public getPreviewTaxReturn1(): CancelablePromise<response_preview_taxReturn1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/tax_return1',
        });
    }

    /**
     * 確定申告書第二表プレビューAPI
     * 確定申告書第二表のプレビューに必要な値を取得する
     * @returns response_preview_taxReturn2 Example response
     * @throws ApiError
     */
    public getPreviewTaxReturn2(): CancelablePromise<response_preview_taxReturn2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/tax_return2',
        });
    }

    /**
     * 確定申告書第三表プレビューAPI
     * 確定申告書第三表のプレビューに必要な値を取得する
     * @returns response_preview_taxReturn3 Example response
     * @throws ApiError
     */
    public getPreviewTaxReturn3(): CancelablePromise<response_preview_taxReturn3> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/tax_return3',
        });
    }

    /**
     * 収支内訳書プレビューAPI
     * 収支内訳書のプレビューに必要な値を取得する
     * @returns response_preview_earningsAndExpensesStatement Example response
     * @throws ApiError
     */
    public getPreviewEarningsAndExpensesStatement(): CancelablePromise<response_preview_earningsAndExpensesStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/earnings_and_expenses_statement',
        });
    }

    /**
     * 青色申告決算書プレビューAPI
     * 青色申告決算書のプレビューに必要な値を取得する
     * @returns response_preview_financialStatement Example response
     * @throws ApiError
     */
    public getPreviewFinancialStatement(): CancelablePromise<response_preview_financialStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/financial_statement',
        });
    }

    /**
     * 確定申告アンケ 直接入力項目取得API
     * 確定申告アンケの直接入力の項目を取得する
     * @returns response_taxReturn_direct Example response
     * @throws ApiError
     */
    public getTaxReturnDirect(): CancelablePromise<response_taxReturn_direct> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tax_return/direct',
        });
    }

    /**
     * 確定申告アンケ 直接入力項目更新API
     * 確定申告アンケの直接入力の項目を更新する
     * @param requestBody
     * @returns response_taxReturn_direct Example response
     * @throws ApiError
     */
    public patchTaxReturnDirect(
        requestBody?: request_taxReturn_direct,
    ): CancelablePromise<response_taxReturn_direct> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tax_return/direct',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 確定申告添付書類リスト取得API
     * 確定申告添付書類リストを取得する。
     * @returns response_taxReturn_attachedDocuments Example response
     * @throws ApiError
     */
    public getTaxReturnAttachedDocuments(): CancelablePromise<response_taxReturn_attachedDocuments> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tax_return/attached_documents',
        });
    }

    /**
     * 医療費控除の明細書プレビューAPI
     * 医療費控除の明細書に必要な値を取得する
     * @returns response_preview_medicalExpense Example response
     * @throws ApiError
     */
    public getPreviewMedicalExpense(): CancelablePromise<response_preview_medicalExpense> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/medical_expense',
        });
    }

    /**
     * 所得の内訳書プレビューAPI
     * 所得の内訳書に必要な値を取得する
     * @returns response_preview_incomeDetail Example response
     * @throws ApiError
     */
    public getPreviewIncomeDetail(): CancelablePromise<response_preview_incomeDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/income_detail',
        });
    }

    /**
     * 株式等に係る譲渡所得等の金額の計算明細書プレビューAPI
     * 株式等に係る譲渡所得等の金額の計算明細書の情報を返す。
     * @returns response_preview_stockIncomeStatement Example response
     * @throws ApiError
     */
    public getPreviewStockIncomeStatement(): CancelablePromise<response_preview_stockIncomeStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/stock_income_statement',
        });
    }

    /**
     * 上場株式等に係る譲渡損失の損益通算及び繰越控除用の付表プレビューAPI
     * 上場株式等に係る譲渡損失の損益通算及び繰越控除用の付表の情報を返す。
     * @returns response_preview_listedStockCarryForwardLossStatement Example response
     * @throws ApiError
     */
    public getPreviewListedStockCarryForwardLossStatement(): CancelablePromise<response_preview_listedStockCarryForwardLossStatement> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/listed_stock_carry_forward_loss_statement',
        });
    }

    /**
     * 株式の譲渡・配当所得一覧取得API
     * 株式の譲渡・配当所得一覧を取得する。
     * @returns response_stockIncomes Example response
     * @throws ApiError
     */
    public getStockIncomes(): CancelablePromise<response_stockIncomes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stock_incomes',
        });
    }

    /**
     * 株式の譲渡・配当所得更新API
     * 株式の譲渡・配当所得を更新する。
     * @param requestBody
     * @returns response_stockIncomes Example response
     * @throws ApiError
     */
    public patchStockIncomes(
        requestBody?: request_stockIncomes,
    ): CancelablePromise<response_stockIncomes> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/stock_incomes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 従業員取得API
     * 従業員を取得する。
     * @returns response_employeeSalaries Example response
     * @throws ApiError
     */
    public getEmployeeSalaries(): CancelablePromise<response_employeeSalaries> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/employee_salaries',
        });
    }

    /**
     * 従業員更新API
     * 従業員を更新する。
     * @param requestBody
     * @returns response_employeeSalaries Example response
     * @throws ApiError
     */
    public patchEmployeeSalaries(
        requestBody?: request_employeeSalaries,
    ): CancelablePromise<response_employeeSalaries> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/employee_salaries',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 住宅ローン控除取得API
     * 住宅ローン控除を取得する。
     * @returns houseLoanDeduction Example response
     * @throws ApiError
     */
    public getHouseLoanDeduction(): CancelablePromise<houseLoanDeduction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/house_loan_deduction',
        });
    }

    /**
     * 住宅ローン控除更新API
     * 住宅ローン控除を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchHouseLoanDeduction(
        requestBody?: houseLoanDeduction,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/house_loan_deduction',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 住宅借入金等特別控除額の計算明細書プレビューAPI
     * 住宅借入金等特別控除額の計算明細書プレビューを返す。
     * @returns response_preview_houseLoanDeduction Example response
     * @throws ApiError
     */
    public getPreviewHouseLoanDeduction(): CancelablePromise<response_preview_houseLoanDeduction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/house_loan_deduction',
        });
    }

    /**
     * 確定申告書第四表プレビューAPI
     * 確定申告書第四表のプレビューを返す。
     * @returns response_preview_taxReturn4 Example response
     * @throws ApiError
     */
    public getPreviewTaxReturn4(): CancelablePromise<response_preview_taxReturn4> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/tax_return4',
        });
    }

    /**
     * 前年からの繰越損失取得API
     * 前年からの繰越損失を取得する。
     * @returns response_carriedForwardLosses Example response
     * @throws ApiError
     */
    public getCarriedForwardLosses(): CancelablePromise<response_carriedForwardLosses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/carried_forward_losses',
        });
    }

    /**
     * 前年からの繰越損失更新API
     * 前年からの繰越損失を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchCarriedForwardLosses(
        requestBody?: request_carriedForwardLosses,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/carried_forward_losses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 連帯債務がある場合の住宅借入金等の年末残高の計算明細書プレビューAPI
     * 連帯債務がある場合の住宅借入金等の年末残高の計算明細書のプレビューを返す。
     * @returns response_preview_houseLoanDeduction_appendix_jointDebt Example response
     * @throws ApiError
     */
    public getPreviewHouseLoanDeductionAppendixJointDebt(): CancelablePromise<response_preview_houseLoanDeduction_appendix_jointDebt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/house_loan_deduction/appendix/joint_debt',
        });
    }

    /**
     * 認定NPO法人等寄附金特別控除額の計算明細書プレビューAPI
     * 認定NPO法人等寄附金特別控除額の計算明細書のプレビューを返す。
     * @returns response_preview_donation_npo Example response
     * @throws ApiError
     */
    public getPreviewDonationNpo(): CancelablePromise<response_preview_donation_npo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preview/donation/npo',
        });
    }

}
