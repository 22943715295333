/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_onboardingActions = {
    path: string;
    method: request_onboardingActions.method;
};

export namespace request_onboardingActions {

    export enum method {
        GET = 'GET',
        POST = 'POST',
    }


}

