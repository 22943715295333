/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type donation = {
    destination: donation.destination;
    destination_detail: donation.destination_detail | null;
    donation_date: string;
    amount: number;
    recipient_name: string;
    recipient_address: string;
    will_use_special_deduction: boolean;
};

export namespace donation {

    export enum destination {
        NATION = 'nation',
        PREFECTURE = 'prefecture',
        RED_CROSS = 'red_cross',
        COMMUNITY_CHEST = 'community_chest',
        POLITICAL_PARTIES = 'political_parties',
        NPO = 'npo',
        PUBLIC_INTEREST_CORPORATION = 'public_interest_corporation',
        OTHER_DONATION = 'other_donation',
    }

    export enum destination_detail {
        DISASTER_RELIEF = 'disaster_relief',
        PLACE_OF_RESIDENCE = 'place_of_residence',
        OTHER_PLACE_OF_RESIDENCE = 'other_place_of_residence',
        BOTH_ORDINANCE = 'both_ordinance',
        PREFECTURE_ORDINANCE = 'prefecture_ordinance',
        MUNICIPALITY_ORDINANCE = 'municipality_ordinance',
        NOT_ORDINANCE = 'not_ordinance',
    }


}

