/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_journals_bulkPatch } from '../models/request_journals_bulkPatch';
import type { request_journals_import } from '../models/request_journals_import';
import type { request_journals_manual } from '../models/request_journals_manual';
import type { request_journals_patch } from '../models/request_journals_patch';
import type { request_journals_post } from '../models/request_journals_post';
import type { response_journals } from '../models/response_journals';
import type { response_journals_get } from '../models/response_journals_get';
import type { response_journals_manual } from '../models/response_journals_manual';
import type { response_journals_patch } from '../models/response_journals_patch';
import type { response_journals_totalAmount } from '../models/response_journals_totalAmount';
import type { response_journals_withholdingAmounts } from '../models/response_journals_withholdingAmounts';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class JournalService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 仕訳取得API
     * 仕訳一覧の取得を行う。
     * @param page
     * @param since
     * @param until
     * @param remarks
     * @param accountItemIds
     * @param type
     * @param accountIds
     * @param amountMax
     * @param amountMin
     * @param scope
     * @param count 件数
     * @param includeBackDetailsInSearch 勘定科目検索時にBackDetailの勘定科目まで含めるかどうか
     * @returns response_journals_get Example response
     * @throws ApiError
     */
    public getJournals(
        page?: number,
        since?: string,
        until?: string,
        remarks?: string,
        accountItemIds?: any[],
        type?: 'income' | 'outgo',
        accountIds?: any[],
        amountMax?: number,
        amountMin?: number,
        scope?: 'fixed_asset' | 'business_income' | 'unregistered_fixed_asset',
        count?: number,
        includeBackDetailsInSearch?: boolean,
    ): CancelablePromise<response_journals_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/journals',
            query: {
                'page': page,
                'since': since,
                'until': until,
                'remarks': remarks,
                'account_item_ids[]': accountItemIds,
                'type': type,
                'account_ids[]': accountIds,
                'amount_max': amountMax,
                'amount_min': amountMin,
                'scope': scope,
                'count': count,
                'include_back_details_in_search': includeBackDetailsInSearch,
            },
        });
    }

    /**
     * 仕訳作成API
     * 仕訳の作成を行う。
     * @param requestBody
     * @returns response_journals Example response
     * @throws ApiError
     */
    public postJournals(
        requestBody?: request_journals_post,
    ): CancelablePromise<response_journals> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/journals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 仕訳一括更新API
     * 仕訳を一括で更新する
     * @param requestBody
     * @returns response_journals Example response
     * @throws ApiError
     */
    public bulkPatchJournals(
        requestBody?: request_journals_bulkPatch,
    ): CancelablePromise<response_journals> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/journals',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Example response`,
            },
        });
    }

    /**
     * 仕訳一括削除API
     * 仕訳を一括で削除する
     * @param ids 削除する仕訳ID
     * @returns void
     * @throws ApiError
     */
    public bulkDeleteJournals(
        ids?: any[],
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/journals',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * 仕訳更新API
     * 仕訳の編集を行う。
     * @param journalId
     * @param requestBody
     * @returns response_journals_patch Example response
     * @throws ApiError
     */
    public patchJournals(
        journalId: number,
        requestBody?: request_journals_patch,
    ): CancelablePromise<response_journals_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/journals/{journal_id}',
            path: {
                'journal_id': journalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 仕訳削除API
     * 仕訳の削除を行う。
     * @param journalId
     * @returns any OK
     * @throws ApiError
     */
    public deleteJournals(
        journalId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/journals/{journal_id}',
            path: {
                'journal_id': journalId,
            },
        });
    }

    /**
     * 仕訳編集モードからの仕訳作成API
     * 仕訳編集モードから仕訳の作成を行う。
     * @param requestBody
     * @returns response_journals_manual Example response
     * @throws ApiError
     */
    public postJournalsManual(
        requestBody?: request_journals_manual,
    ): CancelablePromise<response_journals_manual> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/journals/manual',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 仕訳編集モードからの仕訳更新API
     * 仕訳編集モードから仕訳の更新を行う。
     * @param journalId 仕訳ID
     * @param requestBody
     * @returns response_journals_manual Example response
     * @throws ApiError
     */
    public patchJournalsJournalIdManual(
        journalId: number,
        requestBody?: request_journals_manual,
    ): CancelablePromise<response_journals_manual> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/journals/{journal_id}/manual',
            path: {
                'journal_id': journalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 合計仕訳金額取得API
     * 合計仕訳金額を取得する。
     * @param scope
     * @returns response_journals_totalAmount Example response
     * @throws ApiError
     */
    public getJournalsTotalAmount(
        scope?: 'land-rent' | 'withholding',
    ): CancelablePromise<response_journals_totalAmount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/journals/total_amount',
            query: {
                'scope': scope,
            },
        });
    }

    /**
     * 仕訳インポートAPI
     * 仕訳データをCSVインポートする。
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public postJournalsImport(
        formData?: request_journals_import,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/journals/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Example response`,
            },
        });
    }

    /**
     * 源泉徴収の仕訳取得API
     * 源泉徴収の仕訳を取得する。
     * @returns response_journals_withholdingAmounts Example response
     * @throws ApiError
     */
    public getJournalsWithholdingAmounts(): CancelablePromise<response_journals_withholdingAmounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/journals/withholding_amounts',
        });
    }

}
