/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_opening_blue_export = {
    tax_office_name: string;
    tax_payment_place: {
        place: response_opening_blue_export.place;
        zip_code: string;
        address: string;
        tel: string;
    };
    another_place: {
        zip_code?: string;
        address?: string;
        tel?: string;
    } | null;
    name: string;
    name_kana: string;
    birth_era_name: response_opening_blue_export.birth_era_name | null;
    birth_era_year: number | null;
    birthday: string | null;
    occupation: string;
    business_name: string;
    business_name_kana: string;
    blue_start_era_year: number | null;
    office_address: string;
    has_income_from_business: boolean;
    has_income_from_real_estate: boolean;
    has_income_from_deforestation: boolean;
    has_canceled_blue: boolean;
    /**
     * 1月15日以前の場合は空文字を返します
     */
    open_date: string | null;
    has_business_succession: boolean;
    bookkeeping_method: response_opening_blue_export.bookkeeping_method;
    book_names: Array<'cash_account' | 'receivable' | 'payable' | 'expense' | 'fixed_asset' | 'deposit_account' | 'general_ledger' | 'journal'>;
};

export namespace response_opening_blue_export {

    export enum place {
        HOME = 'home',
        OFFICE = 'office',
    }

    export enum birth_era_name {
        TAISHO = 'taisho',
        SHOWA = 'showa',
        HEISEI = 'heisei',
        REIWA = 'reiwa',
    }

    export enum bookkeeping_method {
        DOUBLE = 'double',
    }


}

