/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_ultra_tasks_eTax = {
    my_number_card_created_status: response_ultra_tasks_eTax.my_number_card_created_status | null;
    digital_certificate_reissue_required: response_ultra_tasks_eTax.digital_certificate_reissue_required | null;
    e_tax_user_id_issued_status: response_ultra_tasks_eTax.e_tax_user_id_issued_status | null;
    e_tax_login_succeeded_status: response_ultra_tasks_eTax.e_tax_login_succeeded_status | null;
    e_certificate_signature_password_status: response_ultra_tasks_eTax.e_certificate_signature_password_status | null;
    digital_certificate_reissue_status: response_ultra_tasks_eTax.digital_certificate_reissue_status | null;
    e_tax_user_id: string | null;
};

export namespace response_ultra_tasks_eTax {

    export enum my_number_card_created_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum digital_certificate_reissue_required {
        YES = 'yes',
        NO = 'no',
        UNKNOWN = 'unknown',
    }

    export enum e_tax_user_id_issued_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum e_tax_login_succeeded_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum e_certificate_signature_password_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }

    export enum digital_certificate_reissue_status {
        COMPLETED = 'completed',
        INCOMPLETED = 'incompleted',
    }


}

