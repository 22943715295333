/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_lastFinancialStatementImages = {
    last_tax_return_type: response_lastFinancialStatementImages.last_tax_return_type | null;
    images: Array<{
        id: number;
        file_name: string;
        url: string;
        uploaded_at: string;
    }>;
};

export namespace response_lastFinancialStatementImages {

    export enum last_tax_return_type {
        WHITE = 'white',
        BLUE = 'blue',
    }


}

