/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_user_lineUserId } from '../models/request_user_lineUserId';
import type { response_user_confirmationSentAt } from '../models/response_user_confirmationSentAt';
import type { response_user_confirmedAt } from '../models/response_user_confirmedAt';
import type { response_user_info } from '../models/response_user_info';
import type { response_user_isTestUser } from '../models/response_user_isTestUser';
import type { response_user_provider } from '../models/response_user_provider';
import type { response_user_signUpFrom } from '../models/response_user_signUpFrom';
import type { response_user_userType } from '../models/response_user_userType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Provider取得API
     * ユーザーのProviderを取得する。
     * @returns response_user_provider Example response
     * @throws ApiError
     */
    public getUserProvider(): CancelablePromise<response_user_provider> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/provider',
        });
    }

    /**
     * テストユーザーフラグ取得API
     * ユーザーのテストユーザーフラグを取得する。
     * @returns response_user_isTestUser Example response
     * @throws ApiError
     */
    public getUserIsTestUser(): CancelablePromise<response_user_isTestUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/is_test_user',
        });
    }

    /**
     * LINE UserID更新API
     * ユーザーのLINE UserIDを更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchUserLineUserId(
        requestBody?: request_user_lineUserId,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/line_user_id',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 本人認証メール送信日時取得API
     * 本人認証メール送信日時を返す。
     * @returns response_user_confirmationSentAt Example response
     * @throws ApiError
     */
    public getUserConfirmationSentAt(): CancelablePromise<response_user_confirmationSentAt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/confirmation_sent_at',
        });
    }

    /**
     * 本人認証日時取得API
     * 本人認証を行った日時を返す。
     * @returns response_user_confirmedAt Example response
     * @throws ApiError
     */
    public getUserConfirmedAt(): CancelablePromise<response_user_confirmedAt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/confirmed_at',
        });
    }

    /**
     * ユーザー登録元取得API
     * ユーザー登録を行った際のutm_sourceを取得する。
     * @returns response_user_signUpFrom Example response
     * @throws ApiError
     */
    public getUserSignUpFrom(): CancelablePromise<response_user_signUpFrom> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/sign_up_from',
        });
    }

    /**
     * ユーザー種別取得API
     * ユーザー種別を取得する。
     * @returns response_user_userType Example response
     * @throws ApiError
     */
    public getUserUserType(): CancelablePromise<response_user_userType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/user_type',
        });
    }

    /**
     * ユーザー情報取得API
     * ユーザーの情報を取得する。
     * @returns response_user_info Example response
     * @throws ApiError
     */
    public getUserInfo(): CancelablePromise<response_user_info> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/info',
        });
    }

}
