/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { preview_refundAccount } from './preview_refundAccount';

export type response_preview_consumptionTax_general_page1 = {
    is_necessary: boolean;
    page_1: {
        submit_era_year: number;
        submit_month: number;
        submit_date: number;
        tax_office_name: string;
        address: string;
        tel: string;
        business_name_kana: string;
        business_name: string;
        name_kana: string;
        name: string;
        continue_transfer: boolean;
        tax_start_era_code: response_preview_consumptionTax_general_page1.tax_start_era_code;
        tax_start_era_year: number;
        tax_start_month: number;
        tax_start_date: number;
        tax_end_era_year: number;
        tax_end_month: number;
        tax_end_date: number;
        declaration_type: string;
        refund_account: preview_refundAccount;
        will_use_public_money_receipt_account: boolean;
        sales_tax_base_amount: number;
        sales_tax_amount: number;
        collection_bad_debt_tax_amount: number;
        deduction: {
            deductible_purchase_tax_amount: number;
            sales_return_tax_amount: number;
            bad_debt_tax_amount: number;
            deductible_subtotal_amount: number;
        };
        refund_tax_amount: number;
        subtracted_tax_amount: number;
        interim_tax_amount: number;
        pay_tax_amount: number;
        interim_refund_tax_amount: number;
        taxable_assets_transfer_amount: number;
        assets_transfer_amount: number;
        local_tax_base: {
            refund_amount: number;
            pay_amount: number;
        };
        local_tax: {
            refund_amount: number;
            pay_amount: number;
        };
        interim_local_tax_amount: number;
        pay_local_tax_amount: number;
        interim_refund_local_tax_amount: number;
        final_tax_amount: number;
        is_applied_installment_basis: boolean;
        is_applied_deferred_payment_standards: boolean;
        is_applied_percentage_of_completion_method: boolean;
        is_applied_cash_basis_accounting: boolean;
        is_applied_sales_tax_base_amount_exception: boolean;
        taxable_type: {
            is_item: boolean;
            is_proportion: boolean;
            is_full: boolean;
        };
        is_applied_invoice_exception: boolean;
    } | null;
};

export namespace response_preview_consumptionTax_general_page1 {

    export enum tax_start_era_code {
        R = 'R',
    }


}

