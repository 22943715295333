/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type topicMessage = {
    id: number;
    is_sender: boolean;
    message_type: topicMessage.message_type;
    contents: Array<{
        text: string | null;
        action_type: 'journal.private_or_not_replied' | 'journal.usage_replied' | null;
        data: string | null;
    }>;
    sent_at: string;
};

export namespace topicMessage {

    export enum message_type {
        TEXT = 'text',
        POSTBACK = 'postback',
    }


}

