/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_report_config_patch = {
    /**
     * 0: 白色, 1: 青色
     */
    tax_return_type: response_report_config_patch.tax_return_type;
    /**
     * 0: e-Taxで電子申告, 1: 窓口・郵送で提出
     */
    submission_method: response_report_config_patch.submission_method;
    salary_amount: number;
};

export namespace response_report_config_patch {

    /**
     * 0: 白色, 1: 青色
     */
    export enum tax_return_type {
        '_0' = 0,
        '_1' = 1,
    }

    /**
     * 0: e-Taxで電子申告, 1: 窓口・郵送で提出
     */
    export enum submission_method {
        '_0' = 0,
        '_1' = 1,
    }


}

