/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_onboardingActions } from '../models/request_onboardingActions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OnboardingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * オンボーディングアクション作成API
     * オンボーディングのアクションログを作成する
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postOnboardingActions(
        requestBody?: request_onboardingActions,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/onboarding_actions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
