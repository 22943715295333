/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_basicInformation_specialDeductionAmount = {
    special_deduction_amount: response_basicInformation_specialDeductionAmount.special_deduction_amount;
};

export namespace response_basicInformation_specialDeductionAmount {

    export enum special_deduction_amount {
        '_100000' = 100000,
        '_550000' = 550000,
        '_650000' = 650000,
    }


}

