/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_marunageProgresses = {
    category: request_marunageProgresses.category;
    code: request_marunageProgresses.code;
    status: request_marunageProgresses.status;
};

export namespace request_marunageProgresses {

    export enum category {
        SERVEY = 'servey',
        DOCUMENT = 'document',
        MEETING = 'meeting',
        REVIEW = 'review',
    }

    export enum code {
        ADVANCE = 'advance',
        SALARIES = 'salaries',
        RETIREMENT_INCOMES = 'retirement_incomes',
        MISCELLANEOUS_INCOMES_CRYPTO = 'miscellaneous_incomes_crypto',
        MISCELLANEOUS_INCOMES_BUSINESS = 'miscellaneous_incomes_business',
        BUSINESS_INCOME_DETAILS = 'business_income_details',
        EXC_SOCIAL_INSURANCES = 'exc_social_insurances',
        FAMILY_SPOUSE = 'family_spouse',
        OTHER_FAMILIES = 'other_families',
        SOCIAL_INSURANCE_PENSION = 'social_insurance_pension',
        SOCIAL_INSURANCE_HEALTH = 'social_insurance_health',
        SOCIAL_INSURANCE_PENSION_FUND = 'social_insurance_pension_fund',
        OTHER_SOCIAL_INSURANCES = 'other_social_insurances',
        SMALL_BUSINESS_MUTUAL_AID_PENSIONS = 'small_business_mutual_aid_pensions',
        SMALL_BUSINESS_MUTUAL_AIDS = 'small_business_mutual_aids',
        MEDICAL_EXPENSES = 'medical_expenses',
        DONATION_PREFECTURE = 'donation_prefecture',
        OTHER_DONATIONS = 'other_donations',
        ESTIMATED_TAX_PREPAYMENTS = 'estimated_tax_prepayments',
        COUNSELOR_FEES = 'counselor_fees',
        FIRST_WITH_TAX_ACCOUNTANT = 'first_with_tax_accountant',
        SECOND_WITH_TAX_ACCOUNTANT = 'second_with_tax_accountant',
        USER_JOURNALS_REVIEW = 'user_journals_review',
        USER_FIXED_ASSETS_REVIEW = 'user_fixed_assets_review',
        TAX_ACCOUNTANT_JOURNALS_REVIEW = 'tax_accountant_journals_review',
        TAX_ACCOUNTANT_TAX_RETURN_DOCUMENT_REVIEW = 'tax_accountant_tax_return_document_review',
    }

    export enum status {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

