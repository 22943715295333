/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_startupExpense_progress } from '../models/request_startupExpense_progress';
import type { request_startupExpenses } from '../models/request_startupExpenses';
import type { response_startupExpense_progress } from '../models/response_startupExpense_progress';
import type { response_startupExpenses } from '../models/response_startupExpenses';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StartupExpenseService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 開業費一覧取得API
     * 開業費一覧を取得する。
     * @returns response_startupExpenses Example response
     * @throws ApiError
     */
    public getStartupExpenses(): CancelablePromise<response_startupExpenses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/startup_expenses',
        });
    }

    /**
     * 開業費作成API
     * 開業費を作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postStartupExpenses(
        requestBody?: request_startupExpenses,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/startup_expenses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業費削除API
     * 開業費を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteStartupExpensesId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/startup_expenses/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 開業費の登録状況更新API
     * 開業費の登録状況を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchStartupExpenseProgress(
        requestBody?: request_startupExpense_progress,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/startup_expense/progress',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業費の登録状況取得API
     * 開業費の登録状況を取得する。
     * @returns response_startupExpense_progress Example response
     * @throws ApiError
     */
    public getStartupExpenseProgress(): CancelablePromise<response_startupExpense_progress> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/startup_expense/progress',
        });
    }

}
