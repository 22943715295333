/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_atTransactions_patch } from '../models/request_atTransactions_patch';
import type { response_atTransaction_recommendAccountItem } from '../models/response_atTransaction_recommendAccountItem';
import type { response_atTransactions } from '../models/response_atTransactions';
import type { response_atTransactions_progress } from '../models/response_atTransactions_progress';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AtTransactionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 連携取引一覧取得API
     * 連携された取引一覧を取得する。
     * @param since
     * @param until
     * @param isIncome 収支フラグ
     * @returns response_atTransactions Example response
     * @throws ApiError
     */
    public getAtTransactions(
        since?: string,
        until?: string,
        isIncome?: boolean,
    ): CancelablePromise<response_atTransactions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/at_transactions',
            query: {
                'since': since,
                'until': until,
                'is_income': isIncome,
            },
        });
    }

    /**
     * 連携取引更新API
     * 連携された取引の更新を行う。
     * @param requestBody
     * @returns response_atTransactions Example response
     * @throws ApiError
     */
    public patchAtTransactions(
        requestBody?: request_atTransactions_patch,
    ): CancelablePromise<response_atTransactions> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/at_transactions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Example response`,
                404: `Example response`,
            },
        });
    }

    /**
     * 連携取引仕訳進捗状況取得API
     * 連携された取引の仕訳進捗状況を取得する。
     * @returns response_atTransactions_progress Example response
     * @throws ApiError
     */
    public getAtTransactionsProgress(): CancelablePromise<response_atTransactions_progress> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/at_transactions/progress',
        });
    }

    /**
     * 連携取引レコメンド勘定科目取得API
     * 連携取引のレコメンド勘定科目を取得する
     * @param atTransactionId
     * @returns response_atTransaction_recommendAccountItem Example response
     * @throws ApiError
     */
    public getAtTransactionsAtTransactionIdRecommendAccountItem(
        atTransactionId: string,
    ): CancelablePromise<response_atTransaction_recommendAccountItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/at_transactions/{at_transaction_id}/recommend_account_item',
            path: {
                'at_transaction_id': atTransactionId,
            },
        });
    }

}
