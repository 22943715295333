import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const primary = c === 'green' ? 'primary.green' : 'primary.purple'

  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '1.5px solid',
    borderRadius: '2px',
    borderColor: 'gray.600',
    color: 'white',
    bgColor: 'white.100',

    _checked: {
      bg: primary,
      borderColor: primary,
      color: 'white.100',

      _hover: {
        bg: primary,
        borderColor: primary
      },

      _disabled: {
        borderColor: mode('gray.200', 'transparent')(props),
        bg: mode('gray.50', 'whiteAlpha.300')(props),
        color: mode('gray.500', 'whiteAlpha.500')(props)
      }
    },

    _indeterminate: {
      bg: primary,
      borderColor: primary,
      color: 'white.100'
    },

    _disabled: {
      bg: mode('gray.50', 'whiteAlpha.100')(props),
      borderColor: mode('gray.200', 'transparent')(props)
    },

    _focus: {
      boxShadow: 'none'
    },

    _invalid: {
      borderColor: mode('red.500', 'red.300')(props)
    }
  }
}

const baseStyleContainer: SystemStyleObject = {
  _disabled: { cursor: 'not-allowed' },
  display: 'flex'
}

const baseStyleLabel: SystemStyleObject = {
  userSelect: 'none',
  fontWeight: 500,
  lineHeight: 1,
  _disabled: { opacity: 0.4 }
}

const baseStyleIcon: SystemStyleObject = {
  transitionProperty: 'transform',
  transitionDuration: 'normal'
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: baseStyleControl(props),
  label: baseStyleLabel
})

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    container: { h: '32px', display: 'flex' },
    control: { h: '15px', w: '15px' },
    label: { fontSize: '13px' },
    icon: { fontSize: '0.5rem' }
  },
  md: {
    control: { w: '15px', h: '15px', display: 'flex' },
    label: { fontSize: '16px' },
    icon: { fontSize: '0.625rem' }
  },
  lg: {
    control: { w: '18px', h: '18px' },
    label: { fontSize: '13px' },
    icon: { fontSize: '0.625rem' }
  },
  xl: {
    control: { w: '20px', h: '20px' },
    label: { fontSize: '16px' },
    icon: { fontSize: '0.625rem' }
  }
}

const defaultProps = {
  size: 'md',
  colorScheme: 'blue'
}

const variants = {
  taxTask: {
    control: {
      borderColor: 'primary.black'
    }
  }
}

export const CheckboxTheme = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps
}
