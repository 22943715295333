/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type miscellaneousIncome = {
    type: miscellaneousIncome.type;
    item: miscellaneousIncome.item | null;
    payer_name: string;
    payer_address: string;
    revenue_amount: number;
    withholding_amount: number;
    unpaid_withholding_amount: number;
    expense_amount: number;
};

export namespace miscellaneousIncome {

    export enum type {
        MISCELLANEOUS_PENSION_INCOME = 'MiscellaneousPensionIncome',
        MISCELLANEOUS_BUSINESS_INCOME = 'MiscellaneousBusinessIncome',
        MISCELLANEOUS_OTHER_INCOME = 'MiscellaneousOtherIncome',
    }

    export enum item {
        CRYPTO = 'crypto',
    }


}

