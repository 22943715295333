/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_requiredDocumentImages } from '../models/request_requiredDocumentImages';
import type { request_ultra_survey } from '../models/request_ultra_survey';
import type { request_ultra_tasks_eTax } from '../models/request_ultra_tasks_eTax';
import type { request_ultra_tasks_medicalExpenses } from '../models/request_ultra_tasks_medicalExpenses';
import type { request_ultra_tasks_openingBalance } from '../models/request_ultra_tasks_openingBalance';
import type { request_ultra_tasks_openingDate } from '../models/request_ultra_tasks_openingDate';
import type { request_ultra_tasks_requiredDocuments_code } from '../models/request_ultra_tasks_requiredDocuments_code';
import type { request_ultra_tasks_taxableType } from '../models/request_ultra_tasks_taxableType';
import type { request_ultra_tasks_taxReturnType } from '../models/request_ultra_tasks_taxReturnType';
import type { request_ultra_tasks_uploadRequiredAccountFiles } from '../models/request_ultra_tasks_uploadRequiredAccountFiles';
import type { request_uploadRequiredAccountProgresses } from '../models/request_uploadRequiredAccountProgresses';
import type { request_uploadRequiredAccounts } from '../models/request_uploadRequiredAccounts';
import type { response_lastFinancialStatementImages } from '../models/response_lastFinancialStatementImages';
import type { response_meetingSchedule } from '../models/response_meetingSchedule';
import type { response_ultra_survey } from '../models/response_ultra_survey';
import type { response_ultra_tasks } from '../models/response_ultra_tasks';
import type { response_ultra_tasks_beforeFirstMeetingProgresses } from '../models/response_ultra_tasks_beforeFirstMeetingProgresses';
import type { response_ultra_tasks_eTax } from '../models/response_ultra_tasks_eTax';
import type { response_ultra_tasks_medicalExpenses } from '../models/response_ultra_tasks_medicalExpenses';
import type { response_ultra_tasks_newYear } from '../models/response_ultra_tasks_newYear';
import type { response_ultra_tasks_openingBalance } from '../models/response_ultra_tasks_openingBalance';
import type { response_ultra_tasks_openingDate } from '../models/response_ultra_tasks_openingDate';
import type { response_ultra_tasks_requiredDocuments } from '../models/response_ultra_tasks_requiredDocuments';
import type { response_ultra_tasks_requiredDocuments_code } from '../models/response_ultra_tasks_requiredDocuments_code';
import type { response_ultra_tasks_taxableType_get } from '../models/response_ultra_tasks_taxableType_get';
import type { response_ultra_tasks_taxableType_patch } from '../models/response_ultra_tasks_taxableType_patch';
import type { response_ultra_tasks_taxReturnType } from '../models/response_ultra_tasks_taxReturnType';
import type { response_ultra_tasks_uploadRequiredAccountFiles } from '../models/response_ultra_tasks_uploadRequiredAccountFiles';
import type { response_uploadRequiredAccounts } from '../models/response_uploadRequiredAccounts';
import type { response_uploadRequiredAccounts_unconnected } from '../models/response_uploadRequiredAccounts_unconnected';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UltraService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * ULTRAプランユーザー初回MTG前タスク進捗取得API
     * ULTRAプランユーザー初回MTG前タスク進捗を取得する。
     * @returns response_ultra_tasks_beforeFirstMeetingProgresses Example response
     * @throws ApiError
     */
    public getUltraTasksBeforeFirstMeetingProgresses(): CancelablePromise<response_ultra_tasks_beforeFirstMeetingProgresses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/before_first_meeting_progresses',
        });
    }

    /**
     * ULTRAプランユーザーアンケート回答状況更新API
     * ULTRAプランユーザーアンケート回答状況を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchUltraSurvey(
        requestBody?: request_ultra_survey,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/survey',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ULTRAプランユーザーアンケート回答状況取得API
     * ULTRAプランユーザーアンケート回答状況を取得する。
     * @param code
     * @returns response_ultra_survey Example response
     * @throws ApiError
     */
    public getUltraSurvey(
        code: 'initial',
    ): CancelablePromise<response_ultra_survey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/survey',
            query: {
                'code': code,
            },
        });
    }

    /**
     * ULTRAプランユーザー初回MTG前タスク進捗取得API
     * ULTRAプランユーザーMTGスケジュールを取得する。
     * @param name
     * @returns response_meetingSchedule Example response
     * @throws ApiError
     */
    public getMeetingSchedule(
        name: 'first' | 'second' | 'third',
    ): CancelablePromise<response_meetingSchedule> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meeting_schedule',
            query: {
                'name': name,
            },
        });
    }

    /**
     * CSVダウンロードが必要な金融機関取得API
     * CSVダウンロードが必要な金融機関を取得する。
     * @returns response_uploadRequiredAccounts Example response
     * @throws ApiError
     */
    public getUploadRequiredAccounts(): CancelablePromise<response_uploadRequiredAccounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/upload_required_accounts',
        });
    }

    /**
     * CSVダウンロードが必要な金融機関更新API
     * CSVダウンロードが必要な金融機関を更新する。
     * @param requestBody
     * @returns response_uploadRequiredAccounts Example response
     * @throws ApiError
     */
    public patchUploadRequiredAccounts(
        requestBody?: request_uploadRequiredAccounts,
    ): CancelablePromise<response_uploadRequiredAccounts> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/upload_required_accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * AT未連携の金融機関取得API
     * AT未連携の金融機関を取得する。
     * @returns response_uploadRequiredAccounts_unconnected Example response
     * @throws ApiError
     */
    public getUploadRequiredAccountsUnconnected(): CancelablePromise<response_uploadRequiredAccounts_unconnected> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/upload_required_accounts/unconnected',
        });
    }

    /**
     * CSVダウンロードが必要な金融機関削除API
     * CSVダウンロードが必要な金融機関を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteUploadRequiredAccountsId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/upload_required_accounts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * CSVダウンロードが必要な金融機関の準備状況更新API
     * CSVダウンロードが必要な金融機関の準備状況を更新する。
     * @param uploadRequiredAccountId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchUploadRequiredAccountProgressesId(
        uploadRequiredAccountId: number,
        requestBody?: request_uploadRequiredAccountProgresses,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/upload_required_account_progresses/{upload_required_account_id}',
            path: {
                'upload_required_account_id': uploadRequiredAccountId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ULTRAタスク一覧取得API
     * ULTRAタスク一覧を取得する。
     * @returns response_ultra_tasks Example response
     * @throws ApiError
     */
    public getUltraTasks(): CancelablePromise<response_ultra_tasks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks',
        });
    }

    /**
     * 開業日情報取得API
     * 開業日情報を取得する。
     * @returns response_ultra_tasks_openingDate Example response
     * @throws ApiError
     */
    public getUltraTasksOpeningDate(): CancelablePromise<response_ultra_tasks_openingDate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/opening_date',
        });
    }

    /**
     * 開業日情報更新API
     * 開業日情報を更新する。
     * @param requestBody
     * @returns response_ultra_tasks_openingDate Example response
     * @throws ApiError
     */
    public patchUltraTasksOpeningDate(
        requestBody?: request_ultra_tasks_openingDate,
    ): CancelablePromise<response_ultra_tasks_openingDate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/opening_date',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 青色申告可否情報取得API
     * 青色申告可否情報を取得する。
     * @returns response_ultra_tasks_taxReturnType Example response
     * @throws ApiError
     */
    public getUltraTasksTaxReturnType(): CancelablePromise<response_ultra_tasks_taxReturnType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/tax_return_type',
        });
    }

    /**
     * 青色申告可否情報更新API
     * 青色申告可否情報を更新する。
     * @param requestBody
     * @returns response_ultra_tasks_taxReturnType Example response
     * @throws ApiError
     */
    public patchUltraTasksTaxReturnType(
        requestBody?: request_ultra_tasks_taxReturnType,
    ): CancelablePromise<response_ultra_tasks_taxReturnType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/tax_return_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 前回の決算書・収支内訳書ファイル情報取得API
     * 前回の決算書・収支内訳書ファイル情報を取得する。
     * @returns response_lastFinancialStatementImages Example response
     * @throws ApiError
     */
    public getLastFinancialStatementImages(): CancelablePromise<response_lastFinancialStatementImages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/last_financial_statement_images',
        });
    }

    /**
     * 前回の決算書・収支内訳書ファイル情報削除API
     * 前回の決算書・収支内訳書ファイル情報を削除する。
     * @param id 前回の決算書・収支内訳書ファイルID
     * @returns void
     * @throws ApiError
     */
    public deleteLastFinancialStatementImages(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/last_financial_statement_images/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 開始残高設定情報取得API
     * 開始残高設定情報を取得する。
     * @returns response_ultra_tasks_openingBalance Example response
     * @throws ApiError
     */
    public getUltraTasksOpeningBalance(): CancelablePromise<response_ultra_tasks_openingBalance> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/opening_balance',
        });
    }

    /**
     * 開始残高設定情報更新API
     * 開始残高設定情報を更新する。
     * @param requestBody
     * @returns response_ultra_tasks_openingBalance Example response
     * @throws ApiError
     */
    public patchUltraTasksOpeningBalance(
        requestBody?: request_ultra_tasks_openingBalance,
    ): CancelablePromise<response_ultra_tasks_openingBalance> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/opening_balance',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * アップロードが必要な金融機関情報取得API
     * アップロードが必要な金融機関情報を取得する。
     * @param uploadRequiredAccountId
     * @returns response_ultra_tasks_uploadRequiredAccountFiles Example response
     * @throws ApiError
     */
    public getUltraTasksUploadRequiredAccountFiles(
        uploadRequiredAccountId: number,
    ): CancelablePromise<response_ultra_tasks_uploadRequiredAccountFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/upload_required_account_files/{upload_required_account_id}',
            path: {
                'upload_required_account_id': uploadRequiredAccountId,
            },
        });
    }

    /**
     * 金融機関の明細ファイル情報登録API
     * 金融機関の明細ファイル情報を登録する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postUltraTasksUploadRequiredAccountFiles(
        requestBody?: request_ultra_tasks_uploadRequiredAccountFiles,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ultra/tasks/upload_required_account_files',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 金融機関の明細ファイル情報削除API
     * 金融機関の明細ファイル情報を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteUltraTasksUploadRequiredAccountFiles(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/ultra/tasks/upload_required_account_files',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @deprecated
     * 課税事業者区分取得API
     * 課税事業者区分を取得する。
     * @returns response_ultra_tasks_taxableType_get Example response
     * @throws ApiError
     */
    public getUltraTasksTaxableType(): CancelablePromise<response_ultra_tasks_taxableType_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/taxable_type',
        });
    }

    /**
     * @deprecated
     * 課税事業者区分更新API
     * 課税事業者区分を更新する。
     * @param requestBody
     * @returns response_ultra_tasks_taxableType_patch Example response
     * @throws ApiError
     */
    public patchUltraTasksTaxableType(
        requestBody?: request_ultra_tasks_taxableType,
    ): CancelablePromise<response_ultra_tasks_taxableType_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/taxable_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 電子申告の準備タスク状況取得API
     * 電子申告の準備タスク状況を取得する。
     * @returns response_ultra_tasks_eTax Example response
     * @throws ApiError
     */
    public getUltraTasksETax(): CancelablePromise<response_ultra_tasks_eTax> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/e_tax',
        });
    }

    /**
     * 電子申告の準備タスク状況更新API
     * 電子申告の準備タスク状況を更新する。
     * @param requestBody
     * @returns response_ultra_tasks_eTax Example response
     * @throws ApiError
     */
    public patchUltraTasksETax(
        requestBody?: request_ultra_tasks_eTax,
    ): CancelablePromise<response_ultra_tasks_eTax> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/e_tax',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 必要書類の保管タスク一覧取得API
     * 必要書類の保管タスク一覧を取得する。
     * @returns response_ultra_tasks_requiredDocuments Example response
     * @throws ApiError
     */
    public getUltraTasksRequiredDocuments(): CancelablePromise<response_ultra_tasks_requiredDocuments> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/required_documents',
        });
    }

    /**
     * 必要書類の保管アップロードファイル一覧取得API
     * 必要書類の保管アップロードファイルの一覧を取得する。
     * @param code
     * @returns response_ultra_tasks_requiredDocuments_code Example response
     * @throws ApiError
     */
    public getUltraTasksRequiredDocumentsCode(
        code: 'salary' | 'prefecture_donation' | 'national_pension' | 'national_pension_fund' | 'national_health' | 'life' | 'earthquake' | 'estimated_tax_prepayment' | 'other_social_insurance' | 'individual_pension' | 'corporate_pension' | 'smbc' | 'counselor_fee',
    ): CancelablePromise<response_ultra_tasks_requiredDocuments_code> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/required_documents/{code}',
            path: {
                'code': code,
            },
        });
    }

    /**
     * 必要書類の保管タスク更新API
     * 必要書類の保管タスクを更新する。
     * @param code
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchUltraTasksRequiredDocumentsCode(
        code: 'salary' | 'prefecture_donation' | 'national_pension' | 'national_pension_fund' | 'national_health' | 'life' | 'earthquake' | 'estimated_tax_prepayment' | 'other_social_insurance' | 'individual_pension' | 'corporate_pension' | 'smbc' | 'counselor_fee',
        requestBody?: request_ultra_tasks_requiredDocuments_code,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/required_documents/{code}',
            path: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 必要書類の画像アップロード情報保存API
     * 必要書類の画像アップロード情報を保存する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postRequiredDocumentImages(
        requestBody?: request_requiredDocumentImages,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/required_document_images',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 必要書類の画像アップロード情報削除API
     * 必要書類の画像アップロード情報を削除する。
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public deleteRequiredDocumentImagesId(
        id: number,
        requestBody?: any,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/required_document_images/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
        });
    }

    /**
     * ULTRA年明けタスクのステータス取得API
     * ULTRA年明けタスクのステータスを取得する。
     * @returns response_ultra_tasks_newYear Example response
     * @throws ApiError
     */
    public getUltraTasksNewYear(): CancelablePromise<response_ultra_tasks_newYear> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/new_year',
        });
    }

    /**
     * 医療費控除タスク進捗情報取得API
     * 医療費控除タスク進捗情報を取得する。
     * @returns response_ultra_tasks_medicalExpenses Example response
     * @throws ApiError
     */
    public getUltraTasksMedicalExpense(): CancelablePromise<response_ultra_tasks_medicalExpenses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ultra/tasks/medical_expense',
        });
    }

    /**
     * 医療費控除タスク進捗情報更新API
     * 医療費控除タスク進捗情報を更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchUltraTasksMedicalExpense(
        requestBody?: request_ultra_tasks_medicalExpenses,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/ultra/tasks/medical_expense',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
