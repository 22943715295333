/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type fixedAssets = {
    id: number;
    name: string;
    acquisition_date: string;
    acquisition_amount: number;
    account_item_name: string;
    /**
     * draft: 下書き, depreciating: 償却中, removed: 除去済み, sold: 売却済み
     */
    status: fixedAssets.status;
    depreciation: {
        depreciation_amount: number;
        total_depreciation_amount: number;
        balance_amount: number;
    };
};

export namespace fixedAssets {

    /**
     * draft: 下書き, depreciating: 償却中, removed: 除去済み, sold: 売却済み
     */
    export enum status {
        DRAFT = 'draft',
        DEPRECIATING = 'depreciating',
        REMOVED = 'removed',
        SOLD = 'sold',
    }


}

