import { radioAnatomy as parts } from '@chakra-ui/anatomy'
import {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction
} from '@chakra-ui/theme-tools'
import { CheckboxTheme } from './checkbox'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { control = {} } = CheckboxTheme.baseStyle(props)

  return {
    ...control,
    mr: '10px',
    border: '2px solid',
    borderColor: 'gray.400',
    borderRadius: 'full',
    _checked: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ...control['_checked'],
      borderColor: 'purple.900',
      bgColor: 'white.100',
      _hover: {
        borderColor: 'purple.900',
        bgColor: 'white.100'
      },
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        borderColor: 'none',
        bgColor: 'primary.purple',
        color: 'primary.purple'
      },
      _invalid: {
        _before: {
          bgColor: 'primary.alert',
          color: 'primary.alert'
        }
      }
    }
  }
}
const baseStyleContainer: SystemStyleFunction = (props) => {
  const { container = {} } = CheckboxTheme.baseStyle(props)

  return {
    ...container,
    py: '6px',
    pl: 0,
    pr: '12px',
    _checked: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ...container['_checked']
    }
  }
}
const baseStyleLabel: SystemStyleFunction = (props) => {
  const { label = {} } = CheckboxTheme.baseStyle(props)

  return {
    ...label,
    ml: 0,
    _checked: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ...label['_checked'],
      fontWeight: 700
    }
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  label: baseStyleLabel(props),
  container: baseStyleContainer(props),
  control: baseStyleControl(props)
})

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    container: { py: '6px' },
    control: { w: '20px', h: '20px' },
    label: { fontSize: '13px' }
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' }
  },
  sm: {
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' }
  }
}

const defaultProps = {
  size: 'md',
  colorScheme: 'blue'
}

const variants = {
  highlight: {
    label: {},
    container: {
      _checked: {
        bgColor: 'purple.50'
      },
      py: '12px',
      pl: '14px'
    },
    control: {}
  }
}
export const RadioTheme = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps
}
