import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/theme-tools'

const baseStyleOverlay: SystemStyleObject = {
  bg: '#0D0D0D66',
  zIndex: 'modal'
}

const baseStyleDialogContainer: SystemStyleFunction = (props) => {
  const { isCentered, scrollBehavior } = props

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto'
  }
}

const baseStyleDialog: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props

  return {
    boxShadow: 'none',
    borderRadius: '16px',
    bg: 'white.100',
    color: 'inherit',
    my: '3.75rem',
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? '75%' : undefined,
    w: '600px'
  }
}

const baseStyleHeader: SystemStyleObject = {
  pt: '24px',
  pb: '16px',
  px: 0,
  mx: '24px',
  fontSize: '16px',
  lineHeight: 1,
  fontWeight: 700,
  borderBottom: '1px solid',
  borderColor: 'gray.200'
}

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  top: 2,
  insetEnd: 3
}

const baseStyleBody: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props
  return {
    py: '24px',
    px: '24px',
    m: 0,
    flex: 1,
    borderRadius: '16px',
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined
  }
}

const baseStyleFooter: SystemStyleFunction = () => {
  return {
    bgColor: 'gray.70',
    py: '12px',
    px: '12px',
    borderBottomRadius: '8px'
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter(props)
})

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string): PartsStyleObject<typeof parts> {
  if (value === 'full') {
    return {
      dialog: {
        my: 0,
        w: '100vw',
        maxW: '100vw',
        h: 'fit-content',
        maxH: ['calc(100vh - 100px)', 'calc(100vh - 40px)'],
        minH: [0, 'calc(100vh - 40px)'],
        '@supports(min-height: -webkit-fill-available)': {
          minH: [0, 'calc(100vh - 40px)']
        },
        borderTopRadius: '20px',
        borderBottomRadius: 0
      }
    }
  }
  if (value === 'xs') {
    return {
      dialog: { w: ['90%', '328px'], maxW: ['90%', '328px'] }
    }
  }
  if (value === 'sm') {
    return {
      dialog: { w: ['90%', '420px'], maxW: ['90%', '420px'] }
    }
  }
  if (value === 'md') {
    return {
      dialog: { w: ['90%', '480px'], maxW: ['90%', '480px'] }
    }
  }
  if (value === 'lg') {
    return {
      dialog: { w: ['90%', '600px'], maxW: ['90%', '600px'] }
    }
  }
  if (value === 'xl') {
    return {
      dialog: { w: ['90%', '1000px'], maxW: ['90%', '1000px'] }
    }
  }
  return {
    dialog: { w: ['90%', '600px'], maxW: ['90%', '600px'] }
  }
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  '3xl': getSize('3xl'),
  '4xl': getSize('4xl'),
  '5xl': getSize('5xl'),
  '6xl': getSize('6xl'),
  full: getSize('full')
}

const defaultProps = {
  size: 'lg',
  isCentered: true,
  scrollBehavior: 'inside', // 内部コンテンツが多くなったらモーダル内でスクロールさせる
  blockScrollOnMount: true,
  closeOnOverlayClick: false,
  variant: 'normal'
}

const variants = {
  full: {
    overlay: {
      bg: '#0D0D0D99'
    },
    dialogContainer: {
      alignItems: 'flex-end'
    },
    dialog: {
      my: 0,
      w: '100vw',
      maxW: '100vw',

      borderTopRadius: '20px',
      borderBottomRadius: 0
    },

    closeButton: {
      color: 'white.100',
      top: '4px',
      zIndex: 1500
    },
    body: {
      maxW: '980px',
      borderTopRadius: '20px',
      h: 'fit-content',
      w: '100%',
      mx: 'auto',
      p: ['20px', '24px']
    },
    footer: {
      bgColor: 'gray.50'
    }
  },
  confirm: {
    dialog: {},
    header: {
      fontWeight: 700,
      fontSize: '19px',
      lineHeight: 1.5,
      mx: 0,
      px: '24px',
      pt: '24px',
      pb: '12px',
      borderBottom: 'none'
    },
    body: {
      pt: 0,
      pb: 0,
      px: '24px'
    },

    footer: {
      bgColor: 'white.100',
      pt: '16px',
      px: '24px',
      pb: '24px',
      borderBottomRadius: '16px'
    }
  }
}

export const ModalTheme = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps
}
