/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_opening_jobInfo } from '../models/request_opening_jobInfo';
import type { request_opening_personalInfo } from '../models/request_opening_personalInfo';
import type { request_opening_submissionTasks } from '../models/request_opening_submissionTasks';
import type { request_opening_taxOffice } from '../models/request_opening_taxOffice';
import type { request_opening_taxReturnType } from '../models/request_opening_taxReturnType';
import type { response_opening } from '../models/response_opening';
import type { response_opening_blue_export } from '../models/response_opening_blue_export';
import type { response_opening_export } from '../models/response_opening_export';
import type { response_opening_jobInfo } from '../models/response_opening_jobInfo';
import type { response_opening_personalInfo } from '../models/response_opening_personalInfo';
import type { response_opening_submissionTasks } from '../models/response_opening_submissionTasks';
import type { response_opening_taxOffice } from '../models/response_opening_taxOffice';
import type { response_opening_taxReturnType } from '../models/response_opening_taxReturnType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OpeningService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 開業届け情報取得API
     * 開業届けの情報を取得する
     * 初回取得時には作成も行う
     * @returns response_opening Example response
     * @throws ApiError
     */
    public getOpening(): CancelablePromise<response_opening> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening',
        });
    }

    /**
     * 開業届けあなたの情報更新API
     * 開業届け情報のあなたの情報を更新する
     * 同時にbasic_infomationsの情報も更新する
     * @param requestBody
     * @returns response_opening_personalInfo Example response
     * @throws ApiError
     */
    public patchOpeningPersonalInfo(
        requestBody?: request_opening_personalInfo,
    ): CancelablePromise<response_opening_personalInfo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening/personal_info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業届けお仕事情更新API
     * 開業届けのお仕事情を更新する
     * 同時にbasic_infomationsの情報も更新する
     * @param requestBody
     * @returns response_opening_jobInfo Example response
     * @throws ApiError
     */
    public patchOpeningJobInfo(
        requestBody?: request_opening_jobInfo,
    ): CancelablePromise<response_opening_jobInfo> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening/job_info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業届け申告種別更新API
     * 開業届け情報の申告種別を更新する
     * 同時にbasic_infomationsの情報も更新する
     * @param requestBody
     * @returns response_opening_taxReturnType Example response
     * @throws ApiError
     */
    public patchOpeningTaxReturnType(
        requestBody?: request_opening_taxReturnType,
    ): CancelablePromise<response_opening_taxReturnType> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening/tax_return_type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業届け提出先税務署取得API
     * 開業届けの提出先税務署を取得する
     * @returns response_opening_taxOffice Example response
     * @throws ApiError
     */
    public getOpeningTaxOffice(): CancelablePromise<response_opening_taxOffice> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening/tax_office',
        });
    }

    /**
     * 開業届け提出先税務署更新API
     * 開業届けの提出先税務署を更新する
     * @param requestBody
     * @returns response_opening_taxOffice Example response
     * @throws ApiError
     */
    public patchOpeningTaxOffice(
        requestBody?: request_opening_taxOffice,
    ): CancelablePromise<response_opening_taxOffice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening/tax_office',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業届け提出タスク取得API
     * 開業届けの提出タスクを取得する
     * @returns response_opening_submissionTasks Example response
     * @throws ApiError
     */
    public getOpeningSubmissionTasks(): CancelablePromise<response_opening_submissionTasks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening/submission_tasks',
        });
    }

    /**
     * 開業届け提出タスク更新API
     * 開業届けの提出タスクを更新する
     * @param requestBody
     * @returns response_opening_submissionTasks Example response
     * @throws ApiError
     */
    public patchOpeningSubmissionTasks(
        requestBody?: request_opening_submissionTasks,
    ): CancelablePromise<response_opening_submissionTasks> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/opening/submission_tasks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 開業届情報取得API
     * 開業届の情報を取得する
     * @returns response_opening_export Example response
     * @throws ApiError
     */
    public getOpeningExport(): CancelablePromise<response_opening_export> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening/export',
        });
    }

    /**
     * 青色申告承認申請書情報取得API
     * 青色申告承認申請書の情報を取得する
     * @returns response_opening_blue_export Example response
     * @throws ApiError
     */
    public getOpeningBlueExport(): CancelablePromise<response_opening_blue_export> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/opening/blue/export',
        });
    }

}
