import { ChakraProvider } from '@chakra-ui/react'
import Head from 'next/head'
import { ReactNode } from 'react'
import { GTM_ID } from 'src/constants'
import GlobalStateProvider from 'src/context/GlobalStateProvider'
import { customTheme } from 'src/styles/customTheme'
import { Fonts } from 'src/styles/Fonts'
import { GoogleTagManager } from '../common/GoogleTagManager'
type Props = {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  return (
    <ChakraProvider theme={customTheme}>
      <Head>
        <meta name="robots" content="noindex" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,viewport-fit=cover"
        />
      </Head>

      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <GoogleTagManager googleTagManagerId={GTM_ID} />
      )}

      <Fonts />
      <GlobalStateProvider>{children}</GlobalStateProvider>
    </ChakraProvider>
  )
}
