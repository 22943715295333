/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_clients = {
    user_id: number;
    name: string;
    honorific: request_zoAdmin_clients.honorific;
    department_name: string;
    person_in_charge: string;
    person_in_charge_honorific: request_zoAdmin_clients.person_in_charge_honorific;
    zip_code: string;
    address: string;
    tel: string;
    email: string;
};

export namespace request_zoAdmin_clients {

    export enum honorific {
        ONCHU = 'onchu',
        SAMA = 'sama',
        WITHOUT_HONORIFIC = 'without_honorific',
    }

    export enum person_in_charge_honorific {
        PERSON_ONCHU = 'person_onchu',
        PERSON_SAMA = 'person_sama',
        PERSON_WITHOUT_HONORIFIC = 'person_without_honorific',
    }


}

