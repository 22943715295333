import { formErrorAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction
} from '@chakra-ui/theme-tools'

const baseStyleText: SystemStyleFunction = (props) => {
  return {
    color: 'primary.alert',
    mt: '8px',
    fontSize: '12px',
    lineHeight: 1,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    // エラー文の手前につくアイコンの大きさを制御する
    // 本当は:beforeのcontentとかで指定したい…
    '> svg': {
      w: '20px',
      h: '20px'
    }
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  text: baseStyleText(props)
})

export const FormErrorTheme = {
  parts: parts.keys,
  baseStyle
}
