/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { headOfHouseholdRelationship } from './headOfHouseholdRelationship';
import type { preview_refundAccount } from './preview_refundAccount';

export type response_preview_taxReturn1 = {
    era_year: number;
    tax_return_kind: number;
    tax_office_name: string;
    submit_era_year: number;
    submit_date: string;
    basic_information: {
        zip_code: string;
        address1: string;
        address2: string;
        is_office_tax_payment_place: boolean;
        new_years_day_address: string;
        name_kana: string;
        name: string;
        occupation: string;
        business_name: string;
        head_of_household_name: string;
        head_of_household_relationship: headOfHouseholdRelationship;
        birthday: string;
        /**
         * 1: 明治、
         * 2: 大正、
         * 3: 昭和、
         * 4: 平成、
         * 5: 令和
         */
        birthday_era: response_preview_taxReturn1.birthday_era;
        birthday_era_year: number;
        /**
         * ハイフン区切り
         */
        tel: string;
        tel_division: string;
        is_return_type_blue: boolean;
        continue_transfer: boolean;
        reference_number: string;
        has_separate_tax: boolean;
        has_loss: boolean;
    };
    revenue: {
        sales_division: number;
        sales_amount: number;
        salary_division: number | null;
        salary_amount: number;
        miscellaneous_pension_amount: number;
        miscellaneous_business_amount: number;
        miscellaneous_other_division: number | null;
        miscellaneous_other_amount: number;
        short_term_amount: number;
        long_term_amount: number;
    };
    income: {
        sales_amount: number;
        salary_division: number | null;
        salary_amount: number;
        miscellaneous_pension_amount: number;
        miscellaneous_business_amount: number;
        miscellaneous_other_amount: number;
        miscellaneous_total_amount: number;
        temporary_amount: number;
        total_amount: number;
    };
    deduction: {
        medical_amount: number;
        social_insurance_amount: number;
        small_business_mutual_aid_amount: number;
        life_insurance_amount: number;
        earthquake_insurance_amount: number;
        donation_amount: number;
        widow_division: number | null;
        widow_amount: number;
        working_student_amount: number;
        spouse_division: number | null;
        spouse_amount: number;
        dependents_amount: number;
        basic_amount: number;
        subtotal_amount: number;
        total_amount: number;
    };
    tax: {
        taxable_income_amount: number;
        taxable_income_amount_tax: number;
        other_deduction_name: string;
        other_deduction_division: number | null;
        other_deduction_amount: number;
        house_loan_deduction_division_2: number | null;
        house_loan_deduction_amount: number | null;
        special_deduction_donation_amount: number;
        deduction_income_amount: number;
        re_deduction_income_amount: number;
        income_tax_amount_for_reconstruction: number;
        income_tax_and_income_tax_for_reconstruction: number;
        withholding_amount: number;
        declared_tax_amount: number;
        estimated_tax_prepayment_amount: number;
        pay_tax: number;
        refund_tax: number;
    };
    other: {
        income_total_amount_other_than_public_pension: number | null;
        spouse_income_total_amount: number;
        special_deduction_amount: number;
        withholding_amount: number;
        unpaid_withholding_amount: number;
        subtract_carry_forward_loss_amount: number;
    };
    refund_account: preview_refundAccount;
};

export namespace response_preview_taxReturn1 {

    /**
     * 1: 明治、
     * 2: 大正、
     * 3: 昭和、
     * 4: 平成、
     * 5: 令和
     */
    export enum birthday_era {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
    }


}

